<template>
  <my-home :projects="projects">
    <template #nav>
      <el-menu router :uniqueOpened="true" :default-active="$route.matched[1].name + ($route.query.p ? $route.query.p : '')
        " class="el-menu-vertical-demo hide" @open="handleOpen" @close="handleClose" background-color="#23334b"
        text-color="#D7D7DE" active-text-color="#FFD574">
        <el-menu-item class="hide" index="projectIndex" :route="{ name: 'projectIndex' }">
          <i class="el-icon-menu"></i>
          <template #title>首页</template>
        </el-menu-item>
        <el-sub-menu class="hide" index="siwei">
          <template #title>
            <i class="el-icon-menu"></i>
            <span>饲喂管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="MeadowFeedHome" :route="{
              name: 'MeadowFeedIndex',
            }">集中供料</el-menu-item>
            <el-menu-item index="MeadowWeightHome" :route="{
              name: 'MeadowWeightIndex',
            }">料塔称重</el-menu-item>
            <el-menu-item index="MeadowLiquidHome" :route="{
              name: 'MeadowLiquidIndex',
            }">液态料</el-menu-item>
            <el-menu-item index="MeadowSmartFeedHome" :route="{
              name: 'MeadowSmartFeedIndex',
            }">智能饲喂站</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-menu-item index="projectEnv" :route="{ name: 'projectEnv', params: { id: 1 } }">
          <i class="el-icon-monitor"></i>
          <template #title>环境控制</template>
        </el-menu-item>
        <el-sub-menu class="" index="video">
          <template #title>
            <i class="el-icon-video-camera"></i>
            <span>视频监控</span>
          </template>
          <el-menu-item class="" index="projectVideoLive" :route="{
            name: 'projectVideoLive',
            params: { id: 1 },
          }">
            实时
          </el-menu-item>
          <el-menu-item class="" index="projectVideoBack" :route="{
            name: 'projectVideoBack',
            params: { id: 1 },
          }">
            历史
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item index="projectAlarm" :route="{ name: 'projectAlarm', params: { id: 1 } }">
          <i class="el-icon-s-opportunity"></i>
          <template #title>报警</template>
        </el-menu-item>
        <template v-if="isSuper">
          <el-menu-item index="user" :route="{ name: 'user' }">
            <i class="el-icon-s-custom"></i>
            <template #title>人员管理</template>
          </el-menu-item>
          <el-menu-item index="contacts" :route="{ name: 'contacts', params: { id: 1 } }">
            <i class="el-icon-user-solid"></i>
            <template #title>联系人管理</template>
          </el-menu-item>
        </template>
        <el-menu-item index="password" :route="{ name: 'password' }">
          <i class="el-icon-lock"></i>
          <template #title>修改密码</template>
        </el-menu-item>

        <el-sub-menu class="hide" index="baojing">
          <template #title>
            <i class="el-icon-menu"></i>
            <span>报警管理</span>
          </template>
          <el-menu-item class="hide" index="xxx" :route="{
            name: 'xxx',
          }">
            报警设置
          </el-menu-item>
          <el-sub-menu index="baojingjilu">
            <template #title>
              <i class="el-icon-menu"></i>
              <span>报警记录</span>
            </template>
            <el-menu-item index="alarmEnv" :route="{ name: 'alarmEnv' }">
              <i class="el-icon-menu"></i>
              <template #title>环境报警</template>
            </el-menu-item>
            <el-menu-item index="alarmDevice" :route="{ name: 'alarmDevice' }">
              <i class="el-icon-menu"></i>
              <template #title>设备报警</template>
            </el-menu-item>
            <el-menu-item index="alarmVideo" :route="{ name: 'alarmVideo' }">
              <i class="el-icon-menu"></i>
              <template #title>视频报警</template>
            </el-menu-item>
          </el-sub-menu>
        </el-sub-menu>
        <el-sub-menu class="hide" index="shengwu">
          <template #title>
            <i class="el-icon-menu"></i>
            <span>生物安全</span>
          </template>
          <el-menu-item index="video1" :route="{ name: 'video', query: { p: 1 } }">
            <i class="el-icon-menu"></i>
            <template #title>外围监控</template>
          </el-menu-item>
          <el-menu-item index="video2" :route="{ name: 'video', query: { p: 2 } }">
            <i class="el-icon-menu"></i>
            <template #title>厂内监控</template>
          </el-menu-item>
          <el-sub-menu index="xixiao">
            <template #title>
              <i class="el-icon-menu"></i>
              <span>洗消监控</span>
            </template>
            <el-menu-item index="video3" :route="{ name: 'video', query: { p: 3 } }">
              <i class="el-icon-menu"></i>
              <template #title>人员洗消</template>
            </el-menu-item>
            <el-menu-item index="video4" :route="{ name: 'video', query: { p: 4 } }">
              <i class="el-icon-menu"></i>
              <template #title>车辆洗消</template>
            </el-menu-item>
            <el-menu-item index="video5" :route="{ name: 'video', query: { p: 5 } }">
              <i class="el-icon-menu"></i>
              <template #title>物资洗消</template>
            </el-menu-item>
          </el-sub-menu>
        </el-sub-menu>
        <el-sub-menu class="hide" index="nenghao">
          <template #title>
            <i class="el-icon-menu"></i>
            <span>能耗中心</span>
          </template>

          <el-menu-item index="energyWater" :route="{
            name: 'energyWater',
          }">
            水量统计
          </el-menu-item>
          <el-menu-item index="energyDian" :route="{
            name: 'energyDian',
          }">
            电量统计
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu class="hide" index="dongwu">
          <template #title>
            <i class="el-icon-menu"></i>
            <span>动物管理</span>
          </template>

          <el-menu-item index="guzhong" :route="{
            name: 'guzhong',
          }">
            盘点估重
          </el-menu-item>
        </el-sub-menu>

        <el-menu-item class="hide" index="About" :route="{ name: 'About' }">
          <i class="el-icon-menu"></i>
          <template #title>xxx</template>
        </el-menu-item>
      </el-menu>

      <el-menu ref="menu" router :uniqueOpened="true" :default-active="defaultActive" class="el-menu-vertical-demo"
        background-color="#23334b" text-color="#D7D7DE" active-text-color="#FFD574">
        <template v-for="(item, index) in getMenu">
          <el-sub-menu :index="index + ''" :key="index" v-if="item.functions.length > 0">
            <template #title>
              <i :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </template>
            <!-- <el-menu-item v-for="(i, index1) in item.functions" :key="index1" :index="i.page" >{{i.name}}</el-menu-item> -->
            <el-menu-item v-for="(i, index1) in item.functions" :key="index1" :index="i.page" :route="{
              name: i.page,
              params: { id: projectid },
            }">
              {{ i.name }}
            </el-menu-item>
          </el-sub-menu>

          <el-menu-item v-else style="border: none" :index="item.page" :key="index + ''" :route="{ name: item.page }">
            <i :class="item.icon"></i>{{ item.name }}</el-menu-item>
        </template>
      </el-menu>
    </template>
    <template #con> <router-view /></template>
  </my-home>
</template>

<script>
import basil from "../../util/basil";
import Home from "@/components/home/index.vue";
import { data } from "./data2";

export default {
  name: "Home",
  components: {
    "my-home": Home,
  },
  data() {
    return {
      // user: cookie.get("user"),
      user: basil.get("user"),
      projects: {
        value: "1",
        text: "中育项目",
      },
      projectid: basil.get("projectid"),
      defaultActive: "",
    };
  },
  computed: {
    isSuper() {
      let is = false;
      if (!this.user || !this.user.roles) {
        return is;
      }
      this.user.roles.forEach((item, index) => {
        if (item.id == 1) {
          is = true;
        }
      });
      return is;
    },
    getMenu: function () {
      const arr = this?.user?.roles[0]?.modules || [];
      if (arr[3] && arr[3]?.functions) {
        arr[3].functions = arr[3].functions.filter(i => i.name != "智能饲喂站")
      }
      return arr;



      // return data
    },
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    getProjectsIndex(arr, obj) {
      let index = null;
      arr.every(function (value, i) {
        if (value.value === obj) {
          index = i;
          return false;
        }
        return true;
      });
      return index;
    },
  },
  watch: {
    $route: function (value) {
      this.defaultActive = value.matched[this.$route.matched.length - 1].name;
      // if(this.defaultActive=='WashList') {
      //   this.defaultActive = 'Wash'
      // }
    },
  },
  mounted() {
    if (!this.user) {
      this.$router.push({ name: "Login" });
      return;
    }
    const projectLen = this.user.projects.length;
    if (projectLen && projectLen === 1) {
      this.projects = this.user.projects[0];
    } else if (projectLen && projectLen > 1) {
      this.projects =
        this.user.projects[
        this.getProjectsIndex(this.user.projects, this.projectid)
        ];
    }
    this.defaultActive =
      this.$route.matched[this.$route.matched.length - 1].name;
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
