<template>
  <router-link
    v-if="num > 0"
    :to="{ name: 'projectAlarm', params: { id: 1 } }"
    class="warning"
  >
    <div class="left"></div>
    <div class="warning-label">异常报警</div>
    <div class="warning-num">{{ num > 99 ? "99+" : num }}</div>
    <div class="warning-more">>></div>
    <div class="right"></div>

  </router-link>
</template>

<script>
import basil from "@/util/basil";

export default {
  name: "Page",
  components: {},
  data() {
    return {
      projectid: basil.get("projectid"),
      num: 0,
      timeT: undefined,
      socketObj: null,
    };
  },
  methods: {
    init() {
      const socketUrl =  `ws://cloud.muzhiyun.link/websocket/alarm/${this.projectid}/${basil.get(
          "token"
        )}`
      this.socketObj = new WebSocket(
       socketUrl
      );
      this.socketObj.onopen = () => {
        console.log(" socket open");
      };
      this.socketObj.onmessage = (e) => {
        // console.log(" socket onmessage");
        let dataObj = JSON.parse(e.data);
        // console.log(dataObj);
        this.num = dataObj.body;
      };
      this.socketObj.onerror = (e, a) => {
        console.log(" socket err");
        console.log(e);
        console.log(a);
      };
      this.socketObj.onclose = () => {
        console.log(" socket close");
      };
      this.timeT = setInterval(() => {
        if (this.socketObj.readyState == WebSocket.OPEN) {
          this.socketObj.send(
            JSON.stringify({ status: 1, body: new Date().getTime() })
          );
        }
      }, 40000);
    },
    close() {
      clearInterval(this.timeT);

      this.socketObj.onclose = null;
      this.socketObj.close();
      this.socketObj = null;
    },
    visibilitychange() {
      if (document.hidden) {
        this.close();
      } else {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
    document.addEventListener("visibilitychange", this.visibilitychange);
  },
  beforeUnmount() {
    this.close();
    document.removeEventListener("visibilitychange", this.visibilitychange);
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
