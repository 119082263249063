export const data = [
  {
    index: 1,
    plate: "晋M6852KL",
    type: "外场转猪车",
    enterTime: "2022-08-9 16:00:00",
    sampling: "合格",
    samplingResult: "阴性",
    xi1: "合格",
    xi2: "合格",
    zhuan: "合格",
    xiTime: '2022-08-9'
  },
  {
    index: 2,
    plate: "晋M635L0",
    type: "饲料车",
    enterTime: "2022-08-8 15:55:03",
    sampling: "合格",
    samplingResult: "阳性",
    xi1: "--",
    xi2: "无结果",
    zhuan: "--",
    xiTime: '2022-08-8'
  },
  {
    index: 3,
    plate: "晋MHT632",
    type: "物资车",
    enterTime: "2022-08-8 10:25:12",
    sampling: "合格",
    samplingResult: "无结果",
    xi1: "--",
    xi2: "--",
    zhuan: "--",
    xiTime: '2022-08-8'
  },
  {
    index: 4,
    plate: "晋M25669",
    type: "外场转猪车",
    enterTime: "2022-08-5 10:06:01",
    sampling: "合格",
    samplingResult: "阴性",
    xi1: "合格",
    xi2: "合格",
    zhuan: "合格",
    xiTime: '2022-08-5'
  },
  {
    index: 5,
    plate: "晋M3654L",
    type: "外场转猪车",
    enterTime: "2022-08-9 16:00:00",
    sampling: "合格",
    samplingResult: "阴性",
    xi1: "合格",
    xi2: "合格",
    zhuan: "合格",
    xiTime: '2022-08-9'
  },
  {
    index: 6,
    plate: "晋M30KI9",
    type: "外场转猪车",
    enterTime: "2022-08-5 10:04:00",
    sampling: "合格",
    samplingResult: "阴性",
    xi1: "合格",
    xi2: "合格",
    zhuan: "合格",
    xiTime: '2022-08-9'
  },
  {
    index: 7,
    plate: "晋M635L0",
    type: "饲料车",
    enterTime: "2022-08-1 10:10:03",
    sampling: "合格",
    samplingResult: "阴性",
    xi1: "--",
    xi2: "合格",
    zhuan: "--",
    xiTime: '2022-08-1'
  },
  {
    index: 8,
    plate: "晋M3695N",
    type: "饲料车",
    enterTime: "2022-08-1 10:09:03",
    sampling: "合格",
    samplingResult: "阴性",
    xi1: "--",
    xi2: "合格",
    zhuan: "--",
    xiTime: '2022-08-1'
  },
  {
    index: 9,
    plate: "晋MLIJ78",
    type: "饲料车",
    enterTime: "2022-08-1 10:09:00",
    sampling: "合格",
    samplingResult: "阴性",
    xi1: "--",
    xi2: "合格",
    zhuan: "--",
    xiTime: '2022-08-1'
  },
  {
    index: 10,
    plate: "晋M0PI87",
    type: "饲料车",
    enterTime: "2022-08-1 10:08:50",
    sampling: "合格",
    samplingResult: "阴性",
    xi1: "--",
    xi2: "合格",
    zhuan: "--",
    xiTime: '2022-08-1'
  },
];
