<template>
  <div class="dongwu padding10">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'MeadowIndex' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item :to="{ name: 'MeadowSmartFeedIndex' }"
        >智能饲喂站</el-breadcrumb-item
      >
      <el-breadcrumb-item>动物数据</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-form :inline="true" :model="form" class="demo-form-inline global-form global-form-inline">
        <el-form-item label="">
          <el-select v-model="form.s1" placeholder="">
            <el-option label="所有" value="xxx"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="form.s2" placeholder="">
            <el-option label="所有" value="xx"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <el-tabs>
      <el-tab-pane label="饲料量">
        <el-card>
          <el-table class="table1" :data="tableData" border>
            <el-table-column prop="t1" label="*"> </el-table-column>
            <el-table-column prop="t2" label="母猪号码"> </el-table-column>
            <el-table-column prop="t3" label="--1--"> </el-table-column>
            <el-table-column prop="t4" label="状态"> </el-table-column>
            <el-table-column prop="t5" label="天数"> </el-table-column>
            <el-table-column prop="t6" label="位置"> </el-table-column>
            <el-table-column prop="t7" label="栏号"> </el-table-column>
            <el-table-column prop="t8" label="天数"> </el-table-column>
            <el-table-column prop="t9" label="训练"> </el-table-column>
            <el-table-column prop="t10" label="饲料1"> </el-table-column>
            <el-table-column prop="t11" label="饲料2"> </el-table-column>
            <el-table-column prop="t12" label="体重"> </el-table-column>
            <el-table-column prop="t13" label="体重评分"> </el-table-column>
            <el-table-column prop="t14" label="当前%"> </el-table-column>
            <el-table-column prop="t15" label="曲线"> </el-table-column>
          </el-table>
        </el-card>
        <el-card class="box-card">
          <el-button type="primary" icon="el-icon-delete"></el-button>
          <el-button type="primary" icon="el-icon-turn-off"></el-button>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="饲料定额1" disabled></el-tab-pane>
      <el-tab-pane label="饲料定额2" disabled></el-tab-pane>
      <el-tab-pane label="分离" disabled></el-tab-pane>
      <el-tab-pane label="颜色标记" disabled></el-tab-pane>
      <el-tab-pane label="微计量添加剂" disabled></el-tab-pane>
      <el-tab-pane label="发情探测" disabled></el-tab-pane>
      <el-tab-pane label="分配号码" disabled></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  methods: {
    filterTag(value, row) {
      return row.tag === value;
    },
  },
  data() {
    return {
      tableData: [
        {
          t1: "*",
          t2: "xxa0002596",
          t3: "",
          t4: "补充母猪",
          t5: "158",
          t6: "不存在",
          t7: "14",
          t8: "49",
          t9: "",
          t10: "0",
          t11: "0",
          t12: "0",
          t13: "3",
          t14: "115",
          t15: "1",
        },
        {
          t1: "*",
          t2: "xxa0002600",
          t3: "",
          t4: "补充母猪",
          t5: "158",
          t6: "不存在",
          t7: "15",
          t8: "49",
          t9: "",
          t10: "0",
          t11: "0",
          t12: "0",
          t13: "3",
          t14: "115",
          t15: "1",
        },
      ],
      form: {
        resource: "",
        type: [],
      },
    };
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
