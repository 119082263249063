<template>
  <div ref="envBox" :class="{ 'env-box': true }">
    <div ref="env" class="env" :style="`transform:scale(${scale})`">
      <div class="env-left">
        <div class="env-top">
          <pigstyUnit
            v-model="assetidInfo"
            :initAssetidInfo="initAssetidInfo"
          ></pigstyUnit>
          <div class="env-info1">
            <div class="info-item info-item-1">
              <div class="info-label">日龄：</div>
              <div class="info-value">
                {{ envControlInfo.age ? envControlInfo.age + "天" : "--" }}
              </div>
            </div>

            <div class="info-item info-item-2">
              <div class="info-label">目标温度：</div>
              <div class="info-value">
                {{
                  envControlInfo.targetTemperature
                    ? envControlInfo.targetTemperature + "℃"
                    : "--"
                }}
              </div>
            </div>
            <div class="info-item info-item-3">
              <div class="info-label">当前通风等级：</div>
              <div class="info-value">
                {{
                  envControlInfo.ventilationLevel
                    ? envControlInfo.ventilationLevel + "级"
                    : "--"
                }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div style="color: #fff;font-size: 20px">  {{deviceDataArrO}}</div> -->

        <div :class="{ 'env-she': true, gray: status }">
          <div :class="'env-she-bg'">
            <div v-if="status == 0" class="gong-box">
              <div
                :class="'gong gong' + this.gongNum"
                v-for="(item, index) in gongArr"
                :key="index"
              >
                <template v-if="item.device">
                  <el-tooltip
                    :disabled="
                      item.device.status == 3 || item.device.alarm_tips
                        ? false
                        : true
                    "
                    :popper-class="
                      item.device.status == 3
                        ? 'global-device-tip-blue'
                        : 'global-device-tip'
                    "
                    :content="
                      item.device.status == 3
                        ? '设备停用'
                        : item.device.alarm_tips
                        ? item.device.alarm_tips.message
                        : ''
                    "
                    placement="top"
                  >
                    <div :class="item.device.status == 0 ? 'device' : 'device'">
                      <div class="icon-box">
                        <div
                          :class="{
                            [`icon-${item.device.type}`]:
                              item.device.switch == undefined,
                            [`icon-${item.device.type}-on`]:
                              item.device.status != 3,
                            [`icon-${item.device.type}-off`]:
                              item.device.status == 3,
                            [`icon-${item.device.type}-no-turn`]:
                              item.device.switch == 0,
                          }"
                          :style="
                            item.device.type == 'fengBian' &&
                            item.device.switch !== 0
                              ? {
                                  animationDuration:
                                    (0.5 * 100) / item.device.switch + 's',
                                }
                              : null
                          "
                        ></div>
                      </div>
                      <div
                        :class="{
                          txt: true,
                          'txt-err': item.device.alarm_tips,
                        }"
                      >
                        <div
                          :class="{
                            txt1: true,
                            'txt1-stop': item.device.status == 3,
                          }"
                        >
                          <template
                            v-if="
                              item.device.type == 'feng' ||
                              item.device.type == 'fengBian'
                            "
                          >
                            {{ !item.device.a ? "--" : item.device.a + "A" }}
                          </template>
                          <template v-else>{{ item.device.name }}</template>
                        </div>
                        <div
                          :class="{
                            txt2: true,
                            'txt2-stop': item.device.status == 3,
                          }"
                        >
                          <template
                            v-if="
                              item.device.type == 'fengBian' ||
                              item.device.type == 'chuang'
                            "
                          >
                            {{
                              item.device.status != 1
                                ? "--"
                                : item.device.percent
                                ? item.device.percent + "%"
                                : "关闭"
                            }}
                          </template>

                          <template
                            v-if="
                              item.device.type == 'juan' ||
                              item.device.type == 'hua'
                            "
                          >
                            {{
                              item.device.status != 1
                                ? "--"
                                : item.device.percent + "%"
                            }}

                            <span></span>
                          </template>
                          <template
                            v-if="
                              item.device.type == 're' ||
                              item.device.type == 'shi' ||
                              item.device.type == 'feng'
                            "
                          >
                            {{
                              item.device.status != 1
                                ? "--"
                                : item.device.switch
                                ? "开启"
                                : "关闭"
                            }}
                          </template>
                          <template v-if="item.device.type == 'wendu'">
                            {{
                              item.device.status != 1
                                ? "--"
                                : item.device.value.toFixed(1) + "℃"
                            }}
                          </template>
                          <template v-if="item.device.type == 'shidu'">
                            {{
                              item.device.status != 1
                                ? "--"
                                : item.device.value + "%"
                            }}
                          </template>
                        </div>
                        <div
                          v-if="
                            item.device.type == 'fengBian' ||
                            item.device.type == 'chuang'
                          "
                          class="progress"
                        >
                          <div
                            class="progress-bar"
                            :style="{ width: item.device.percent + '%' }"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </el-tooltip>
                </template>
              </div>
            </div>
            <div v-else>
              <div :class="'she-err she-err' + status">
                <div class="she-err-icon"></div>
                <div class="she-err-text">
                  <template v-if="status == 1"> 当前猪舍为空舍 </template>
                  <template v-if="status == 2">
                    当前猪舍环控离线，请检查环控设备
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="env-right">
        <div class="box0">
          <div class="global-title">环境信息</div>
          <div class="box2-con">
            <div
              class="box2-item"
              :style="{ cursor: envControlInfo.temperature ? 'pointer' : '' }"
              @click="() => toTemperatureCurve(envControlInfo.uuid)"
            >
              <div class="box2-icon-box">
                <div class="box2-icon-1"></div>
              </div>
              <div class="box2-info">
                <div class="box2-name">室内平均温度</div>
                <div class="box2-value">
                  {{
                    envControlInfo.temperature
                      ? envControlInfo.temperature + "℃&nbsp;&nbsp;&nbsp;>>"
                      : "--"
                  }}
                </div>
              </div>
            </div>
            <div class="box2-item">
              <div class="box2-icon-box">
                <div class="box2-icon-2"></div>
              </div>
              <div class="box2-info">
                <div class="box2-name">室内平均湿度</div>
                <div class="box2-value">
                  {{
                    envControlInfo.humidity
                      ? envControlInfo.humidity + "%RH"
                      : "--"
                  }}
                </div>
              </div>
            </div>
            <div class="box2-item">
              <div class="box2-icon-box">
                <div class="box2-icon-3"></div>
              </div>
              <div class="box2-info">
                <div class="box2-name">室内平均负压</div>
                <div class="box2-value">
                  {{ "--" }}
                </div>
              </div>
            </div>

            <div class="box2-item">
              <div class="box2-icon-box">
                <div class="box2-icon-4"></div>
              </div>
              <div class="box2-info">
                <div class="box2-name">室外平均温度</div>
                <div class="box2-value">
                  {{ "--" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box1">
          <div class="global-title">设备信息</div>
          <div class="box1-con">
            <div class="box1-item">
              <div class="box1-icon-box">
                <div class="box1-icon-4"></div>
              </div>
              <div class="box1-info">
                <div class="box1-name">环控</div>
                <div class="box1-value">
                  <template v-if="status == 0">{{
                    isEmpty(deviceDataArrO) ? "--" : "在线"
                  }}</template>
                  <template v-if="status == 1">空舍</template>
                  <template v-if="status == 2">离线</template>
                </div>
              </div>
            </div>
            <div class="box1-item">
              <div class="box1-icon-box">
                <div class="box1-icon-5"></div>
              </div>
              <div class="box1-info">
                <div class="box1-name">水表</div>
                <div class="box1-value">{{ envControlInfo.shuiStatus }}</div>
              </div>
            </div>
            <div class="box1-item">
              <div class="box1-icon-box">
                <div class="box1-icon-6"></div>
              </div>
              <div class="box1-info">
                <div class="box1-name">电表</div>
                <div class="box1-value">{{ envControlInfo.dianStatus }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="box2">
          <div class="global-title">设备统计</div>
          <div class="box2-con">
            <div class="box2-item">
              <div class="box2-icon-box">
                <div class="box2-icon-1"></div>
              </div>
              <div class="box2-info">
                <div class="box2-name">设备总数量</div>
                <div class="box2-value">
                  {{ deviceInfo.total_amount || "--" }}
                </div>
              </div>
            </div>
            <div class="box2-item">
              <div class="box2-icon-box">
                <div class="box2-icon-2"></div>
              </div>
              <div class="box2-info">
                <div class="box2-name">运行数量</div>
                <div class="box2-value">
                  {{ deviceInfo.running_amount || "--" }}
                </div>
              </div>
            </div>
            <div class="box2-item">
              <div class="box2-icon-box">
                <div class="box2-icon-3"></div>
              </div>
              <div class="box2-info">
                <div class="box2-name">停用数量</div>
                <div class="box2-value">
                  {{ deviceInfo.stoped_amount || "--" }}
                </div>
              </div>
            </div>

            <div class="box2-item">
              <div class="box2-icon-box">
                <div class="box2-icon-4"></div>
              </div>
              <div class="box2-info">
                <div class="box2-name">异常数量</div>
                <div class="box2-value">
                  {{ deviceInfo.alarm_amount || "--" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <table class="box3">
          <tr class="tr1">
            <td class="td1">
              <div class="txt1">能源消耗</div>
            </td>
            <td class="td2">
              <div class="txt2">今日</div>
            </td>
            <td class="td3">
              <div class="txt2">每只动物</div>
            </td>
            <td>
              <div class="txt2">总量</div>
            </td>
          </tr>
          <tr class="tr2">
            <td>
              <div class="box3-icon1"></div>
              <div class="txt3">电表</div>
            </td>
            <td>
              <div class="txt4">
                {{
                  energyInfo.electric.total != undefined
                    ? energyInfo.electric?.total.toFixed(2) + "kw.h"
                    : "--"
                }}
              </div>
            </td>
            <td>
              <div class="txt4">
                {{
                  energyInfo.electric.average != undefined && false
                    ? energyInfo.electric?.average.toFixed(2) + "kw.h"
                    : "--"
                }}
              </div>
            </td>
            <td>
              <div class="txt4">
                {{
                  energyInfo.electric.accumulate != undefined
                    ? energyInfo.electric?.accumulate.toFixed(2) + "kw.h"
                    : "--"
                }}
              </div>
            </td>
          </tr>
          <tr class="tr2">
            <td>
              <div class="box3-icon2"></div>
              <div class="txt3">水表</div>
            </td>
            <td>
              <div class="txt4">
                {{
                  energyInfo.water.total != undefined
                    ? energyInfo.water?.total.toFixed(2) + "t"
                    : "--"
                }}
              </div>
            </td>
            <td>
              <div class="txt4">
                {{
                  energyInfo.water.average != undefined && false
                    ? energyInfo.water?.average.toFixed(2) + "t"
                    : "--"
                }}
              </div>
            </td>
            <td>
              <div class="txt4">
                {{
                  energyInfo.water.accumulate != undefined
                    ? energyInfo.water?.accumulate.toFixed(2) + "t"
                    : "--"
                }}
              </div>
            </td>
          </tr>
        </table>
      </div>
      <temperatureDialog
        :title="temperatureTitle"
        v-model:visible="temperatureVisible"
        v-model:uuid="temperatureUuid"
      />
    </div>
  </div>
</template>

<script>
import basil from "@/util/basil";
import pigstyUnit from "../../../components/pigsty-unit";
import { isEmpty } from "lodash-es";
import { DEVICE_TYPE, DEVICE_TYPE_ICON } from "./const";
import temperatureDialog from "@/components/temperatureCurve";

let envInterval;
export default {
  name: "Page",
  data() {
    return {
      deviceMap: {},
      projectid: basil.get("projectid"),
      status: 0,
      scale: 1,
      form: {},
      // gongNum: 15,
      deviceDataArrO: [],
      deviceArrO: [],

      deviceInfo: {
        alarm_amount: "",
        running_amount: "",
        stoped_amount: "",
        total_amount: "",
      },
      energyInfo: {
        electric: {},
        water: {},
      },
      initAssetidInfo: {
        pigstyActive: this.$route.query?.pigstyid || "",
        unitActive: "",
      },
      assetidInfo: {},
      isEmpty,

      temperatureTitle: "",
      temperatureVisible: false,
      temperatureUuid: "",
    };
  },
  computed: {
    gongNum() {
      let num;
      this.deviceArrO.forEach((v, k) => {
        if (v.profile == DEVICE_TYPE["环控"]) {
          // console.log(v, "vvvvvvvvvvvvv");
          if (
            v.attributes &&
            v.attributes.gridSize &&
            parseInt(v.attributes.gridSize) == 9
          ) {
            num = 9;
          }
        }
      });
      if (!num) {
        num = 15;
      }
      return num;
    },
    envControlInfo() {
      // console.log('envControlInfo');
      let o;

      o = {
        age: undefined,
        targetTemperature: undefined,
        ventilationLevel: undefined,
        temperature: undefined,
        dianStatus: "--",
        shuiStatus: "--",
        humidity: undefined,
        uuid: undefined,
        name: undefined,
      };
      this.deviceDataArrO.forEach((v, k) => {
        if (this.deviceMap[v.uuid].profile == DEVICE_TYPE["环控"]) {
          o.name = v?.name;
          //uuid
          o.uuid = v?.uuid;
          // 日龄
          o.age = v?.values?.age;
          // 目标温度
          o.targetTemperature = v?.values?.targetTemperature;

          // 当前通风等级
          o.ventilationLevel = v?.values?.ventilationLevel;
          // 温度
          o.temperature = v?.values?.temperature;
          // 湿度
          o.humidity = v?.values?.humidity;

          if (!v.online) {
            this.status = 2;
            //离线
          } else if (v.values.empty) {
            //空舍
            this.status = 1;
          } else {
            // 在线
            this.status = 0;
          }
        }

        // console.log(this.deviceMap, DEVICE_TYPE["电表"]);
        // console.log(DEVICE_TYPE["水表"], '水');

        if (this.deviceMap[v.uuid].profile == DEVICE_TYPE["电表"]) {
          if (v.alarm_tips || !v.online) {
            o.dianStatus = "异常";
          } else {
            o.dianStatus = "正常";
          }
        }

        if (this.deviceMap[v.uuid].profile == DEVICE_TYPE["水表"]) {
          if (v.alarm_tips || !v.online) {
            o.shuiStatus = "异常";
          } else {
            o.shuiStatus = "正常";
          }
        }
      });
      return o;
    },
    deviceArr() {
      let arr = [];
      let objById = {};
      let i,
        j,
        iArr = [],
        jArr = [];
      for (i = 0; i < this.gongNum; i++) {
        iArr.push(i + 1);
      }
      for (j = 0; j < this.gongNum; j++) {
        jArr.push(j + 1);
      }
      this.deviceArrO.forEach((v, k) => {
        let o = {};
        o.type = DEVICE_TYPE_ICON?.[this.deviceMap[v.uuid].profile] || "";

        if (o.type) {
          objById[v.uuid] = o;
          if (v.attributes && v.attributes.position) {
            v.attributes.position = v.attributes.position.replace("，", ",");
            let positionArr = v.attributes.position.split(",");
            o.x = parseInt(positionArr[0]);
            o.y = parseInt(positionArr[1]);
            // o.x = iArr.splice(Math.floor(iArr.length * Math.random()), 1)[0];
            // o.y = jArr.splice(Math.floor(jArr.length * Math.random()), 1)[0];
          }

          o.status = 0; // 没读到数据，不知道状态
          if (v.attributes && !v.attributes.enable) {
            // 不可用？
            o.status = 3;
          }
          arr.push(o);
        }
      });
      this.deviceDataArrO.forEach((v, k) => {
        let o = objById[v.uuid];

        if (!o) {
          return;
        }

        let nameSplitArr = v.name.split("-");

        if (o.status != 3) {
          o.status = 1;
        }

        o.online = v.online;
        o.alarm_tips = v.alarm_tips;
        o.alarm_tips_unit = "";

        if (
          o.alarm_tips &&
          o.alarm_tips.data_item &&
          o.alarm_tips.data_value != undefined
        ) {
          o.alarm_tips.data_value =
            parseFloat(o.alarm_tips.data_value).toFixed(2) + "";
          if (o.alarm_tips.data_item == "温度") {
            o.alarm_tips_unit = "℃";
          }
          if (o.alarm_tips.data_item == "湿度") {
            o.alarm_tips_unit = "RH";
          }
          if (o.alarm_tips.data_item == "电压") {
            o.alarm_tips_unit = "V";
          }
          if (o.alarm_tips.data_item == "电流") {
            o.alarm_tips_unit = "A";
          }
          if (o.alarm_tips.data_item == "压力") {
            o.alarm_tips_unit = "pa";
          }
        }
        if (!o.online && !o.alarm_tips) {
          o.alarm_tips = {
            message: "设备离线",
          };
        } else if (!o.online && o.alarm_tips) {
          o.alarm_tips.message = "设备离线且" + o.alarm_tips.message;
        }
        if (o.alarm_tips && o.alarm_tips.data_value != undefined) {
          o.alarm_tips.message =
            o.alarm_tips.message +
            " " +
            o.alarm_tips.data_value +
            o.alarm_tips_unit;
        }

        if (this.deviceMap[v.uuid].profile == DEVICE_TYPE["温度"]) {
          if (o.status == 3) {
            return;
          }
          if (!v.values || v.values.temperature == undefined) {
            // 数据获取异常
            o.status = 2;
            return;
          }
          o.value = v.values.temperature;
        }

        if (this.deviceMap[v.uuid].profile == DEVICE_TYPE["湿度"]) {
          if (o.status == 3) {
            return;
          }
          if (!v.values || v.values.humidity == undefined) {
            o.status = 2;
            return;
          }
          o.value = v.values.humidity;
        }

        if (this.deviceMap[v.uuid].profile == DEVICE_TYPE["定速风机"]) {
          if (o.status == 3) {
            o.switch = 0;
            return;
          }
          if (!v.values || v.values.on == undefined) {
            o.status = 2;
            o.switch = 0;
            return;
          }
          o.switch = v.values.on ? 1 : 0;
          // if (v.values.on != undefined) {
          //   o.switch = v.values.on ? 1 : 0;
          // } else {
          //   o.switch = v.values.current ? 1 : 0;
          // }
          // o.a = undefined;
          o.a = v.values?.current?.toFixed(2) || undefined;
        }
        if (this.deviceMap[v.uuid].profile == DEVICE_TYPE["制冷"]) {
          if (o.status == 3) {
            o.switch = 0; //因为switch决定了icon，所以必须指定一个
            return;
          }
          if (!v.values || v.values.on == undefined) {
            o.status = 2;
            o.switch = 0;
            return;
          }
          o.switch = v.values.on ? 1 : 0;
        }
        if (
          this.deviceMap[v.uuid].profile == DEVICE_TYPE["小窗"] ||
          this.deviceMap[v.uuid].profile == DEVICE_TYPE["变速风机"]
        ) {
          if (o.status == 3) {
            o.switch = 0;
            return;
          }
          if (!v.values || v.values.percent == undefined) {
            o.status = 2;
            o.switch = 0;
            return;
          }
          o.switch = v.values.on ? 1 : 0;
          // if (v.values.on != undefined) {
          //   o.switch = v.values.on ? 1 : 0;
          // } else {
          //   o.switch = v.values.current ? 1 : 0;
          // }
          o.percent = v.values.percent;
          o.a = v.values?.current?.toFixed(2) || undefined;
        }
        if (
          this.deviceMap[v.uuid].profile == DEVICE_TYPE["卷帘"] ||
          this.deviceMap[v.uuid].profile == DEVICE_TYPE["滑帘"]
        ) {
          if (o.status == 3) {
            o.switch = 0;
            return;
          }
          if (!v.values || v.values.percent == undefined) {
            o.status = 2;
            o.switch = 0;
            return;
          }
          // percent 有值认为开启
          o.switch = v.values.percent != undefined ? 1 : 0;
          o.percent = v.values.percent;
        }
      });

      return arr;
    },
    deviceObj() {
      let o = {};
      let nameObj = {
        wendu: "温度",
        shidu: "湿度",
        feng: "",
        fengBian: "",
        re: "加热",
        juan: "卷帘",
        hua: "滑帘",
        shi: "湿帘",
        chuang: "小窗",
      };

      this.deviceArr.forEach((v, k) => {
        v.name = nameObj[v.type];
        o[`x_${v.x}_y_${v.y}`] = v;
      });
      // console.log(o, "deviceObj");
      return o;
    },
    deviceObjById() {
      let o = {};
      this.deviceArr.forEach((v, k) => {
        o[v.id] = v;
      });
      return o;
    },
    gongArr: function () {
      let arr = [],
        i,
        j;
      for (i = 0; i < this.gongNum; i++) {
        for (j = 0; j < this.gongNum; j++) {
          let item = { x: j + 1, y: i + 1 };
          if (this.deviceObj[`x_${j + 1}_y_${i + 1}`]) {
            item.device = this.deviceObj[`x_${j + 1}_y_${i + 1}`];
          }
          arr.push(item);
        }
      }
      // console.log("gong arr");
      // console.log(arr);
      return arr;
    },
  },
  watch: {
    assetidInfo: function (newVal, old) {
      // if (newVal && newVal.unitActive && newVal.unitActive != old.unitActive) {
      this.getEnvList();
      this.getDevice();
      this.getEnergy();
      clearInterval(envInterval);

      envInterval = setInterval(() => {
        this.getEnvData();
        this.getDevice();
        this.getEnergy();
      }, 10000);
      // }
    },
  },
  components: { pigstyUnit, temperatureDialog },
  methods: {
    scaleEvn() {
      // console.log(this.$refs.env.clientWidth);
      // console.log(this.$refs.env.clientHeight);
      // console.log(this.$refs.envBox.clientWidth);
      // console.log(this.$refs.envBox.clientHeight);
      let scale;
      if (
        this.$refs.envBox.clientWidth / this.$refs.envBox.clientHeight >
        this.$refs.env.clientWidth / this.$refs.env.clientHeight
      ) {
        scale = this.$refs.envBox.clientHeight / this.$refs.env.clientHeight;
      } else {
        scale = this.$refs.envBox.clientWidth / this.$refs.env.clientWidth;
      }

      this.scale = scale > 0.5 ? scale : 0.5;
    },

    getEnvData() {
      this.$myRequest({
        url: `/api/tenant/device/${this.projectid}/datas/realtime`,
        data: {
          assetid: this.assetidInfo.unitActive,
          projectid: this.projectid,
        },
      })
        .then((res) => {
          // console.log("getEnvData");
          // console.log(res);
          this.deviceDataArrO = res.data.body;
          //
        })
        .catch((res) => {
          // console.log("err");
          // console.log(res);
          this.$message(res.data.status_detail);
        });
    },
    getEnvList() {
      this.$myRequest({
        url: `/api/tenant/device/${this.projectid}/list`,
        data: {
          assetid: this.assetidInfo.unitActive,
          projectid: this.projectid,
        },
      })
        .then((res) => {
          // console.log("getEnv list");
          // console.log(res);

          res.data.body.forEach((d) => {
            this.deviceMap[d.uuid] = d;
          });

          this.deviceArrO = res.data.body;
          this.getEnvData();
          //
        })
        .catch((res) => {
          // console.log("env list err");
          // console.log(res);
          this.$message(res.data.status_detail);
        });
    },
    getDevice() {
      this.$myRequest({
        url: `/api/tenant/device/${this.projectid}/statistics`,
        data: {
          assetid: this.assetidInfo.unitActive,
          projectid: this.projectid,
        },
      })
        .then((res) => {
          // console.log("getDevice");
          // console.log(res);
          this.deviceInfo = res.data.body;
          //
        })
        .catch((res) => {
          // console.log("err");
          // console.log(res);
          this.$message(res.data.status_detail);
        });
    },
    getEnergy() {
      this.$myRequest({
        url: `/api/tenant/energy/${this.projectid}/today`,
        data: {
          assetid: this.assetidInfo.unitActive,
          projectid: this.projectid,
        },
      })
        .then((res) => {
          // console.log("getEnergy res");
          // this.energyInfo = res.data.body;
          if (res.data.body.electric) {
            this.energyInfo.electric = res.data.body.electric;
          } else {
            this.energyInfo.electric = {};
          }
          if (res.data.body.water) {
            this.energyInfo.water = res.data.body.water;
          } else {
            this.energyInfo.water = {};
          }
          //
        })
        .catch((res) => {
          // console.log("getEnergy err");
          // console.log(res);
          this.$message(res.data.status_detail);
        });
    },
    toTemperatureCurve(uuid) {
      this.temperatureVisible = true;
      this.temperatureUuid = uuid;
      if (this.envControlInfo.name.includes("环控器")) {
        this.temperatureTitle =
          this.envControlInfo.name.replace("-环控器", "") + "-温度曲线";
        return;
      }
      if (this.envControlInfo.name.includes("环控")) {
        this.temperatureTitle =
          this.envControlInfo.name.replace("-环控", "") + "-温度曲线";
      }
    },
  },
  mounted() {
    window.xxx = this;
    this.scaleEvn();
    window.addEventListener("resize", this.scaleEvn);
  },
  beforeUnmount() {
    clearInterval(envInterval);
    window.removeEventListener("resize", this.scaleEvn);
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
