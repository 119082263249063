<template>
  <div class="video-list-box">
    <el-form ref="form" :model="form" class="global-form" :rules="rules">
      <el-form-item label="" prop="">
        <el-input v-model="input" placeholder="搜索监控位置" clearable> </el-input>
      </el-form-item>
    </el-form>
    <el-scrollbar always class="video-list">
      <div class="nosearch" v-if="noSearch">暂无匹配搜索内容</div>
      <div
        :class="{
          'video-list-item': true,
          active: item.indexCode == cameraIndexCode,
          hide: !item.show,
        }"
        v-for="(item, index) in showList"
        :key="index"
        @click="itemClick(item.indexCode)"
      >
        {{ item.name }}
        <div class="tag"></div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import basil from "../../util/basil";

export default {
  name: "Page",
  props: ["modelValue"],
  emits: ["update:modelValue"],
  components: {},
  data() {
    return {
      projectid: basil.get("projectid"),
      list: [],
      cameraIndexCode: "",
      input: "",
    };
  },
  computed: {
    noSearch() {
      let noNum = 0;
      this.list.forEach((item, index) => {
        if (item.show === false) {
          noNum++;
        }
      });
      if (this.list.length && noNum == this.list.length) {
        return true;
      } else {
        return false;
      }
    },
    showList() {
      if (this.input == "") {
        this.list.forEach((item, index) => {
          item.show = true;
        });
      } else {
        this.list.forEach((item, index) => {
          if (item.name.indexOf(this.input) == -1) {
            item.show = false;
          } else {
            item.show = true;
          }
        });
      }
      return this.list;
    },
  },
  methods: {
    itemClick(indexCode) {
      this.cameraIndexCode = indexCode;
      this.$emit("update:modelValue", indexCode);
    },
    getList() {
      this.$myRequest({
        url: `/api/tenant/hik/${this.projectid}/camera/list`,
      })
        .then((res) => {
          console.log("list res");
          console.log(res);
          this.list = res.data.body.datas;
          //
        })
        .catch((res) => {
          console.log("list err");
          console.log(res);
          this.$message(res.data.status_detail);
        });
    },
  },
  mounted() {
    this.cameraIndexCode = this.modelValue;
    this.getList();
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
