<template>
  <router-view />
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    console.log("+++版本打包时间：+++", process.env.version);
  },
};
</script>
<style lang="less" src="./app/reset.less"></style>
<style lang="less" src="./app/common.less"></style>
<style lang="less" src="./app/form.less"></style>
