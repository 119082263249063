<template>
  <div class="global-home">
    <div class="global-header-bg"></div>
    <div class="global-header">
      <div class="global-logo1"></div>
      <Time></Time>

      <div class="global-logo2"></div>
    </div>
    
    <div class="global-con-no">
      <slot name="con"></slot>
    </div>
  </div>
</template>

<script>
import Time from "../../components/time/index.vue";
import Warning from "../../components/warning/index.vue";
import User from "../../components/user/index.vue";
export default {
  name: "Home",
  components: { Time, Warning, User },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
