<template>
  <div class="yanse padding10">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'MeadowIndex' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item :to="{ name: 'MeadowSmartFeedIndex' }"
        >智能饲喂站</el-breadcrumb-item
      >
      <el-breadcrumb-item>颜色标记</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs>
      <el-tab-pane label="当前列表">
        <el-row :gutter="10">
          <el-col :span="16">
            <el-card>
              <el-table class="table1" :data="tableData" border>
                <el-table-column prop="t1" label="名称"> </el-table-column>
                <el-table-column prop="t2" label="生产状态"> </el-table-column>
                <el-table-column prop="t3" label="天数"> </el-table-column>
                <el-table-column prop="t4" label="当天"> </el-table-column>
                <el-table-column prop="t5" label="原因"> </el-table-column>

                <el-table-column prop="t6" label="ok"> </el-table-column>
              </el-table>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card>
              <el-form :model="form" class="global-form">
                <el-form-item label="群">
                  <el-select v-model="form.s1" placeholder="">
                    <el-option label="所有" value="xxx"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </el-card>
            <el-card>
              <template #header>已颜色标记的母猪</template>
              <el-progress :percentage="0">0/7</el-progress>
            </el-card>
            <el-card>
              <el-form :model="form" class="global-form">
                <el-form-item label="">
                  <el-checkbox-group v-model="form.type">
                    <el-checkbox
                      label="尚未颜色标记的母猪"
                      name="type"
                    ></el-checkbox>
                    <el-checkbox
                      label="不包括训练母猪"
                      name="type"
                    ></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-form>
            </el-card>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="预览" disabled></el-tab-pane>
      <el-tab-pane label="任务" disabled></el-tab-pane>
      <el-tab-pane label="设置" disabled></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  data() {
    return {
      tableData: [
        {
          t1: "xxa0003065",
          t2: "补充母猪",
          t3: "41",
          t4: "1",
          t5: "标记未知身份母猪",
          t6: "0",
        },
        {
          t1: "xxa0003107",
          t2: "补充母猪",
          t3: "34",
          t4: "1",
          t5: "标记未知身份母猪",
          t6: "0",
        },
      ],
      form: {
        resource: "",
        type: [],
      },
    };
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
