<template>
  <div class="user">
    <div class="user-icon"></div>
    <div class="user-name">{{ username }}</div>
    <div class="user-logout" @click="logout"></div>
  </div>
</template>

<script>
import basil from "@/util/basil";

export default {
  name: "Page",
  data() {
    return {
      username: "",
    };
  },
  components: {},
  methods: {
    logout() {
      this.$myRequest({
        url: "/api/tenant/user/logout",
      })
        .then((res) => {

          basil.remove("user");
          basil.remove("token");
          basil.remove("expand_items");
          this.$router.push({ name: "Login" });
          //
        })
        .catch((res) => {
          console.log(" err");
          console.log(res);
          this.$message("系统错误");
        });
    },
  },
  mounted() {
    if (basil.get("user")) {
      this.username = basil.get("user").username;
    } else {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
