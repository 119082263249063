import dayjs from "dayjs";

const formatterNull = function (row, column, cellValue) {
  if (cellValue) {
    return cellValue;
  } else {
    return "--";
  }
};
const formatterNumber = function (row, column, cellValue) {
  if (cellValue) {
    return cellValue.toFixed(2);
  } else {
    return "--";
  }
};
const formatterRate = function (row, column, cellValue) {
  if (cellValue) {
    return cellValue.toFixed(2) + "/1";
  } else {
    return "--";
  }
};
const formatterDateSt = function (row, column, cellValue) {
  // console.log(cellValue, 'cellValue');
  return dayjs(cellValue).format("YYYY.MM.DD");
};

const formatterTimeHour = function (row, column, cellValue) {
  return dayjs(cellValue).format("YYYY.MM.DD HH");
};

const formatterTimeSt = function (row, column, cellValue) {
  return dayjs(cellValue).format("YYYY.MM.DD HH:mm:ss");
};

const formatterTimeSt2 = function (row, column, cellValue) {
  return dayjs(cellValue).format("YYYY.MM.DD HH:mm");
};
export {
  formatterNull,
  formatterNumber,
  formatterRate,
  formatterDateSt,
  formatterTimeSt,
  formatterTimeHour,
  formatterTimeSt2
};
