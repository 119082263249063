const axios = require("axios");
const qs = require("qs");
// import cookie from "./cookie";
import basil from "@/util/basil";

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.transformRequest = [function (data) {
//     let ret = ''
//     for (let it in data) {
//         ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
//     }
//     return ret
// }]
let request = function ({ method = "get", url, data, type }) {
  // console.log('axios');
  // console.log(this.xyz);
  // console.log(axios.defaults.headers.post["Content-Type"]);
  //   && type !== "json"
  if (method == "post") {
    data = qs.stringify(data);
  }
  // console.log(data);
  let sendObj = {
    method,
    url,
  };
  if (method == "get") {
    sendObj.params = data;
  } else {
    sendObj.data = data;
  }
  if (basil.get("token")) {
    sendObj.headers = { authorization: basil.get("token") };
  }
  //   if (type == "json") {
  //     sendObj.headers["Content-Type"] = "application/json";
  //   }
  return new Promise((resolve, reject) => {
    // ... some code
    axios(sendObj)
      .then((res) => {
        // console.log('mid res');
        // console.log(res);
        if (res.data.status == 1) {
          resolve(res);
        } else {
          if (!res.data.status_detail) {
            res.data.status_detail = "系统错误";
          }

          if (res.data.status == 0) {
            this.$message("请登录");

            this.$router.push({ name: "Login" });
          } else {
            reject(res);
          }
        }
      })
      .catch((err) => {
        // console.log('mid err');
        // console.log(err);
        // console.log(typeof err);
        reject({
          data: { status: 9999, status_detail: "系统错误" },
          myErr: err,
        });
      });
  });
};

let MyPlugin = {};
MyPlugin.install = function (app, options) {
  // 4. 添加实例方法
  app.config.globalProperties.$myRequest = request;
};
export default MyPlugin;
