<template>
  <div class="feed">
    <div class="tower-map">
      <div class="big-towers">
        <div
          v-for="(item, index) in towerBig"
          :key="index"
          class="big-tower-item"
        >
          <div class="big-tower-label">F{{ index + 1 }}</div>
          <div class="big-tower">
            <div class="big-tower-img"></div>
            <div class="guan-s big-tower-guan"></div>
            <div
              :class="
                item.switch
                  ? 'big-tower-switch big-tower-switch-1 big-tower-switch-on'
                  : 'big-tower-switch big-tower-switch-1 '
              "
            ></div>
            <div class="big-tower-capacity">
              <div
                class="big-tower-capacity-value"
                :style="{ height: item.capacity + '%' }"
              ></div>
            </div>
            <div class="big-tower-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="guan-h big-tower-guan1"></div>
      <div class="big-tower-beng big-tower-beng1">
        <div class="big-tower-beng-label">M6</div>
        <div class="big-tower-beng-status">M6开延时 0</div>
        <div class="big-tower-beng-pinlv big-tower-beng-pinlv-1">
          <div class="big-tower-beng-pinlv-label">水平蛟龙频率</div>
          <div class="feed-msg">9.0</div>
          <div class="big-tower-beng-pinlv-danwei">HZ</div>
        </div>
        <div class="big-tower-beng-delay">M6开延时 0</div>
      </div>
      <div class="guan-s big-tower-guan2"></div>
      <div class="guan-h big-tower-guan3"></div>
      <div class="big-tower-beng big-tower-beng2">
        <div class="big-tower-beng-label">M7</div>
        <div class="big-tower-beng-pinlv big-tower-beng-pinlv-2">
          <div class="big-tower-beng-pinlv-label">水平蛟龙频率</div>
          <div class="feed-msg">9.0</div>
          <div class="big-tower-beng-pinlv-danwei">HZ</div>
        </div>
      </div>
      <div class="guan-s big-tower-guan4"></div>
      <div class="loudou">
        <div class="feed-msg feed-msg-1">{{ mixtureTower.topValue }}</div>
        <div class="loudou-item">
          <div class="loudou-label">设定值</div>
          <div class="feed-msg feed-msg-2">{{ mixtureTower.setValue }}</div>
        </div>
        <div class="loudou-item hide">
          <div class="loudou-label">实际值</div>
          <div class="feed-msg feed-msg-2">{{ mixtureTower.value }}</div>
        </div>
        <div
          :class="
            mixtureTower.switch
              ? 'big-tower-switch big-tower-switch-2 big-tower-switch-on'
              : 'big-tower-switch big-tower-switch-2 '
          "
        ></div>
        <div class="loudou-famen-label">称门 Y0</div>
        <div class="loudou-famen-delay">开 延时{{ mixtureTower.delay }}</div>
        <div style="margin-top: 20px" class="loudou-famen-delay">关 延时10</div>
      </div>
      <div class="guan-x guan-x-1"></div>
      <div class="tower-middle"></div>
      <div class="big-tower-beng big-tower-beng3"></div>
      <div class="guan-x guan-x-2"></div>
      <div class="zengyabeng zengyabeng-1">
        <div class="zengyabeng-label">M1<br />空压机</div>
      </div>
      <div class="zengyabeng zengyabeng-2">
        <div class="zengyabeng-label">M2<br />增压泵</div>
      </div>
      <div class="zyb-msg hide">
        <div class="zyb-msg-item" v-for="(item, index) in kongya" :key="index">
          <div class="zyb-msg-label">{{ item.label }}</div>
          <div class="feed-msg">{{ item.value }}</div>
          <div class="zyb-msg-danwei">{{ item.danwei }}</div>
        </div>
      </div>
      <div class="guan-x guan-x-3"></div>
      <div class="guan-x guan-x-4"></div>
      <div class="guan-x guan-x-5"></div>
      <div class="guan-x guan-x-6"></div>
      <div class="guan-x guan-x-7"></div>
      <div class="guan-x guan-x-8"></div>
      <div class="guan-x guan-x-9"></div>
      <div class="guan-x guan-x-10"></div>
      <div class="jian jian-1"></div>
      <div class="small-towers small-towers-1">
        <div
          v-for="(item, index) in towerSmall"
          :key="index"
          :class="`small-tower${item.level == 1 ? ' small-tower-level-1' : ''}${
            item.level == 2 && item.isFirst
              ? ' small-tower-level-2 small-tower-level-2-first'
              : ''
          }${item.level == 2 && !item.isFirst ? ' small-tower-level-2' : ''}`"
        >
          <div class="small-tower-top"></div>
          <div
            :class="`jian small-tower-jian-1${
              item.switch == 0 ? ' jian-off' : ''
            }`"
          ></div>

          <div
            :class="`small-tower-guan-1 guan-x${
              item.switch == 0 ? ' guan-x-off' : ''
            }`"
          ></div>
          <div
            :class="`small-tower-guan-2 guan-x${
              item.switch == 0 ? ' guan-x-off' : ''
            }`"
          ></div>
          <div
            :class="`small-tower-famen small-tower-famen-1${
              item.switch == 0 ? ' small-tower-famen-off' : ''
            }`"
          ></div>

          <div
            :class="`small-tower-guan-3 guan-x${
              item.switchAll == 0 ? ' guan-x-off' : ''
            }`"
          ></div>
          <div
            :class="`small-tower-famen small-tower-famen-2${
              item.switchAll == 0 ? ' small-tower-famen-off' : ''
            }`"
          ></div>
          <div
            :class="`jian small-tower-jian-2${
              item.level == 2 && item.switchAll == 0 ? ' jian-off' : ''
            }`"
          ></div>
          <div class="jian small-tower-jian-3"></div>
          <div class="small-tower-name hide">{{ item.name }}</div>
          <div class="small-tower-label">T{{ index + 1 }}</div>
          <div class="small-tower-tag">
            <div>{{ item.fei }}</div>
          </div>
        </div>
      </div>
      <div class="small-towers small-towers-2">
        <div
          v-for="(item, index) in towerSmall2"
          :key="index"
          :class="`small-tower${item.level == 1 ? ' small-tower-level-1' : ''}${
            item.level == 2 && item.isFirst
              ? ' small-tower-level-2 small-tower-level-2-first'
              : ''
          }${item.level == 2 && !item.isFirst ? ' small-tower-level-2' : ''}`"
        >
          <div class="small-tower-top"></div>
          <div
            :class="`jian small-tower-jian-1${
              item.switch == 0 ? ' jian-off' : ''
            }`"
          ></div>

          <div
            :class="`small-tower-guan-1 guan-x${
              item.switch == 0 ? ' guan-x-off' : ''
            }`"
          ></div>
          <div
            :class="`small-tower-guan-2 guan-x${
              item.switch == 0 ? ' guan-x-off' : ''
            }`"
          ></div>
          <div
            :class="`small-tower-famen small-tower-famen-1${
              item.switch == 0 ? ' small-tower-famen-off' : ''
            }`"
          ></div>

          <div
            :class="`small-tower-guan-3 guan-x${
              item.switchAll == 0 ? ' guan-x-off' : ''
            }`"
          ></div>
          <div
            :class="`small-tower-famen small-tower-famen-2${
              item.switchAll == 0 ? ' small-tower-famen-off' : ''
            }`"
          ></div>
          <div
            :class="`jian small-tower-jian-2${
              item.level == 2 && item.switchAll == 0 ? ' jian-off' : ''
            }`"
          ></div>
          <div class="jian small-tower-jian-3"></div>
          <div class="small-tower-name hide">{{ item.name }}</div>
          <div class="small-tower-label">T{{ index + 16 }}</div>
          <div class="small-tower-tag">
            <div>{{ item.fei }}</div>
          </div>
        </div>
      </div>
      <table class="my-table tower-table">
        <thead>
          <tr>
            <td class="tower-table-td1">多条路径状态</td>
            <td>日期时间</td>
            <td>原料名称</td>
            <td>原料仓</td>
            <td>目的仓</td>
            <td>设定批次</td>
            <td>单次数量</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tableData" :key="index">
            <td>{{ item.path }}</td>
            <td>{{ item.date }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.company }}</td>
            <td>{{ item.target }}</td>
            <td>{{ item.batch }}</td>
            <td>{{ item.num }}</td>
          </tr>
        </tbody>
      </table>
      <div class="feed-box path-select">
        <div class="path-select-title">多条路径选择</div>
        <div class="path-select-con">
          <div class="path-select-left">
            <el-form ref="form" :model="form" class="global-form">
              <el-form-item label="当前原料仓">
                <span style="color: #ffffff">F1</span>

                <el-select v-model="form.f1" placeholder="" class="hide">
                  <el-option label="x1" value="x1"></el-option>
                  <el-option label="x2" value="x2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="当前目的仓">
                <span style="color: #ffffff">T1</span>

                <el-select v-model="form.f2" placeholder="" class="hide">
                  <el-option label="y1" value="y1"></el-option>
                  <el-option label="y2" value="y2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="批次设定">
                <el-input v-model="form.f3"></el-input>
              </el-form-item>
              <el-form-item label="重量设定">
                <el-input v-model="form.f4"></el-input>
              </el-form-item>
            </el-form>
            <div class="path-select-bottom">
              增压泵最小启动间隔：<span>0</span>
            </div>
          </div>
          <div class="path-select-right">
            <div class="my-btn">刷新</div>
            <div class="my-btn">新增</div>
            <div class="my-btn">开始</div>
            <div class="my-btn">删除</div>
            <div class="my-btn">终止</div>
            <div class="my-btn">修改</div>
          </div>
        </div>
      </div>
      <div class="feed-box path-select-2">
        <div class="path-select-title">单条路径状态</div>
        <div class="path-select-con">
          <el-form ref="form" :model="form" class="global-form">
            <el-form-item label="原料名称">
              <span style="color: #ffffff">妊娠母猪</span>
              <el-select v-model="form.f11" placeholder="" class="hide">
                <el-option
                  v-for="(item, index) in towerBig"
                  :key="index"
                  :label="item.name"
                  value="x1"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="当前原料仓">
              <span style="color: #ffffff">F1</span>

              <el-select v-model="form.f1" placeholder="" class="hide">
                <el-option
                  v-for="(item, index) in towerBig"
                  :key="index"
                  :label="'F' + (index + 1)"
                  value="x1"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="当前目的仓">
              <span style="color: #ffffff">T1</span>

              <el-select v-model="form.f2" placeholder="" class="hide">
                <el-option
                  v-for="(item, index) in towerSmall.concat(towerSmall2)"
                  :key="index"
                  :label="'T' + (index + 1)"
                  value="y1"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="批次设定">
              <el-input v-model="form.f3"></el-input>
            </el-form-item>
            <el-form-item label="重量设定">
              <el-input v-model="form.f4"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="my-btn">开始配料</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  data() {
    return {
      form: {
        f1: "",
        f2: "",
        f3: "",
        f4: "",
      },
      tableData: [
        {
          path: "1",
          date: "2021.5.15", //now
          name: "妊娠母猪",
          company: "F1",
          target: "T1",
          batch: "3",
          num: "1.5",
        },
      ],
      towerBig: [
        { capacity: 40, switch: 1, name: "妊娠母猪" },
        { capacity: 50, switch: 0, name: "哺乳母猪" },
        { capacity: 60, switch: 0, name: "保育前期" },
        { capacity: 70, switch: 0, name: "保育后期" },
        { capacity: 80, switch: 0, name: "育肥前期" },
        { capacity: 90, switch: 0, name: "育肥后期" },
      ],
      pump1: { switch: 1, delay: 20, frequency: 9.9 },
      pump2: { switch: 1, delay: 20, frequency: 9.9 },
      mixtureTower: {
        topValue: 10,
        setValue: 10.3,
        value: 10.3,
        switch: 1,
        delay: 20,
      },
      kongya: [
        { label: "风机电流", value: "1", danwei: "A" },
        { label: "关风暴频率输出", value: "1", danwei: "Hz" },
        { label: "风机温度", value: "1", danwei: "C" },
        { label: "关风器频率手动设定", value: "1", danwei: "Hz" },
        { label: "风机压力", value: "1", danwei: "KPa" },
      ],
      towerSmall: [
        {
          capacity: 40,
          fei: "配怀1",
          name: "123",
          switch: 1,
          level: 2,
          switchAll: 1,
          isFirst: true,
        },
        {
          capacity: 40,
          fei: "配怀1",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
        },
        {
          capacity: 40,
          fei: "配怀2",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
          isFirst: true,
        },
        {
          capacity: 40,
          fei: "配怀2",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
        },
        {
          capacity: 40,
          fei: "配怀3",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
          isFirst: true,
        },
        {
          capacity: 40,
          fei: "配怀3",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
        },

        { capacity: 40, fei: "分娩1", name: "123", switch: 0, level: 1 },
        { capacity: 40, fei: "分娩2", name: "123", switch: 0, level: 1 },
        { capacity: 40, fei: "分娩3", name: "123", switch: 0, level: 1 },
        //
        {
          capacity: 40,
          fei: "保育1",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
          isFirst: true,
        },
        {
          capacity: 40,
          fei: "保育1",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
        },
        {
          capacity: 40,
          fei: "保育2",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
          isFirst: true,
        },
        {
          capacity: 40,
          fei: "保育2",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
        },
        {
          capacity: 40,
          fei: "保育3",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
          isFirst: true,
        },
        {
          capacity: 40,
          fei: "保育3",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
        },
        //
      ],
      towerSmall2: [
        { capacity: 40, fei: "公猪舍", name: "123", switch: 0, level: 1 },
        { capacity: 40, fei: "隔离舍", name: "123", switch: 0, level: 1 },
        { capacity: 40, fei: "后备舍", name: "123", switch: 0, level: 1 },
        {
          capacity: 40,
          fei: "育肥1",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
          isFirst: true,
        },
        {
          capacity: 40,
          fei: "育肥1",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
        },
        {
          capacity: 40,
          fei: "育肥2",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
          isFirst: true,
        },
        {
          capacity: 40,
          fei: "育肥2",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
        },
        {
          capacity: 40,
          fei: "育肥3",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
          isFirst: true,
        },
        {
          capacity: 40,
          fei: "育肥3",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
        },
        {
          capacity: 40,
          fei: "育肥4",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
          isFirst: true,
        },
        {
          capacity: 40,
          fei: "育肥4",
          name: "123",
          switch: 0,
          level: 2,
          switchAll: 0,
        },
      ],
      xxx: [
        {
          select: 10,
          time: "11:11",
          name: "xxx",
          company: "xxx",
          entrepot: "sss",
          batch: "aaa",
          num: 100,
        },
      ],
    };
  },
  mounted() {
    console.log("feed index mounted");
    console.log(this.$route);
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
<style  lang="less" >
.feed {
  
}
</style>

