<template>
  <div class="richang padding10">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'MeadowIndex' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item :to="{ name: 'MeadowSmartFeedIndex' }"
        >智能饲喂站</el-breadcrumb-item
      >
      <el-breadcrumb-item>每日流程</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form ref="form" :model="form" :inline="true" class="global-form global-form-inline">
        <el-form-item label="群的数量：">
          <span style="color: #ffffff">15</span>
        </el-form-item>
        <el-form-item label="喂食开始">
          <el-time-picker
            placeholder="选择时间"
            v-model="form.date2"
          ></el-time-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">自动启用</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-table class="table1" :data="tableData" border>
        <el-table-column prop="t1" label="群"> </el-table-column>
        <el-table-column prop="t2" label="开始时间"> </el-table-column>
        <el-table-column prop="t3" label="结束时间"> </el-table-column>
        <el-table-column prop="t4" label="百分比"> </el-table-column>
        <el-table-column prop="t5" label="母猪数量"> </el-table-column>

        <el-table-column prop="t6" label="剩余时间"> </el-table-column>
        <el-table-column prop="t7" label="保持运行"> </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  data() {
    return {
      tableData: [
        {
          t1: "1",
          t2: "4:00",
          t3: "23:00",
          t4: "100",
          t5: "0",
          t6: "30",
          t7: "✔",
        },
        {
          t1: "2",
          t2: "4:00",
          t3: "23:00",
          t4: "100",
          t5: "0",
          t6: "30",
          t7: "✔",
        },
      ],
      form: {
        resource: "",
        type: [],
      },
    };
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
