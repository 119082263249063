<template>
  <div class="password">
    <el-form
      ref="form"
      :model="form"
      class="global-form"
      label-width="150rem"
      :rules="rules"
    >
      <el-form-item label="旧密码" prop="old_password">
        <el-input type="password" v-model="form.old_password"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="new_password">
        <el-input type="password" v-model="form.new_password"></el-input>
      </el-form-item>
      <el-form-item label="重复新密码" prop="r_password">
        <el-input type="password" v-model="form.r_password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import basil from "@/util/basil";

export default {
  name: "Home",
  components: {},
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$myRequest({
            url: "/api/tenant/user/modify_password",
            method: "post",
            data: this.form,
          })
            .then((res) => {
              this.$message("修改成功");
              basil.remove("token");
              basil.remove("user");
              basil.remove("expand_items");
              this.$router.push({ name: "Login" });
            })
            .catch((res) => {
              console.log(" err");
              console.log(res);
              this.$message(res.data.status_detail);
            });
        }
      });
    },
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("必填"));
      } else {
        if (this.form.r_password !== "") {
          this.$refs.form.validateField("r_password");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("必填"));
      } else if (value !== this.form.new_password) {
        callback(new Error("输入不一致"));
      } else {
        callback();
      }
    };
    return {
      form: {
        old_password: "",
        new_password: "",
        r_password: "",
      },
      rules: {
        old_password: [
          {
            required: true,
            message: "必填",
          },
          { min: 6, message: "至少输入6个字符" },
        ],
        new_password: [
          {
            required: true,
            message: "必填",
          },
          { validator: validatePass },
          { min: 6, message: "至少输入6个字符" },
        ],
        r_password: [
          {
            required: true,
            message: "必填",
          },
          { validator: validatePass2 },
          { min: 6, message: "至少输入6个字符" },
        ],
      },
    };
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
