import { createRouter, createWebHashHistory } from "vue-router";
import Login from "@/views/login/index.vue";
// 新首页
import index from "@/views/index/index.vue";
import home from "@/views/home/index.vue";
// 老首页
import projectIndex from "@/views/project/index/index2.vue";
import projectEnv from "@/views/project/env/index.vue";
import projectAlarm from "@/views/project/alarm/index.vue";
import projectVideoBack from "@/views/project/videoBack/index.vue";
import projectVideoLive from "@/views/project/videoLive/index.vue";

import user from "@/views/user/index.vue";
import contacts from "@/views/project/contacts/index.vue";
import find from "@/views/find/index.vue";
import password from "@/views/password/index.vue";
import alarmEnv from "@/views/alarm/env/index.vue";
import alarmDevice from "@/views/alarm/device/index.vue";
import alarmVideo from "@/views/alarm/video/index.vue";
import video from "@/views/video/index.vue";
// import energyWater from '@/views/energy/water/index.vue';
// import energyDian from '@/views/energy/dian/index.vue';

// 能源管理
// 水
import energyWater from "@/views/energyNew/water/index.vue";
// 电
import energyDian from "@/views/energyNew/dian/index.vue";
import guzhong from "@/views/guzhong/index.vue";

import MeadowFeedHome from "@/views/siwei/feed/home/index.vue";
import MeadowFeedIndex from "@/views/siwei/feed/index/index.vue";
import MeadowFeedParam from "@/views/siwei/feed/param/index.vue";

import MeadowWeightHome from "@/views/siwei/weight/home/index.vue";
import MeadowWeightIndex from "@/views/siwei/weight/index/index.vue";
import MeadowWeightIndex2 from "@/views/siwei/weight/index/index2.vue";
import MeadowWeightAdd from "@/views/siwei/weight/add/index.vue";

import MeadowLiquidHome from "@/views/siwei/liquid/home/index.vue";
import MeadowLiquidIndex from "@/views/siwei/liquid/index/index.vue";
import MeadowLiquidAllot from "@/views/siwei/liquid/allot/index.vue";
import MeadowLiquidXiaohao from "@/views/siwei/liquid/xiaohao/index.vue";
import MeadowLiquidBaojing from "@/views/siwei/liquid/baojing/index.vue";
import MeadowLiquidLanwei from "@/views/siwei/liquid/lanwei/index.vue";
import MeadowLiquidPeiliao from "@/views/siwei/liquid/peiliao/index.vue";
import MeadowLiquidPeifang from "@/views/siwei/liquid/peifang/index.vue";
import MeadowLiquidMeiri from "@/views/siwei/liquid/meiri/index.vue";

import MeadowSmartFeedHome from "@/views/siwei/smartFeed/home/index.vue";
import MeadowSmartFeedIndex from "@/views/siwei/smartFeed/index/index.vue";
import MeadowSmartFeedIndex2 from "@/views/eat/siwei/index.vue";
import MeadowSmartFeedQuxianfenpei from "@/views/siwei/smartFeed/quxianfenpei/index.vue";
import MeadowSmartFeedJilu from "@/views/siwei/smartFeed/jilu/index.vue";
import MeadowSmartFeedBaojing from "@/views/siwei/smartFeed/baojing/index.vue";
import MeadowSmartFeedDongwu from "@/views/siwei/smartFeed/dongwu/index.vue";
import MeadowSmartFeedFenli from "@/views/siwei/smartFeed/fenli/index.vue";
import MeadowSmartFeedYanse from "@/views/siwei/smartFeed/yanse/index.vue";
import MeadowSmartFeedWeijiliang from "@/views/siwei/smartFeed/weijiliang/index.vue";
import MeadowSmartFeedRichang from "@/views/siwei/smartFeed/richang/index.vue";

import Wash from "@/views/wash/card/index.vue";
import WashList from "@/views/wash/list/index.vue";
import RenYuan from "@/views/wash/renYuan/index.vue";
import WuZi from "@/views/wash/wuZi/index.vue";
import CheLiang from "@/views/wash/cheLiang/index.vue";
// import WaiWei from '@/views/wash/waiWei/index.vue'
import WaiWei from "@/views/project/videoLive/index.vue";

import CeDing from "@/views/eat/ceding/index.vue";
import SiWei from "@/views/eat/siwei/index.vue";
import Mamados from "@/views/eat/mamados/index.vue";
import HuanTian from "@/views/huanbao/huanTian/index.vue";
import DataPanel from "@/views/huanbao/dataPanel/index.vue";
import HuanTian2 from "@/views/huanbao2/huanTian/index.vue";
import DataPanel2 from "@/views/huanbao2/dataPanel/index.vue";

import LiquidIFrame from "@/views/liquidFeed/index/index.vue";
import LiquidFeedDetail from "@/views/liquidFeed/frame/index.vue";
import SheIndex from "@/views/sheIndex/index.vue";

export const routes = [
  {
    path: "/find",
    name: "find",
    component: find,
  },
  {
    path: "/",
    // name: 'index',
    // component: index,
    redirect: "/project/index",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/manage",
    name: "manageHome",
    component: home,
    children: [
      {
        path: "user",
        name: "user",
        component: user,
      },
      {
        path: "password",
        name: "password",
        component: password,
      },
    ],
  },

  {
    path: "/project",
    name: "projectHome",
    component: home,
    children: [
      {
        path: "",
        name: "projectIndex",
        component: projectIndex,
      },
      {
        path: "index",
        name: "homeIndex",
        component: index,
      },
      {
        path: "env",
        name: "projectEnv",
        component: projectEnv,
      },
      {
        path: "alarm",
        name: "projectAlarm",
        component: projectAlarm,
      },

      {
        path: "contacts",
        name: "contacts",
        component: contacts,
      },
      {
        path: "videoBack",
        name: "projectVideoBack",
        component: projectVideoBack,
      },
      {
        path: "videoLive",
        name: "projectVideoLive",
        component: projectVideoLive,
      },

      {
        path: "/alarmEnv",
        name: "alarmEnv",
        component: alarmEnv,
      },
      {
        path: "/alarmDevice",
        name: "alarmDevice",
        component: alarmDevice,
      },
      {
        path: "/alarmVideo",
        name: "alarmVideo",
        component: alarmVideo,
      },
      {
        path: "/video",
        name: "video",
        component: video,
      },
      {
        path: "/energyWater",
        name: "energyWater",
        component: energyWater,
      },
      {
        path: "/energyDian",
        name: "energyDian",
        component: energyDian,
      },
      {
        path: "/guzhong",
        name: "guzhong",
        component: guzhong,
      },

      {
        path: "feed/",
        name: "MeadowFeedHome",
        component: MeadowFeedHome,
        children: [
          {
            path: "index",
            name: "MeadowFeedIndex",
            component: MeadowFeedIndex,
          },
          {
            path: "param",
            name: "MeadowFeedParam",
            component: MeadowFeedParam,
          },
        ],
      },
      {
        path: "weight/",
        name: "MeadowWeightHome",
        component: MeadowWeightHome,
        children: [
          // 料塔称重
          {
            path: "index",
            name: "MeadowWeightIndex",
            component: MeadowWeightIndex,
          },
          {
            path: "add",
            name: "MeadowWeightAdd",
            component: MeadowWeightAdd,
          },
        ],
      },
      {
        // 料塔称重-接口数据
        path: "weight/index2",
        name: "MeadowWeightIndex2",
        component: MeadowWeightIndex2,
      },
      {
        path: "liquid_frame",
        // name: "LiquidIFrame",
        // component: LiquidIFrame,
        children: [
          {
            path: "",
            name: "LiquidIFrame",
            component: LiquidIFrame,
          },
          {
            path: "detail",
            name: "LiquidFeedDetail",
            component: LiquidFeedDetail,
          },
        ],
      },
      {
        path: "liquid/",
        name: "MeadowLiquidHome",
        component: MeadowLiquidHome,
        children: [
          {
            path: "index",
            name: "MeadowLiquidIndex",
            component: MeadowLiquidIndex,
          },
          {
            path: "allot",
            name: "MeadowLiquidAllot",
            component: MeadowLiquidAllot,
          },
          {
            path: "xiaohao",
            name: "MeadowLiquidXiaohao",
            component: MeadowLiquidXiaohao,
          },
          {
            path: "baojing",
            name: "MeadowLiquidBaojing",
            component: MeadowLiquidBaojing,
          },
          {
            path: "lanwei",
            name: "MeadowLiquidLanwei",
            component: MeadowLiquidLanwei,
          },
          {
            path: "peiliao",
            name: "MeadowLiquidPeiliao",
            component: MeadowLiquidPeiliao,
          },
          {
            path: "peifang",
            name: "MeadowLiquidPeifang",
            component: MeadowLiquidPeifang,
          },
          {
            path: "meiri",
            name: "MeadowLiquidMeiri",
            component: MeadowLiquidMeiri,
          },
        ],
      },
      {
        path: "smartFeed/",
        name: "MeadowSmartFeedHome",
        component: MeadowSmartFeedHome,
        children: [
          // 中育的智能饲喂
          {
            path: "index0",
            name: "MeadowSmartFeedIndex0",
            // component: MeadowSmartFeedIndex,
            component: CeDing,
          },

          // 新做的智能饲喂
          {
            path: "index",
            name: "MeadowSmartFeedIndex",
            component: MeadowSmartFeedIndex2,
          },
          {
            path: "quxianfenpei",
            name: "MeadowSmartFeedQuxianfenpei",
            component: MeadowSmartFeedQuxianfenpei,
          },
          {
            path: "jilu",
            name: "MeadowSmartFeedJilu",
            component: MeadowSmartFeedJilu,
          },
          {
            path: "baojing",
            name: "MeadowSmartFeedBaojing",
            component: MeadowSmartFeedBaojing,
          },
          {
            path: "dongwu",
            name: "MeadowSmartFeedDongwu",
            component: MeadowSmartFeedDongwu,
          },
          {
            path: "fenli",
            name: "MeadowSmartFeedFenli",
            component: MeadowSmartFeedFenli,
          },
          {
            path: "yanse",
            name: "MeadowSmartFeedYanse",
            component: MeadowSmartFeedYanse,
          },
          {
            path: "weijiliang",
            name: "MeadowSmartFeedWeijiliang",
            component: MeadowSmartFeedWeijiliang,
          },
          {
            path: "richang",
            name: "MeadowSmartFeedRichang",
            component: MeadowSmartFeedRichang,
          },
        ],
      },
      {
        path: "wash",
        name: "Wash",
        component: Wash,
      },
      {
        path: "wash_list",
        name: "WashList",
        component: WashList,
      },
      {
        path: "ren_yuan",
        name: "RenYuan",
        component: RenYuan,
      },
      {
        path: "che_liang",
        name: "CheLiang",
        component: CheLiang,
      },
      {
        path: "wu_zi",
        name: "WuZi",
        component: WuZi,
      },
      {
        path: "wai_wei",
        name: "WaiWei",
        component: WaiWei,
      },

      {
        path: "eat_ceding",
        name: "CeDing",
        component: CeDing,
      },

      {
        path: "eat_mamados",
        name: "Mamados",
        component: Mamados,
      },

      {
        path: "eat_siwei",
        name: "SiWei",
        component: SiWei,
      },
      {
        path: "huanbao_huantian", // 猪
        name: "HuanTian",
        component: HuanTian,
      },
      {
        path: "data_panel",
        name: "DataPanel",
        component: DataPanel,
      },
      {
        path: "huanbao_huantian2", // 牛
        name: "HuanTian2",
        component: HuanTian2,
      },
      {
        path: "data_panel2",
        name: "DataPanel2",
        component: DataPanel2,
      },
      {
        path: "she_index",
        name: "SheIndex",
        component: SheIndex,
      },
      {
        path: "maidong",
        name: "MaiDong",
        component: () => import("@/views/maidong/index/index.vue"),
      },
      {
        path: "maidong/banci",
        name: "BanCi",
        component: () => import("@/views/maidong/banci/index.vue"),
      },
      {
        path: "maidong/shit",
        name: "Shit",
        component: () => import("@/views/maidong/shit/index.vue"),
      },
      {
        path: "maidong/result",
        name: "ResultHome",
        component: () => import("@/views/maidong/result/index.vue"),
      },
      {
        path: "penlin",
        name: "PenLin",
        component: () => import("@/views/penlin/index/index.vue"),
      },
      {
        path: "penlin/unit",
        name: "PenLinUnit",
        component: () => import("@/views/penlin/unit/index.vue"),
      },
      {
        path: "penlin/shuicao",
        name: "PenLinShuiCao",
        component: () => import("@/views/penlin/shuicao/index.vue"),
      },
      {
        path: "penlin/env",
        name: "PenLinEnv",
        component: () => import("@/views/penlin/env/index.vue"),
      },
      {
        path: "huankong/index",
        name: "HuangKong",
        component: () => import("@/views/huankong/index/index.vue"),
      },
      {
        path: "huankong/Unit",
        name: "HuangKongUnit",
        component: () => import("@/views/huankong/unit/index.vue"),
      },
      {
        path: "huankong/alarm",
        name: "HuangKongAlarm",
        component: () => import("@/views/huankong/alarm/index.vue"),
      },
    ],
  },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
