<template>
  <div class="video">
    <div class="video-box">
      <div :class="{ 'video-left': true, 'video-left-close': !open }">
        <div class="video-title">监控位置</div>
        <videoList v-model="cameraIndexCode"></videoList>
      </div>
      <div class="video-line" @click="open = !open">
        <div
          :class="{ 'video-line-open': open, 'video-line-close': !open }"
        ></div>
      </div>
      <div class="video-right">
        <div class="global-title">实时监控</div>
        <div class="video-obj-box">
          <div id="video" class="video-obj"></div>
          <div v-if="this.videoInfo.status != 2" class="video-msg">
            <div class="video-msg-icon"></div>
            <template v-if="this.videoInfo.status == 0"
              >选择一个监控位置，即可查看监控视频</template
            >
            <template v-if="this.videoInfo.status == 1"
              >监控视频加载中...</template
            >
            <template v-if="this.videoInfo.status == 3"
              >加载失败，请重试</template
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videoList from "@/components/video-list/index.vue";

import moment from "moment";
export default {
  name: "Page",
  components: { videoList },
  data() {
    return {
      open: true,
      url: "",
      cameraIndexCode: "",
      videoInfo: {
        status: 0,
      },
    };
  },
  watch: {
    cameraIndexCode(newV, oldV) {
      if (newV && newV != oldV) {
        this.getUrl();
      }
    },
    open() {
      setTimeout(() => {
        this.player.JS_Resize().then(
          () => {
            console.info("JS_Resize success");
            // do you want...
          },
          (err) => {
            console.info("JS_Resize failed");
            // do you want...
          }
        );
      }, 0);
    },
  },
  methods: {
    getUrl() {
      this.videoInfo.status = 1;

      // return;
      this.$myRequest({
        url: `/api/tenant/hik/camera/preview/url`,
        data: {
          cameraIndexCode: this.cameraIndexCode,
        },
      })
        .then((res) => {
          console.log("url res");
          console.log(res);
          // return;
          if (res.data.body && res.data.body.url) {
            this.url = res.data.body.url;
            this.play();
          } else {
            this.$message("加载失败，请重试");
            this.player.JS_Stop();
            this.videoInfo.status = 3;
          }
          //
        })
        .catch((res) => {
          console.log("url err");
          console.log(res);
          // this.$message(res.data.status_detail);
          this.$message("加载失败，请重试");

          this.videoInfo.status = 3;
        });
    },
    play() {
      console.log("play");

      this.player
        .JS_Play(this.url, { playURL: this.url, mode: 0 }, 0)
        .then((res) => {
          console.log("play res");
          console.log(res);
          this.videoInfo.status = 2;
        })
        .catch((res) => {
          console.log("play err");
          console.log(res);
          this.videoInfo.status = 3;
        });
    },
  },
  mounted() {
    this.player = new window.JSPlugin({
      szId: "video",
      szBasePath: "/haikang",
      // szBasePath: "/tenant/haikang",

      // iMaxSplit: 4,
      // iCurrentSplit: 2,
      // openDebug: true,
      // oStyle: {
      //   borderSelect: "#FFCC00",
      // },
    });
    this.player.JS_SetWindowControlCallback({
      pluginErrorHandler: (index, iErrorCode, oError) => {
        //插件错误回调 // do you want...
        console.log("pluginErrorHandler");
        this.videoInfo.status = 3;
      },
    });
    // console.log(parseInt(moment().format("x")));
    // console.log(new Date().getTime());
  },
  beforeUnmount() {
    this.player.JS_Stop();
    this.player = null;
  },
};
</script>
<style scoped lang="less" src="../videoBack/index.less">
</style>
