<template>
  <div class="global-box wrapper">
    <div class="top">
      <div
        v-for="(item, index) in data"
        class="card"
        :key="index"
        :class="{ active: index === cardIndex }"
        @click="() => clickHandle(index)"
      >
        <div>{{ item.forMatName }}</div>
      </div>
    </div>

    <div class="tip">{{ tipTitle }}</div>

    <el-table
      :data="tableData"
      class="global-box-table"
      stripe
      border
      height="100"
    >
      <el-table-column prop="order" label="序号" width="100px">
      </el-table-column>
      <el-table-column prop="seat_no" label="栏位号" :formatter="formatterNull">
      </el-table-column>
      <el-table-column
        prop="aid"
        label="耳标号"
        :formatter="formatterNull"
      ></el-table-column>
      <el-table-column
        prop="amount"
        label="昨日采食量（kg）"
        :formatter="formatterNull"
      ></el-table-column>
      <el-table-column label="操作" width="200px">
        <template #default="scoped">
          <span @click="() => openDetai(scoped.row)" class="detail"
            >饲喂详情</span
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form
        :inline="true"
        :model="form"
        class="global-form global-form-inline"
      >
        <el-form-item>
          <el-date-picker
            v-model="form.date"
            type="daterange"
            range-separator="至"
          />
        </el-form-item>

        <el-form-item>
          <span class="btn-reset" @click="onReset" style="margin-right: 10px"
            >重置</span
          >
          <span class="btn-search" @click="onSubmit">查询</span>
        </el-form-item>
      </el-form>
      <div class="tabs">
        <div
          @click="() => tabClick(0)"
          class="tab-item"
          :class="{ active: tabIndex == 0 }"
        >
          <span>饲喂图表</span>
        </div>
        <div
          @click="() => tabClick(1)"
          class="tab-item"
          :class="{ active: tabIndex == 1 }"
        >
          <span>饲喂数据</span>
        </div>
      </div>
      <div class="tab-content">
        <v-chart
          ref="chart"
          v-if="tabIndex == 0"
          class="chart"
          :option="createOption()"
          autoresize
        />

        <el-table
          v-if="tabIndex == 1"
          :data="tab1TableData"
          class="dialog-box-table"
          stripe
          border
        >
          <el-table-column
            prop="order"
            label="序号"
            width="100px"
            :formatter="formatterNull"
          >
          </el-table-column>
          <el-table-column
            prop="date"
            label="日期"
            :formatter="formatterDateSt"
          ></el-table-column>
          <el-table-column
            prop="amount"
            label="采食量（kg）"
            :formatter="formatterNull"
          ></el-table-column>
          <el-table-column
            prop="targetAmount"
            label="分配量（kg）"
            :formatter="formatterNull"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  formatterNull,
  formatterNumber,
  formatterRate,
  formatterDateSt,
} from "@/util/formatter.js";
import basil from "@/util/basil";
import dayjs from "dayjs";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { maxBy, minBy } from "lodash-es";
// import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { LineChart } from "echarts/charts";
use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
]);

import {midata}  from './data'

const colors = ["#6EBA50", "#748AD2", "#FF9B78"];
export const data = Array.from({ length: 23 }, (v, k) => ({
  index: k,
}));

export default {
  name: "Mamados",
  components: { VChart },
  data() {
    return {
      projectid: basil.get("projectid"),
      cardIndex: 0,
      data: [],
      tableData: [],
      dialogVisible: false,
      // dialogTitle: "fac02336-饲喂详情",
      form: {
        date: [dayjs().subtract(1, "week"), dayjs()],
      },
      tabIndex: 0,
      activeRow: {},
      dateArr: [],
      weightArr: [],
      amountArr: [],
      targetAmountArr: [],
      tab1TableData: [],
    };
  },
  computed: {
    tipTitle() {
      const a = maxBy(this.tableData, (i) => i.seat_no);
      const b = minBy(this.tableData, (i) => i.seat_no);
      return `Box${this.data[this.cardIndex]?.forMatName}(${a?.seat_no || ""}-${
        b?.seat_no || ""
      })-动物信息`;
    },
    dialogTitle() {
      return this.data[this.cardIndex]?.name + "饲喂详情";
    },
  },
  methods: {
    onReset() {
      this.form = {
        date: [dayjs().subtract(1, "week"), dayjs()],
      };
    },
    onSubmit() {
      this.getAnimaiDetail();
    },
    formatterNull,
    formatterNumber,
    formatterRate,
    formatterDateSt,
    clickHandle(index) {
      this.cardIndex = index;
      this.getTableData();
    },
    openDetai(row) {
      this.activeRow = row;
      this.getAnimaiDetail();
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    tabClick(index) {
      this.tabIndex = index;
    },
    createOption() {
      return {
        color: colors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: true,
          },
        },
        legend: {
          tooltip: {
            show: true,
          },
          textStyle: {
            color: "#B4C0D3",
          },
        },
        grid: {
          top: 50,
          left: 30,
          right: 30,
          bottom: 20,
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#44B1DD",
              },
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return params.value;
                },
              },
            },

            data: this.dateArr,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "采食/kg",
            // min: 0,
            // max: 10,
            axisLine: {
              onZero: false,
              show: true,
              lineStyle: {
                color: "#44B1DD",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#3C5389",
              },
            },
            nameTextStyle: {
              color: "#44B1DD",
            },
          },
          // {
          //   type: "value",
          //   name: "重量/kg",
          //   // min: 25,
          //   // max: 70,
          //   axisLine: {
          //     onZero: false,
          //     show: true,
          //     lineStyle: {
          //       color: "#44B1DD",
          //     },
          //   },
          //   splitLine: {
          //     lineStyle: {
          //       color: "#3C5389",
          //     },
          //   },
          //   nameTextStyle: {
          //     color: "#44B1DD",
          //   },
          // },
        ],
        series: [
          {
            name: "采食量",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 8,
            emphasis: {
              focus: "series",
            },
            data: this.amountArr,
            yAxisIndex: 0,
          },
          {
            name: "分配量",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 8,
            data: this.targetAmountArr,
            yAxisIndex: 0,
          },
          // {
          //   name: "重量/kg",
          //   symbol: "circle",
          //   type: "line",
          //   smooth: true,
          //   symbolSize: 8,
          //   data: this.weightArr,
          //   yAxisIndex: 1,
          // },
        ],
      };
    },
    forMatName(name) {
      const index = name.lastIndexOf("-");
      return name.substring(index + 1);
    },
    getStationList() {
      this.$myRequest({
        url: `/api/tenant/feeding/${this.projectid}/station/list`,
        data: {
          type: "mamados",
        },
      })
        .then((res) => {
          res = res.data;
          res.body.forEach((i) => {
            i.forMatName = this.forMatName(i.name);
          });
          this.data = res.body;
          res.body.sort((a, b) => a.forMatName - b.forMatName);
          this.getTableData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getTableData() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/feeding/${this.projectid}/animal/list`,
          data: {
            projectid: this.projectid,
            uuid: this.data[this.cardIndex].uuid,
          },
        });
        res = res.data;
        res.body.forEach((i, index) => (i.order = index + 1));
        this.tableData = res.body;
      } catch (err) {
        console.log(err);
      }
    },
    async getAnimaiDetail() {
      try {
        // let res = await this.$myRequest({
        //   url: `/api/tenant/feeding/${this.projectid}/animal/history`,
        //   data: {
        //     uuid: this.data[this.cardIndex].uuid,
        //     flag: this.activeRow.seat_no,

        //     // uuid: "160011957787002811",
        //     // flag: "8521",
        //     start_date: dayjs(this.form.date[0]).format("YYYY-MM-DD"),
        //     end_date: dayjs(this.form.date[1]).format("YYYY-MM-DD"),
        //   },
        // });
        // res = res.data;

        let res = midata

        
        res.body.forEach((i, index) => (i.order = index + 1));
        this.dateArr = res.body.map((i) => dayjs(i.date).format("YYYY-MM-DD"));
        this.weightArr = res.body.map((i) => i.weight);
        this.amountArr = res.body.map((i) => i.amount);
        this.targetAmountArr = res.body.map((i) => i.targetAmount);
        this.tab1TableData = res.body;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getStationList();

    // this.getAnimaiDetail();
  },
};
</script>
<style scoped lang="less" src="../ceding/style.less"></style>
<style scoped lang="less" src="./style.less"></style>
