export const data = [
  {
    index: 1,
    room: "消毒间1",
    type: "熏蒸",
    startTime: "2022-08-9 16:00:00",
    duration: "2小时",
    mode: "正常开门",
    openTime: "2022-08-9 18:00:00",
    result: "合格",
    xiaoPerson: "王武",
    openPerson: "王武",
  },
  {
    index: 2,
    room: "消毒间1",
    type: "浸泡",
    startTime: "2022-08-8 15:55:03",
    duration: "3小时",
    mode: "正常开门",
    openTime: "2022-08-8 18:55:00",
    result: "不合格",
    xiaoPerson: "王武",
    openPerson: "王武",
  },
  {
    index: 3,
    room: "消毒间2",
    type: "烘干",
    startTime: "2022-08-8 10:25:12",
    duration: "2小时20分",
    mode: "强制开门",
    openTime: "2022-08-8 12:45:10",
    result: "无结果",
    xiaoPerson: "王武",
    openPerson: "王武",
  },
  {
    index: 4,
    room: "消毒间1",
    type: "熏蒸",
    startTime: "2022-08-5 10:06:01",
    duration: "2小时",
    mode: "正常开门",
    openTime: "2022-08-5 12:06:01",
    result: "合格",
    xiaoPerson: "郭一飞",
    openPerson: "郭一飞",
  },
  {
    index: 5,
    room: "消毒间1",
    type: "熏蒸",
    startTime: "2022-08-5 10:04:06",
    duration: "2小时",
    mode: "正常开门",
    openTime: "2022-08-5 12:04:00",
    result: "合格",
    xiaoPerson: "郭一飞",
    openPerson: "郭一飞",
  },
  {
    index: 6,
    room: "消毒间1",
    type: "烘干",
    startTime: "2022-08-5 10:04:00",
    duration: "2小时30分",
    mode: "强制开门",
    openTime: "2022-08-5 13:34:00",
    result: "不合格",
    xiaoPerson: "王武",
    openPerson: "郭一飞",
  },
  {
    index: 7,
    room: "消毒间1",
    type: "熏蒸",
    startTime: "2022-08-1 10:10:03",
    duration: "2小时22分",
    mode: "强制开门",
    openTime: "2022-08-9 18:00:00",
    result: "不合格",
    xiaoPerson: "张强",
    openPerson: "张强",
  },
  {
    index: 8,
    room: "消毒间2",
    type: "烘干",
    startTime: "2022-08-1 10:09:03",
    duration: "3小时",
    mode: "正常开门",
    openTime: "2022-08-1 13:12:03",
    result: "合格",
    xiaoPerson: "张强",
    openPerson: "张强",
  },
];
