<template>
  <div class="wrapper">
    <div class="top">
      <div class="item brn">
        <span class="key">单元总数量：</span>
        <span class="value">{{ num1 }}</span>
      </div>
      <div class="item brn">
        <span class="key">正常单元数量：</span>
        <span class="value">{{ num2 }}</span>
      </div>
      <div class="item brn">
        <span class="key">空舍单元数量：</span>
        <span class="value">{{ num3 }}</span>
      </div>
      <div class="item">
        <span class="key">异常单元数量：</span>
        <span class="value">{{ num4 }}</span>
      </div>
    </div>
    <div class="unit-list focus" v-if="favoriteList.length">
      <div class="block-title">
        <span class="text">特别关注</span>
      </div>
      <div class="block-content">
        <div class="unit-item" v-for="(unit, index2) in favoriteList" :key="index2" :class="{
          mrn: index2 % 5 == 4,
          empty: unit.online && unit.empty,
          alarm: unit.online && unit.have_alarm,
          offline: !unit.online,
        }">
          <div class="item-title">
            <span class="text">{{ unit.house_unit_name }}</span>
            <span class="icon"></span>
            <template v-if="unit.online">
              <span class="status" v-if="unit.have_alarm">报警</span>
              <span class="status" v-else-if="unit.empty">空舍</span>
              <span v-else class="status">在线</span>
            </template>
            <template v-else>
              <span class="status">离线</span>
            </template>

          </div>
          <div class="item-body">
            <div class="card br bb">
              <div class="value">{{ unit.temperature }} ℃</div>
              <div class="text">平均温度</div>
              <img class="wenduji" :src="require('./img/wenduji.png')" />
            </div>
            <div class="card bb">
              <div class="value ">{{ unit.ventilation_Level }} 级</div>
              <div class="text">通风等级</div>
              <img class="feng" :src="require('./img/feng.png')" />
            </div>
            <div class="card br">
              <div class="value ">{{ unit.water_consumption }} t</div>
              <div class="text">今日用水</div>
              <img class="shui" :src="require('./img/shui.png')" />
            </div>
            <div class="card">
              <div class="value">{{ unit.electric_consumption }}kw·k</div>
              <div class="text">今日用电</div>
              <img class="dian" :src="require('./img/dian.png')" />
            </div>
          </div>
          <div class="item-footer">
            <div v-if="unit.favorite" class="btn" @click="nofav(unit)">取消关注</div>
            <div v-else class="btn" @click="fav(unit)">特别关注</div>
            <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
              <div class="btn" @click="getOption(unit)">关注信息</div>
              <template #content>
                <el-checkbox-group v-model="checkboxGroup1" disabled text-color="#fff">
                  <div><el-checkbox label="平均温度" /></div>
                  <div><el-checkbox label="通风等级" /></div>
                  <div><el-checkbox label="今日用水" /></div>
                  <div><el-checkbox label="今日用电" /></div>
                </el-checkbox-group>
              </template>
            </el-tooltip>
          </div>
        </div>
      </div>

    </div>
    <div class="unit-list" v-for="(ite, index) in unitList" :key="index">
      <div class="block-title">
        <span class="text">{{ ite.unitName }}</span>
        <span class="num">共{{ ite.children.length }}个单元</span>
        <span class="btn" v-if="ite.expand" @click="expandUnit(index)">收起</span>
        <span class="btn" v-if="!ite.expand" @click="expandUnit(index)">展开</span>
      </div>
      <div class="block-content" v-if="ite.expand">
        <div class="unit-item" v-for="(unit, index2) in ite.children" :key="index2" :class="{
          mrn: index2 % 5 == 4,
          empty: unit.online && unit.empty,
          alarm: unit.online && unit.have_alarm,
          offline: !unit.online,
        }">
          <div class="item-title">
            <span class="text">{{ unit.house_unit_name }}</span>
            <span class="icon"></span>
            <template v-if="unit.online">
              <span class="status" v-if="unit.have_alarm">报警</span>
              <span class="status" v-else-if="unit.empty">空舍</span>
              <span v-else class="status">在线</span>
            </template>
            <template v-else>
              <span class="status">离线</span>
            </template>

          </div>
          <div class="item-body">
            <div class="card br bb">
              <div class="value">{{ unit.temperature || '--' }} ℃</div>
              <div class="text">平均温度</div>
              <img class="wenduji" :src="require('./img/wenduji.png')" />
            </div>
            <div class="card bb">
              <div class="value ">{{ unit.ventilation_Level || '--' }} 级</div>
              <div class="text">通风等级</div>
              <img class="feng" :src="require('./img/feng.png')" />
            </div>
            <div class="card br">
              <div class="value ">{{ unit.water_consumption || '--' }} t</div>
              <div class="text">今日用水</div>
              <img class="shui" :src="require('./img/shui.png')" />
            </div>
            <div class="card">
              <div class="value">{{ unit.electric_consumption || '--' }} kw·k</div>
              <div class="text">今日用电</div>
              <img class="dian" :src="require('./img/dian.png')" />
            </div>
          </div>
          <div class="item-footer">
            <div v-if="unit.favorite" class="btn" @click="nofav(unit)">取消关注</div>
            <div v-else class="btn" @click="fav(unit)">特别关注</div>
            <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
              <div class="btn" @click="getOption(unit)">关注信息</div>
              <template #content>
                <el-checkbox-group v-model="checkboxGroup1" disabled text-color="#fff">
                  <div><el-checkbox label="平均温度" /></div>
                  <div><el-checkbox label="通风等级" /></div>
                  <div><el-checkbox label="今日用水" /></div>
                  <div><el-checkbox label="今日用电" /></div>
                </el-checkbox-group>
              </template>
            </el-tooltip>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import basil from "../../util/basil";
import { groupBy } from "lodash-es"
export default {
  data() {
    return {
      projectid: basil.get("projectid"),
      num1: 0,
      num2: 0,
      num3: 0,
      num4: 0,
      unitList: [],
      favoriteList: [],
      checkboxGroup1: ['平均温度', '通风等级', '今日用水', '今日用电']
    }
  },
  async mounted() {
    await this.getUnitList()

  },
  methods: {
    achange(value) {
      console.log(value);
      this.checked1 = true;
    },
    expandUnitItems() {
      const expandItems = basil.get("expand_items") || []
      if (!expandItems?.length) {
        this.unitList[0].expand = true
        basil.set("expand_items", [ this.unitList[0].unitName])
      } else {
        this.unitList.forEach(item => {
          if (expandItems.includes(item.unitName)) {
            item.expand = true;
          }
        })
      }
    },
    async getUnitList() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/asset/${this.projectid}/statistics`,
        })
        const list = res.data.body
        this.num1 = list.length
        this.num2 = list.filter(i => i.online).length
        this.num3 = list.filter(i => i.empty).length
        this.num4 = list.filter(i => i.have_alarm).length
        this.favoriteList = list.filter(i => i.favorite)
        let groups = groupBy(list, i => i.house_name)
        this.unitList = Object.keys(groups).map((name, index) => ({
          unitName: name,
          children: groups[name],
          // expand: index == 0
          // expand: true
        }))
        this.expandUnitItems()
      } catch (e) {
        console.log(e);
      }
    },
    expandUnit(index) {
      const ex = this.unitList[index].expand
      this.unitList[index].expand = !ex
      const expandItems = this.unitList.reduce((acc,cur)=> {
        if(cur.expand) {
          acc.push(cur.unitName)
        }
        return acc
      },[])
      basil.set("expand_items", expandItems)
    },
    async getUnitOption(houseid) {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/asset/${this.projectid}/option/${houseid}/unit`,
        })
        res = res.data
      } catch (e) {
        console.log(e);
      }
    },
    async nofav({ house_unit_id }) {
      try {
        let res = await this.$myRequest({
          method: "delete",
          url: `/api/tenant/asset/${this.projectid}/unit/${house_unit_id}/unfavorite`,
        })
        this.getUnitList()
        this.$message({
          type: "success",
          message: "取消成功"
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fav({ house_unit_id }) {
      console.log(house_unit_id);
      try {
        let res = await this.$myRequest({
          method: "put",
          url: `/api/tenant/asset/${this.projectid}/unit/${house_unit_id}/favorite`,
        })
        this.getUnitList()
        this.$message({
          type: "success",
          message: "收藏成功"
        });

      } catch (e) {
        console.log(e);
      }
    },
    async getOption({ house_id }) {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/asset/${this.projectid}/option/${house_id}/unit`,
        })
        console.log(res);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="less" src="./index.less">

</style>
