<template>
  <div class="video">
    <el-form :inline="true" :model="form" class="demo-form-inline global-form global-form-inline">
      <el-form-item label="监控位置">
        <el-select v-model="form.position" placeholder="">
          <el-option
            :label="item.name"
            :value="item.url"
            v-for="(item, index) in option['p' + $route.query.p]"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="&nbsp;" class="item2">
        <el-radio-group v-model="form.type">
          <el-radio :label="1">实时监控</el-radio>
          <el-radio :label="2">历史监控</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.type == 2" label="时间">
        <el-select v-model="history" placeholder="">
          <el-option
            :label="item"
            :value="item"
            v-for="(item, index) in historyArr"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="video-con">
      <div class="video-left">
        <div class="video-prev"></div>
        <div class="video-box">
          <div class="global-title">监控画面</div>
          <div class="video-border">
            <video
              v-if="show"
              loop
              muted
              controls
              autoplay
              ref="video"
              :src="form.position"
            ></video>
          </div>
        </div>

        <div class="video-next"></div>
      </div>
      <div class="video-warning">
        <div class="global-title">异常记录</div>
        <div class="video-warning-list">
          <div
            class="video-warning-item video-warning-item-read"
            v-for="(item, index) in warningHistory"
            :key="index"
            :data-time="item"
            @click="warningClick"
          >
            {{ item }}
            <div class="video-warning-tag"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baoyu1 from "../../assets/a.mp4";
import muzhu5 from "../../assets/a.mp4";
import muzhu6 from "../../assets/a.mp4";
import nanchanfang3 from "../../assets/a.mp4";
import nanchanfang5 from "../../assets/a.mp4";

import damen from "../../assets/a.mp4";
import waiwei1 from "../../assets/a.mp4";
import waiwei2 from "../../assets/a.mp4";
import wushui from "../../assets/a.mp4";

export default {
  name: "Page",
  components: {},
  data() {
    let option = {
      p1: [
        { name: "大门", url: damen },
        { name: "外围1", url: waiwei1 },
        { name: "外围2", url: waiwei2 },
        { name: "污水", url: wushui },
      ],
      p2: [
        { name: "保育舍1单元", url: baoyu1 },
        { name: "母猪舍5单元", url: muzhu5 },
        { name: "母猪舍6单元", url: muzhu6 },
        { name: "南产房3单元", url: nanchanfang3 },
        { name: "南产房5单元", url: nanchanfang5 },
      ],
      p3: [
        { name: "人员位置1", url: waiwei1 },
        { name: "人员位置2", url: waiwei2 },
      ],
      p4: [
        { name: "车辆位置1", url: waiwei1 },
        { name: "车辆位置2", url: waiwei2 },
      ],
      p5: [
        { name: "物资位置1", url: waiwei1 },
        { name: "物资位置2", url: waiwei2 },
      ],
    };
    let historyArr = [
      "2020-1-1 1:1:1",
      "2020-1-2 1:1:1",
      "2020-1-3 1:1:1",
      "2020-1-4 1:1:1",
    ];
    return {
      warningHistory: ["2020-1-1 1:1:1", "2020-1-2 1:1:1"],
      history: historyArr[0],
      historyArr,
      option,
      form: {
        position: option["p" + this.$route.query.p][0].url,
        type: 1,
      },
      videoUrl: "",
      show: true,
    };
  },
  watch: {
    "form.type": function () {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 0);
    },
    history() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 0);
    },
    $route(n, o) {
      if (n.name == o.name && n.name == "video") {
        this.form.position = this.option["p" + this.$route.query.p][0].url;
        this.show = false;
        setTimeout(() => {
          this.show = true;
        }, 0);
      }
    },
  },
  methods: {
    warningClick(e) {
      console.log(e.currentTarget.dataset.time);
      this.form.type = 2;
      this.history = e.currentTarget.dataset.time;
    },
    setVideo() {},
  },
  mounted() {
    // console.log(this.$refs.video);
    // this.$refs.video.play();
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
