<template>
  <div class="projects">
    <!-- <div class="zhu"></div> -->
    <el-form
      :inline="true"
      class="demo-form-inline global-form project-form global-form-inline project-form"
    >
      <el-form-item label="场区名称">
        <el-select
          v-model="projectid"
          placeholder=" "
          @change="projectChange"
          style="width: 170px"
        >
          <el-option
            v-for="(item, index) in projects"
            :key="index"
            :label="item.text"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import basil from "@/util/basil";

export default {
  name: "Page",
  data() {
    return {
      projects: [],
      projectid: basil.get("projectid"),
    };
  },
  components: {},
  methods: {
    projectChange() {
      // console.log('projectid: ', this.projectid);
      basil.set("projectid", this.projectid);
      location.reload();
    },
  },
  mounted() {
    if (basil.get("user")) {
      this.projects = basil.get("user").projects;
    }
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
<style lang="less">
.project-form {
  .el-input__suffix {
    top: -3px !important;
  }
}
</style>
