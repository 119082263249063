import { createStore } from "vuex";

export default createStore({
  state: {
    total: {
      data1: {
        chulan: 100,
        peizhong: 100,
        chanzai: 100,
        bingsi: 100,
      },
      cunlan: {
        zaizhu: 100,
        baoyu: 100,
        yufei: 100,
        muzhu: 100,
        gongzhu: 100,
      },
      chanliang: [
        {
          name: "每月出栏（头）",
          itemStyle: {
            color: "#9C66FF",
          },
          type: "bar",
          data: [
            4740, 4732, 4800, 4803, 4799, 4830, 4888, 4882, 4760, 4768, 4732,
            4756, 4769,
          ],
        },
        {
          name: "每月配种（头）",
          itemStyle: {
            color: "#FEFA29",
          },
          type: "bar",
          data: [
            436, 435, 436, 432, 400, 398, 433, 430, 450, 429, 428, 433, 439,
          ],
        },
        {
          name: "每月产仔（头）",
          itemStyle: {
            color: "#98D4FF",
          },
          type: "bar",
          data: [
            4790, 4888, 4905, 4906, 4992, 4890, 4900, 4880, 4893, 4896, 4960,
            4971, 4932,
          ],
        },
        {
          name: "每月病死（头）",
          itemStyle: {
            color: "#89E56E",
          },
          type: "bar",
          data: [67, 60, 62, 58, 51, 62, 66, 70, 64, 56, 67, 58, 70],
        },
        {
          name: "每月产值（万元）",
          yAxisIndex: 1,
          type: "line",
          itemStyle: {
            color: "#12e1f9",
          },
          data: [
            1193.76, 1193.05, 1189.33, 1199.31, 1201.11, 1190.79, 1190.99,
            1186.32, 1199.3, 1190.65, 1192.33, 1191.54, 1195.69,
          ],
        },
      ],
      chanliangTime: [
        "2022.4",
        "2022.5",
        "2022.6",
        "2022.7",
        "2022.8",
        "2022.9",
        "2022.10",
        "2022.11",
        "2022.12",
        "2023.1",
        "2023.2",
        "2023.3",
        "2023.4",
      ],
    },
    she: [
      {
        l: 100,
        t: 200,
        id: "1000000122",
        name: "测定舍",
        arr: [
          {
            id: 1,
            name: "舍1单元1",
            nenghao: { jin: "301kw·h", zuo: "302kw·h", ping: "303kw·h" },
            shui: [
              {
                name: "上限",
                time: ["00:00", "00:01", "00:02", "00:03"],
                value: [1, 2, 3, 4],
              },
              {
                name: "实际用水量",
                time: ["00:00", "00:01", "00:02", "00:03"],
                value: [5, 6, 7, 8],
              },
              {
                name: "下限",
                time: ["00:00", "00:01", "00:02", "00:03"],
                value: [9, 10, 11, 12],
              },
            ],
          },
          {
            id: 2,
            name: "舍1单元2",
            nenghao: { jin: "304kw·h", zuo: "305kw·h", ping: "306kw·h" },
            shui: [
              {
                name: "上限",
                time: ["00:00", "00:01", "00:02", "00:03"],
                value: [11, 2, 3, 4],
              },
              {
                name: "实际用水量",
                time: ["00:00", "00:01", "00:02", "00:03"],
                value: [5, 6, 7, 8],
              },
              {
                name: "下限",
                time: ["00:00", "00:01", "00:02", "00:03"],
                value: [9, 10, 11, 12],
              },
            ],
          },
        ],
      },
      {
        l: 100,
        t: 600,
        id: "1000000123",
        name: "育成舍2",
        arr: [
          {
            id: 3,
            name: "舍2单元1",
            nenghao: { jin: "307kw·h", zuo: "308kw·h", ping: "309kw·h" },
            shui: [
              {
                name: "上限",
                time: ["00:00", "00:01", "00:02", "00:03"],
                value: [21, 2, 3, 4],
              },
              {
                name: "实际用水量",
                time: ["00:00", "00:01", "00:02", "00:03"],
                value: [5, 6, 7, 8],
              },
              {
                name: "下限",
                time: ["00:00", "00:01", "00:02", "00:03"],
                value: [9, 10, 11, 12],
              },
            ],
          },
          {
            id: 4,
            name: "舍2单元2",
            nenghao: { jin: "310kw·h", zuo: "311kw·h", ping: "312kw·h" },
            shui: [
              {
                name: "上限",
                time: ["00:00", "00:01", "00:02", "00:03"],
                value: [31, 2, 3, 4],
              },
              {
                name: "实际用水量",
                time: ["00:00", "00:01", "00:02", "00:03"],
                value: [5, 6, 7, 8],
              },
              {
                name: "下限",
                time: ["00:00", "00:01", "00:02", "00:03"],
                value: [9, 10, 11, 12],
              },
            ],
          },
        ],
      },
    ],
  },
  getters: {
    // ...
    getSheBySheId: (state) => (id) => {
      if (!id) {
        return {};
      } else {
        return state.she.find((item) => item.id === id);
      }
    },
    getDanByDanId: (state) => (id) => {
      if (!id) {
        return {};
      } else {
        let danItem;
        state.she.forEach((v, k) => {
          v.arr.forEach((vv, kk) => {
            if (vv.id == id) {
              danItem = vv;
            }
          });
        });
        return danItem;
      }
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
