export const xAxis = [
  // "8.10",
  // "8.11",
  // "8.12",
  // "8.13",
  // "8.14",
  "6.15",
  "6.16",
  "6.17",
  "6.18",
  "6.19",
  "6.20",
  "6.21",
  "6.22",
  "6.23",
  "6.24",
  "6.25",
  "6.26",
  "6.27",
  "6.28",
  "6.29",
  "6.30",
  "7.01",
  "7.02",
  "7.03",
  "7.04",
  "7.05",
  "7.06",

];
// 奶厅冲洗用水量/m³
export const data1 = [
  24,
  26,
  27,
  22,
  24,
  23,
  27,
  20,
  24,
  24,
  24,
  26,
  25,
  25,
  26,
  26,
  0,
  25,
  26,
  26,
  24,
  22,
];
// 液粪沟回冲用水量/m³
export const data2 = [
  315,
  298,
  330,
  320,
  275,
  300,
  343,
  293,
  305,
  321,
  311,
  299,
  326,
  321,
  316,
  306,
  0,
  345,
  279,
  323,
  309,
  322,
];
// 固液分离固体量/m³
export const data3 = [
  219, 207, 229, 222, 191, 209, 238, 204, 212, 223, 216, 208, 227, 223, 220,
  213, 0, 240, 194, 225, 215, 224,
];
// 固液分离液体量/m³
export const data4 = [
  878, 831, 920, 892, 767, 836, 956, 817, 850, 895, 867, 833, 909, 895, 881,
  853, 0, 962, 778, 900, 861, 898,
];
// 还田液体量
export const data5 = [
  2385, 2229, 2308, 2584, 2722, 2505, 2288, 2663, 2446, 2308, 2762, 2229, 2091,
  2742, 2446, 2308, 0, 2249, 2426, 2604, 2545, 2841,
];
// 沼气燃烧时间
export const data6 = [
  31, 29, 30, 34, 35, 33, 30, 35, 32, 30, 36, 29, 27, 36, 32, 30, 0, 29, 32, 34,
  33, 37,
];
// 总用电量
export const data7 = [
  385, 383, 385, 390, 392, 387, 383, 392, 383, 380, 381, 375, 372, 390, 384,
  381, 0, 379, 388, 390, 383, 385,
];
