export const DEVICE_TYPE = {
  默认: "default",
  环控: "EC",
  水表: "water",
  电表: "electric",
  料塔: "foodTower",
  洗消: "showerMonitor",
  门禁: "door",
  温度: "temperature",
  室外温度: "temperatureB",
  定速风机: "fanA",
  变速风机: "fanB",
  小窗: "window",
  卷帘: "curtainA",
  滑帘: "curtainB",
  制冷: "waterPumpA",// 湿帘
  消毒: "waterPumpB",
  加热: "heat",
  喷淋: "spray",
  湿度: "humidity",
};

export const DEVICE_TYPE_ICON = {
  // 温度
  temperature: "wendu",
  // 定速风机
  fanA: "feng",
  // 变速风机
  fanB: "fengBian",
  // 制冷
  waterPumpA: "shi",
  // 卷帘
  curtainA: "juan",
  // 小窗
  window: "chuang",
  // 湿度
  humidity: "shidu",
  // 加热
  heat: 're',
  // 滑帘
  curtainB: 'hua',
};
