<template>
  <div class="global-box">
    <el-form
      :inline="true"
      :model="form"
      class="global-form global-form-inline"
    >
      <el-form-item>
        <el-input v-model="form.employee" placeholder="员工姓名" />
      </el-form-item>

      <el-form-item>
        <el-input v-model="form.cardNo" placeholder="员工卡号" />
      </el-form-item>

      <!-- <el-form-item>
        <el-select v-model="form.region" placeholder="员工类型">
          <el-option label="访客" value="shanghai" />
          <el-option label="普通员工" value="beijing" />
        </el-select>
      </el-form-item> -->

      <!-- <el-form-item>
        <el-select v-model="form.region" placeholder="洗消结果">
          <el-option label="全部洗消结果" value="shanghai" />
          <el-option label="洗消合格" value="beijing" />
          <el-option label="洗消不合格" value="beijing" />
        </el-select>
      </el-form-item> -->

      <el-form-item>
        <el-select v-model="form.houseid" placeholder="全部洗消间">
          <el-option label="全部洗消间" value=""></el-option>
          <el-option
            v-for="(item, index) in pigstyList"
            :key="index"
            :label="item.text"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item>
        <el-select v-model="form.region" placeholder="全部洗消时间">
          <el-option label="Zone one" value="shanghai" />
          <el-option label="Zone two" value="beijing" />
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-date-picker
          v-model="form.date"
          type="daterange"
          range-separator="至"
          start-placeholder="打卡时间"
          end-placeholder="打卡时间"
        />
      </el-form-item>
      <el-form-item>
        <span class="btn-reset" @click="onReset" style="margin-right: 10px"
          >重置</span
        >
        <span class="btn-search" @click="onSubmit">查询</span>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      class="global-box-table"
      stripe
      border
      height="100"
      :loading="loading"
    >
      <el-table-column
        prop="order"
        label="序号"
        width="100px"
        :formatter="formatterNull"
      >
      </el-table-column>
      <el-table-column
        prop="employee"
        label="员工姓名"
        :formatter="formatterNull"
      >
      </el-table-column>
      <el-table-column
        prop="card_no"
        label="工号"
        :formatter="formatterNull"
      ></el-table-column>
      <el-table-column
        prop="org_name"
        label="所属部门"
        :formatter="formatterNull"
      ></el-table-column>
      <el-table-column prop="house_name" label="位置"></el-table-column>
      <el-table-column prop="duration" label="洗消总时长"></el-table-column>
      <el-table-column prop="qualified" label="洗消结果">
        <template #default="scoped">
          <span class="green" :class="{ red: !scoped.row.qualified }">{{
            scoped.row.qualified ? "洗消合格" : "洗消不合格"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="enter_time"
        label="打卡时间"
        :formatter="formatterTimeSt"
      ></el-table-column>
      <el-table-column label="操作" width="60px">
        <template #default="scope">
          <span @click.prevent="openDetail(scope.row)" class="detail"
            >详情</span
          >
        </template>
      </el-table-column>
    </el-table>

    <div v-if="totalSize > pageSize" class="padding20">
      <el-pagination
        v-model:currentPage="page"
        background
        layout="prev, pager, next"
        v-if="tableData.length"
        :total="totalSize"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div class="xi">
        <div class="title">基本信息</div>
        <el-row gutter="20">
          <el-col :span="8">
            <div class="label">员工姓名</div>
            <div class="content">{{ detailInfo.employee }}</div>
          </el-col>
          <!-- <el-col :span="8">
            <div class="label">员工类型</div>
            <div class="content">--</div>
          </el-col> -->
          <el-col :span="8">
            <div class="label">卡号</div>
            <div class="content">{{ detailInfo.card_no }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">所属部门</div>
            <div class="content">{{ detailInfo.org_name }}</div>
          </el-col>
        </el-row>
        <el-row gutter="20">
          <el-col :span="8">
            <div class="label">位置</div>
            <div class="content">{{ detailInfo.house_name }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">洗消结果</div>
            <div class="content">
              {{ detailInfo.qualified ? "洗消合格" : "洗消不合格" }}
            </div>
          </el-col>
        </el-row>
        <div class="title" style="margin-top: 40px">洗消流程</div>
        <div class="flow">
          <div class="left">
            <img :src="require('../imgs/zhong.png')" />
            <span class="line"></span>
            <img :src="require('../imgs/zhong.png')" />
            <span class="line"></span>
            <img :src="require('../imgs/zhong.png')" />
          </div>
          <div class="right">
            <div>
              打卡时间：{{
                dayjs(detailInfo.enter_time).format("YYYY-MM-DD HH:mm:ss")
              }}
            </div>
            <span></span>
            <div>洗消总时长：{{ detailInfo.duration }}</div>
            <span></span>
            <div>
              出洗消间时间：{{
                dayjs(detailInfo.leave_time).format("YYYY-MM-DD HH:mm:ss")
              }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatterNull, formatterTimeSt } from "@/util/formatter.js";
import basil from "@/util/basil";
import dayjs from "dayjs";
export default {
  name: "WashList",
  components: {},
  data() {
    return {
      dayjs,
      projectid: basil.get("projectid"),
      form: {
        houseid: this.$route?.query?.houseid || "",
        date: this.$route?.query?.houseid ? [dayjs(), dayjs()] : [null, null],
      },
      loading: true,
      tableData: [],
      pigstyList: [],
      page: 1,
      pageSize: 20,
      totalSize: 0,

      dialogVisible: false,
      dialogTitle: "",
      detailInfo: {},
    };
  },
  async mounted() {
    await this.getPigstyList();
    this.getData();
  },
  watch: {
    "$route.query.houseid": async function (v, o) {
      this.form.houseid = v || "";
      this.form.date = v ? [dayjs(), dayjs()] : [null, null];
      this.getData();
    },
  },
  methods: {
    getPigstyList() {
      this.$myRequest({
        url: `/api/tenant/asset/${this.projectid}/option/house`,
        data: {
          env: "洗消环境",
        },
      })
        .then((res) => {
          this.pigstyList = res.data.body;
        })
        .catch((res) => {
          console.log("getPigstyList err");
          console.log(res);
          this.$message(res.data.status_detail);
        });
    },
    formatterNull,
    formatterTimeSt,
    onReset() {
      this.form = {
        // houseid: this.$route.query.houseid || "",
        // date: this.$route.query?.houseid ? [dayjs(), dayjs()] : [null, null],
        houseid: "",
        date: [null, null],
      };
      this.getData();
    },
    async getData() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/shower/${this.projectid}/door/history`,
          data: {
            page: this.page,
            pageSize: this.pageSize,
            employee: this.form.employee,
            cardNo: this.form.cardNo,
            houseid: this.form.houseid,
            start_date: this.form?.date[0]
              ? dayjs(this.form.date[0]).format("YYYY-MM-DD")
              : undefined,
            end_date: this.form?.date[1]
              ? dayjs(this.form.date[1]).format("YYYY-MM-DD")
              : undefined,
          },
        });
        res = res.data;
        if (res.body.datas.length) {
          res.body.datas.forEach((i, index) => {
            i.order = this.pageSize * (this.page - 1) + index + 1;
          });
        }
        this.tableData = res.body.datas;
        this.page = res.body.page;
        this.pageSize = res.body.pageSize;
        this.totalSize = res.body.totalSize;
      } catch (err) {
        console.log(err);
      }
    },
    handleCurrentChange() {
      this.getData();
    },
    onSubmit() {
      this.getData();
    },
    openDetail(row) {
      this.dialogVisible = true;
      this.dialogTitle = `${row.employee}洗消详情`;
      this.detailInfo = row;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="less" src="./style.less"></style>
