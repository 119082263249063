<template>
  <div class="global-box">
    <el-table :data="tableData" stripe border>
      <el-table-column prop="a1" label="位置"> </el-table-column>
      <el-table-column prop="a2" label="设备类型"> </el-table-column>
      <el-table-column prop="a3" label="设备号"></el-table-column>
      <el-table-column prop="a4" label="报警类型"></el-table-column>
      <el-table-column prop="a5" label="数据"></el-table-column>
      <el-table-column
        prop="a6"
        label="产生时间"
        :formatter="formatterTime"
      ></el-table-column>
      <el-table-column
        prop="a7"
        label="结束时间"
        :formatter="formatterTime"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Page",
  components: {},
  data() {
    return {
      tableData: [
        {
          a1: "公猪舍",
          a2: "变速风机",
          a3: "A1",
          a4: "设备报警",
          a5: "电流异常",
          a6: "2021-10-17 15:48",
          a7: "2021-10-17 15:50",
        },
        {
          a1: "母猪舍1单元",
          a2: "水泵",
          a3: "X1",
          a4: "设备报警",
          a5: "电流异常",
          a6: "2021-10-20 10:15",
          a7: "2021-10-20 18;20",
        },
        {
          a1: "育肥舍3单元",
          a2: "巡检机器人",
          a3: "XJ1",
          a4: "设备报警",
          a5: "设备断线",
          a6: "2021-11-01 02:20",
          a7: "2021-11-01 04:20",
        },
      ],
      page: 1,
      pageSize: 10,
    };
  },
  methods: {
    formatterTime(row, column) {
      // console.log("formatterType");
      // console.log(row);
      // console.log(column);
      return moment(row.time).format("YYYY-MM-DD HH:mm:ss");
    },
    getList() {
      this.$myRequest({
        url: "/api/alarm/list",
        data: {
          page: this.page,
          pageSize: this.pageSize,
        },
      })
        .then((res) => {
          console.log(" res");
          console.log(res);
          this.tableData = res.data.body.datas;
          //
        })
        .catch((res) => {
          console.log(" err");
          console.log(res);
          this.$message(res.data.status_detail);
        });
    },
  },
  mounted() {},
};
</script>
<style scoped lang="less" src="./index.less">
</style>
