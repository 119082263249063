<template>
  <div class="quxian padding10">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'MeadowIndex' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item :to="{ name: 'MeadowSmartFeedIndex' }"
        >智能饲喂站</el-breadcrumb-item
      >
      <el-breadcrumb-item>曲线分配</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs @tab-click="handleClick">
      <el-tab-pane label="曲线分配">
        <el-row :gutter="10">
          <el-col :span="15">
            <el-card>
              <template #header>饲喂曲线列表</template>
              <el-table class="table1" :data="tableData" border>
                <el-table-column prop="t1" label="曲线"> </el-table-column>
                <el-table-column prop="t2" label="饲喂曲线名称">
                </el-table-column>
                <el-table-column prop="t3" label="饲料1兆"> </el-table-column>
                <el-table-column prop="t4" label="饲料2兆"> </el-table-column>
                <el-table-column prop="t5" label="料槽清理时">
                </el-table-column>
                <el-table-column prop="t6" label="动物"> </el-table-column>
              </el-table>
            </el-card>
          </el-col>
          <el-col :span="9">
            <el-card>
              <el-form :inline="true" :model="form" class="global-form global-form-inline">
                <el-form-item label="母猪生产状态">
                  <el-select v-model="form.s1">
                    <el-option label="怀孕" value="xxx"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </el-card>
            <el-card class="quxian-select-form">
              <template #header>饲喂曲线的选择</template>
              <el-form :model="form" class="global-form">
                <el-form-item label="">
                  <el-radio-group v-model="form.r1">
                    <el-radio label="个体设置"></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="">
                  <el-radio-group v-model="form.r2">
                    <el-radio label="固定的曲线"></el-radio>
                    <el-input></el-input>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="">
                  <el-radio-group v-model="form.r3">
                    <el-radio label="根据体重"></el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="">
                  <el-radio-group v-model="form.r4">
                    <el-radio label="根据胎次"></el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </el-card>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="曲线设置" disabled>
        <div class="hide">
          <el-card>
            <el-form :inline="true" class="global-form global-form-inline">
              <el-form-item>
                <el-input-number
                  v-model="form.num"
                  :min="1"
                  :max="10"
                  label=""
                ></el-input-number>
              </el-form-item>
              <el-form-item>
                <el-select v-model="form.xxx" placeholder="">
                  <el-option label="xxx" value="shanghai"></el-option>
                  <el-option label="xxx" value="beijing"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="饲料1">
                <el-input v-model="form.xxx"></el-input>
              </el-form-item>
              <el-form-item label="饲料2">
                <el-input v-model="form.xxx"></el-input>
              </el-form-item>
              <el-form-item label="槽料清理时间">
                <el-input v-model="form.xxx"></el-input>
              </el-form-item>
            </el-form>
          </el-card>
          <el-row :gutter="10">
            <el-col :span="7">
              <el-card>
                <el-table :data="tableData" border>
                  <el-table-column prop="box" label="xx"> </el-table-column>
                  <el-table-column prop="box" label="xx"> </el-table-column>
                  <el-table-column prop="box" label="xx"> </el-table-column>
                  <el-table-column prop="box" label="xx"> </el-table-column>
                  <el-table-column prop="box" label="xx"> </el-table-column>
                  <el-table-column prop="box" label="xx"> </el-table-column>
                  <el-table-column prop="box" label="xx"> </el-table-column>
                  <el-table-column prop="box" label="xx"> </el-table-column>
                  <el-table-column prop="box" label="xx"> </el-table-column>
                </el-table>
              </el-card>
            </el-col>
            <el-col :span="17">
              <el-card>
                <div id="xchart" class="quxian-chart"></div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane label="体况" disabled>xx</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { BarChart, LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
]);
export default {
  name: "Page",
  components: {},
  mounted() {
    // setTimeout(() => {
    //   this.setChart();
    // }, 1000);
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab.index);
      if (tab.index == "1") {
        // console.log(this.xChart);
        // this.xChart.resize();
        // this.setChart();
        if (!this.xChart) {
          setTimeout(() => {
            this.setChart();
          }, 0);
        }
      }
    },
    setChart() {
      let dom = document.getElementById("xchart");
      let chart = echarts.init(dom);
      let option;
      option = {
        grid: {
          left: 10,
          bottom: 15,
          right: 10,
          top: 10,
          // width: 470,
          // height: 90,
          containLabel: true,
        },
        tooltip: {
          confine: true,
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#31c4f6",
            },
          },
        },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            // magicType: { show: true, type: ["line", "bar"] },
            // restore: { show: true },
            // saveAsImage: { show: true },
          },
        },
        legend: {
          type: "scroll",
          pageIconSize: 8,
          pageIconColor: "#aaa",
          pageIconInactiveColor: "#2f4554",
          pageTextStyle: {
            color: "#ffffff",
            fontSize: 8,
          },
          data: [
            "猪场11111110000000000000011111",
            "猪场2",
            "猪场3",
            "猪场4",
            "猪场5",
          ],
          itemWidth: 8,
          itemHeight: 2,
          bottom: 0,
          textStyle: {
            fontSize: 7,
            color: "#FFFFFF",
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: ["1", "2", "3", "4", "5", "6"],
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              color: "#4D9EED",
              fontSize: 9,
            },
          },
        ],
        yAxis: [
          {
            scale: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#31c4f6",
              },
            },
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#C5DBF4",
              verticalAlign: "top",
              fontSize: 9,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#28478c",
                type: "dashed",
              },
            },
            axisLabel: {
              formatter: "{value}",
              color: "#C5DBF4",
              fontSize: 8,
            },
          },
        ],
        series: [
          {
            name: "xxx",
            type: "line",
            data: [
              10,
              4.9,
              7.0,
              23.2,
              25.6,
              76.7,
              135.6,
              162.2,
              32.6,
              20.0,
              6.4,
              3.3,
            ],
          },
        ],
      };
      chart.setOption(option);
      this.xChart = chart;
    },
  },

  data() {
    return {
      tableData: [
        {
          t1: "1",
          t2: "huaiyunmuzhu",
          t3: "1",
          t4: "1",
          t5: "0",
          t6: "537",
        },
        {
          t1: "2",
          t2: "jingchanghuaiyunmuzhu",
          t3: "1",
          t4: "1",
          t5: "0",
          t6: "0",
        },
        {
          t1: "3",
          t2: "houbei",
          t3: "1",
          t4: "1",
          t5: "0",
          t6: "0",
        },
      ],
      form: {
        resource: "",
        type: [],
        r1: "1",
        num: 1,
      },
    };
  },
  beforeUnmount() {
    if (this.xChart) {
      this.xChart.dispose();
      this.xChart = null;
    }
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
<style lang="less">
.quxian {
  .quxian-select-form {
    .el-input {
      width: 60px;
    }
  }

  .el-input-number {
    width: 80px;
  }
}
</style>