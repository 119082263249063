<template>
  <img
    @click="changeImg"
    :src="'/api/tenant/global/code/image?_=' + imgTime"
    :class="setClass ? setClass : 'global-img-code'"
  />
</template>

<script>
export default {
  name: "Page",
  props: ["setClass"],
  components: {},
  data() {
    return {
      imgTime: new Date().getTime(),
    };
  },
  methods: {
    changeImg() {
      this.imgTime = new Date().getTime();
    },
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
