import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import "amfe-flexible";
import "./util/rem.js";
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import 'element-plus/dist/index.css'
import request from './util/request';
createApp(App).use(store).use(router).use(ElementPlus, {
    locale: zhCn,
}).use(request).mount('#app')
