export const midata = {
  body:[
    {
      date: '2023-7-23',
      weight: 30,
      amount: 4.1,
      targetAmount: 4.8,
    },
    {
      date: '2023-7-24',
      weight: 31,
      amount: 4.5,
      targetAmount: 5
    },
    {
      date: '2023-7-25',
      weight: 31,
      amount: 4.5,
      targetAmount: 5.2
    },
    {
      date: '2023-7-26',
      weight: 31,
      amount: 4.5,
      targetAmount: 4.6
    },
    {
      date: '2023-7-27',
      weight: 31.2,
      amount: 4.2,
      targetAmount: 5
    }
  ]
}