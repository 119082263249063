<template>
  <div class="xiaohao padding10">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: 'MeadowIndex' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ name: 'MeadowLiquidIndex' }"
            >液态料</el-breadcrumb-item
          >
          <el-breadcrumb-item>配料消耗</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs>
          <el-tab-pane label="栏">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-card class="box-card">
                  <template #header> 显示消耗 </template>
                  <el-card class="box-card">
                    <el-form class="global-form">
                      <el-form-item label="">
                        <el-radio-group v-model="form.resource">
                          <el-radio label="所有"></el-radio>
                          <br />
                          <el-radio label="时间段"></el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item label="">
                        <el-date-picker
                          type="date"
                          placeholder="选择日期"
                          v-model="form.date1"
                        ></el-date-picker>
                      </el-form-item>
                      <el-form-item label="">
                        <el-date-picker
                          type="date"
                          placeholder="选择日期"
                          v-model="form.date2"
                        ></el-date-picker>
                      </el-form-item>
                    </el-form>
                  </el-card>
                  <el-card class="box-card">
                    <el-form class="global-form">
                      <el-form-item label="">
                        <el-radio-group v-model="form.resource">
                          <el-radio label="所有"></el-radio><br>
                          <el-radio label="栏号码"></el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item label="">
                        <el-input v-model="form.name"></el-input>
                      </el-form-item>
                    </el-form>
                  </el-card>
                  <el-card class="box-card">
                    <el-form class="global-form">
                      <el-checkbox-group v-model="form.type">
                        <el-checkbox label="数量" name="type"></el-checkbox>
                        <el-checkbox label="料能" name="type"></el-checkbox>
                        <el-checkbox label="价格" name="type"></el-checkbox>
                        <el-checkbox
                          label="干物质"
                          name="type"
                        ></el-checkbox>
                      </el-checkbox-group>
                    </el-form>
                  </el-card>
                  <el-card class="card-day box-card">
                    <el-form class="global-form">
                      <el-form-item label="最长储存天数">
                        <el-input v-model="form.name"></el-input>
                      </el-form-item>
                    </el-form>
                  </el-card>
                </el-card>
              </el-col>
              <el-col :span="18">
                <el-table
                  class="table1"
                  sum-text="合计:"
                  :data="tableData"
                  border
                  style="width: 100%"
                  show-summary
                >
                  <el-table-column prop="box" label="栏"> </el-table-column>
                  <el-table-column prop="komponente" label="配料">
                  </el-table-column>
                  <el-table-column prop="menge" label="数量[kg]">
                  </el-table-column>
                  <el-table-column prop="energie" label="料能">
                  </el-table-column>
                  <el-table-column prop="preis" label="价格">
                  </el-table-column>
                  <el-table-column prop="ts" label="干物质"> </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="配料" disabled>xx</el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  methods: {},
  data() {
    return {
      tableData: [
        {
          box: "101",
          komponente: "fresh water",
          menge: "7.54",
          energie: "0",
          preis: "0",
          ts: "0",
        },
        {
          box: "101",
          komponente: "fresh water",
          menge: "1.96",
          energie: "0",
          preis: "0",
          ts: "0",
        },
      ],
      form: {
        resource: "",
        type: [],
      },
    };
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
<style lang="less" >
.xiaohao {
  .el-date-editor {
    // width: 100px !important;
  }
  .card-day{
    .el-form-item__label{
      width:100px;
    }
  }
}
</style>
