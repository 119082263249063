<template>
  <div class="global-box wrapper">
    <div class="top">
      <div v-for="(item, index) in data" class="card" :key="index" @click="() => clickHandle(index)">
        <div class="head">
          <div class="status-text" >
            {{ item.runStatusText }}
          </div>
          <div class="status">
            <span class="dot" :class="{ red: index == 2, orange: index == 3 }"></span>
            <span class="text" :class="{ red:  index == 2, orange: index == 3 }">{{ item.statusText }}</span>
          </div>
        </div>
        <div class="order">{{ item.order }}</div>
        <div class="room">饲喂厨房</div>
        <div class="gangText">{{ item.gangText }}</div>
      </div>
    </div>

    <div class="tip">饲喂厨房信息</div>

    <el-table :data="tableData" class="global-box-table" stripe border height="100">
      <el-table-column prop="code" label="厨房编号">
      </el-table-column>
      <el-table-column prop="pipeline" label="环线">
      </el-table-column>
      <el-table-column prop="fa" label="落料阀" :formatter="formatterNull">
      </el-table-column>
      <el-table-column prop="animalCount" label="猪只数量" :formatter="formatterNull"></el-table-column>
      <el-table-column prop="yestody1" label="昨日饲喂量（kg）" :formatter="formatterNull"></el-table-column>
      <el-table-column prop="yestody2" label="昨日喂水（kg）" :formatter="formatterNull"></el-table-column>
      <el-table-column label="操作" width="200px">
        <template #default="scoped">
          <span @click="() => openDetai(scoped.row)" class="detail">饲喂详情</span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="dialogVisible" :title="dialogTitle" :before-close="handleClose" :close-on-click-modal="false"
      :append-to-body="true">
      <el-form :inline="true" :model="form" class="global-form global-form-inline">
        <el-form-item>
          <el-date-picker v-model="form.date" type="daterange" range-separator="至" />
        </el-form-item>

        <el-form-item>
          <span class="btn-reset" @click="onReset" style="margin-right: 10px">重置</span>
          <span class="btn-search" @click="onSubmit">查询</span>
        </el-form-item>
      </el-form>

      <div class="tabs">
        <div @click="() => tabClick(0)" class="tab-item" :class="{ active: tabIndex == 0 }">
          <span>饲喂图表</span>
        </div>
        <div @click="() => tabClick(1)" class="tab-item" :class="{ active: tabIndex == 1 }">
          <span>饲喂数据</span>
        </div>
        <!-- <div @click="() => tabClick(2)" class="tab-item" :class="{ active: tabIndex == 2 }">
          <span>肉料比</span>
        </div> -->
      </div>

      <div ref="tabContent" class="tab-content">
        <v-chart ref="chart" v-if="tabIndex == 0" class="chart" :option="createOption()" autoresize />

        <el-table v-if="tabIndex == 1" :data="tab1TableData" class="dialog-box-table" stripe border>
          <el-table-column prop="order" label="序号" width="100px" :formatter="formatterNull">
          </el-table-column>
          <el-table-column prop="date" label="日期" :formatter="formatterDateSt"></el-table-column>
          <el-table-column prop="amount" label="采食量（kg）" :formatter="formatterNull"></el-table-column>
          <!-- <el-table-column prop="weight" label="重量（kg）" :formatter="formatterNull"></el-table-column> -->
        </el-table>

        <el-table v-if="tabIndex == 2" :data="tab2TableData" class="dialog-box-table" stripe border>
          <el-table-column prop="date" label="饲喂时间段" width="220px" :formatter="formatterNull">
          </el-table-column>
          <el-table-column prop="amount" label="总采食量 (kg)" :formatter="formatterNull"></el-table-column>
          <el-table-column prop="addWeight" label="体重增长 (kg)" :formatter="formatterNumber"></el-table-column>
          <el-table-column prop="rate" label="肉料比" :formatter="formatterRate"></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  formatterNull,
  formatterNumber,
  formatterRate,
  formatterDateSt,
} from "@/util/formatter.js";
import basil from "@/util/basil";
import dayjs from "dayjs";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { LineChart } from "echarts/charts";
use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
]);

import { list, list_detail } from "./const"

const colors = ["#6EBA50", "#748AD2", "#FF9B78"];
export default {
  components: { VChart },
  data() {
    return {
      projectid: basil.get("projectid"),
      cardIndex: 0,
      data: [
        {
          order: 1,
          statusText: "运行中",
          runStatusText: "在线",
          gangText: "双缸",
        },
        {
          code: 2,
          order: 2,
          statusText: "运行中",
          runStatusText: "在线",
          gangText: "单缸",
        },
        {
          code: 3,
          order: 3,
          statusText: "停止中",
          runStatusText: "在线",
          gangText: "双缸",
        },
        {
          code: 4,
          order: 4,
          statusText: "暂停中",
          runStatusText: "在线",
          gangText: "单缸",
        },
        {
          code: 5,
          order: 5,
          statusText: "运行中",
          runStatusText: "在线",
          gangText: "单缸",
        },
        // {
        //   code: 5,
        //   order: 5,
        //   statusText: "--",
        //   runStatusText: "离线",
        //   gangText: "单缸",
        // },
      ],
      dialogVisible: false,
      dialogTitle: "饲喂详情",
      form: {
        date: [dayjs().subtract(1, "week"), dayjs()],
      },
      tabIndex: 0,
      chartWidth: 400,
      activeRow: {},
      dateArr: [],
      weightArr: [],
      amountArr: [],
      tableData: list,
      tab1TableData: [],

      tab2TableData: [],
    };
  },
  mounted() { },
  methods: {
    formatterNull,
    formatterNumber,
    formatterRate,
    formatterDateSt,
    clickHandle() {
      this.$router.push({ name: "LiquidFeedDetail" });
    },
    onReset() {
      this.form = {
        date: [dayjs().subtract(1, "week"), dayjs()],
      };
    },
    onSubmit() {
      this.getAnimaiDetail();
    },
    openDetai(row) {
      this.activeRow = row;
      this.getAnimaiDetail();
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    tabClick(index) {
      this.tabIndex = index;
    },
    createOption() {
      return {
        color: colors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: true,
          },
        },
        legend: {
          tooltip: {
            show: true,
          },
          textStyle: {
            color: "#B4C0D3",
          },
        },
        grid: {
          top: 50,
          left: 30,
          right: 30,
          bottom: 20,
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#44B1DD",
              },
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return params.value;
                },
              },
            },
            data: this.dateArr,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "采食/g",
            // min: 1,
            // max: 10,
            axisLine: {
              onZero: false,
              show: true,
              lineStyle: {
                color: "#44B1DD",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#3C5389",
              },
            },
            nameTextStyle: {
              color: "#44B1DD",
            },
          },
          {
            type: "value",
            name: "重量/kg",
            // min: 25,
            // max: 70,
            axisLine: {
              onZero: false,
              show: true,
              lineStyle: {
                color: "#44B1DD",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#3C5389",
              },
            },
            nameTextStyle: {
              color: "#44B1DD",
            },
          },
        ],
        series: [
          {
            name: "采食量",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 8,
            emphasis: {
              focus: "series",
            },
            data: this.amountArr,
            yAxisIndex: 0,
          },
          // {
          //   name: "分配量",
          //   type: "line",
          //   smooth: true,
          //   symbol: "circle",
          //   symbolSize: 8,
          //   data: data2,
          //   yAxisIndex: 0,
          // },
          // {
          //   name: "重量",
          //   symbol: "circle",
          //   type: "line",
          //   smooth: true,
          //   symbolSize: 8,
          //   data: this.weightArr,
          //   yAxisIndex: 1,
          // },
        ],
      };
    },
    forMatName(name) {
      const index = name.lastIndexOf("-");
      return name.substring(index + 1);
    },
    getStationList() {
      this.$myRequest({
        url: `/api/tenant/feeding/${this.projectid}/station/list`,
        data: {
          type: "cedingzhan",
        },
      })
        .then((res) => {
          res = res.data;
          res.body.forEach((i) => {
            i.forMatName = this.forMatName(i.name);
          });
          this.data = res.body;
          res.body.sort((a, b) => a.forMatName - b.forMatName);
          this.getTableData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getTableData() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/feeding/${this.projectid}/animal/list`,
          data: {
            projectid: this.projectid,
            uuid: this.data[this.cardIndex].uuid,
          },
        });
        res = res.data;
        res.body.forEach((i, index) => (i.order = index + 1));
        this.tableData = res.body;
      } catch (err) {
        console.log(err);
      }
    },
    async getAnimaiDetail() {
      console.log(this.activeRow, '[[[]]]');

      this.dialogTitle = "厨房编号" + this.activeRow.code + "-饲喂详情"

      console.log(list_detail[this.activeRow.index]);
      this.tab1TableData = list_detail[this.activeRow.index]

      this.tab1TableData.forEach((i, index) => (i.order = index + 1));

      this.dateArr = this.tab1TableData.map((i) => i.date);
      this.weightArr = this.tab1TableData.map((i) => i.weight);
      this.amountArr = this.tab1TableData.map((i) => i.amount);


      // try {
      //   let res = await this.$myRequest({
      //     url: `/api/tenant/feeding/2/animal/history`,
      //     data: {
      //       uuid: "160011957780002783",
      //       flag: "cedingzhan_1",
      //       start_date: dayjs(this.form.date[0]).format("YYYY-MM-DD"),
      //       end_date: dayjs(this.form.date[1]).format("YYYY-MM-DD"),
      //     },
      //   });
      //   res = res.data;
      //   const len = res.body.length;
      //   res.body.forEach((i, index) => (i.order = index + 1));
      //   this.dateArr = res.body.map((i) => dayjs(i.date).format("YYYY-MM-DD"));
      //   this.weightArr = res.body.map((i) => i.weight);
      //   this.amountArr = res.body.map((i) => i.amount);
      //   this.tab1TableData = res.body;

      //   if (len == 1) {
      //     this.tab2TableData = [
      //       {
      //         date: dayjs(res.body[0].date).format("YYYY.MM.DD"),
      //         amount: res.body[0].amount,
      //         addWeight: 0,
      //         rate: "",
      //       },
      //     ];
      //   } else if (len > 2) {
      //     let amount = 0;
      //     res.body.forEach((i) => {
      //       amount += i.amount;
      //     });
      //     let addWeight = res.body[len - 1].weight - res.body[0].weight;
      //     this.tab2TableData = [
      //       {
      //         date:
      //           dayjs(res.body[0].date).format("YYYY.MM.DD") +
      //           "-" +
      //           dayjs(res.body[len - 1].date).format("YYYY.MM.DD"),
      //         amount,
      //         addWeight,
      //         rate: amount / (addWeight * 1000),
      //       },
      //     ];
      //     console.log(this.tab2TableData);
      //   }
      // } catch (err) {
      //   console.log(err);
      // }
    },
  },
};
</script>

<style scoped lang="less" src="./index.less"></style>
