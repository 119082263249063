<template>
  <div class="lanwei padding10">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'MeadowIndex' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item :to="{ name: 'MeadowLiquidIndex' }"
        >液态料</el-breadcrumb-item
      >
      <el-breadcrumb-item>栏位</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-form :inline="true" :model="form" class="demo-form-inline global-form global-form-inline">
        <el-form-item label="">
          <el-select v-model="form.s1" placeholder="">
            <el-option label="所有" value="xx"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="form.s2" placeholder="">
            <el-option label="----" value="xx"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-table class="table1" :data="tableData" border>
        <el-table-column prop="t1" label="*"> </el-table-column>
        <el-table-column prop="t2" label="栏"> </el-table-column>
        <el-table-column prop="t3" label="类型"> </el-table-column>
        <el-table-column prop="t4" label="管"> </el-table-column>
        <el-table-column prop="t5" label="头"> </el-table-column>
        <el-table-column prop="t6" label="栏"> </el-table-column>
        <el-table-column prop="t7" label="重量"> </el-table-column>
        <el-table-column prop="t8" label="曲线名"> </el-table-column>
        <el-table-column prop="t9" label="线天"> </el-table-column>
        <el-table-column prop="t10" label="算法"> </el-table-column>
        <el-table-column prop="t11" label="算式"> </el-table-column>
        <el-table-column prop="t12" label="群"> </el-table-column>
        <el-table-column prop="t13" label="算量"> </el-table-column>
        <el-table-column prop="t14" label="比例"> </el-table-column>
        <el-table-column prop="t15" label="修正"> </el-table-column>
        <el-table-column prop="t16" label="目标"> </el-table-column>
        <el-table-column prop="t17" label="最大"> </el-table-column>
        <el-table-column prop="t18" label="算式"> </el-table-column>
      </el-table>
    </el-card>
    <el-card class="box-card">
      <el-button type="primary" icon="el-icon-delete"></el-button>
    </el-card>
   
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  methods: {},
  data() {
    return {
      tableData: [
       {t1:'*',t2:'601',t3:'育肥',t4:'',t5:'0',t6:'',t7:'62.9',t8:'料1',t9:'29',t10:'天数',t11:'份额',t12:'6',t13:'0',t14:'100',t15:'0',t16:'100',t17:'150',t18:'计算'},
       {t1:'*',t2:'602',t3:'育肥',t4:'',t5:'0',t6:'',t7:'53.3',t8:'料1',t9:'20',t10:'天数',t11:'份额',t12:'6',t13:'0',t14:'100',t15:'0',t16:'100',t17:'150',t18:'计算'},
      ],
      form: {
        resource: "",
        type: [],
      },
    };
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
