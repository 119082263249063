<template>
  <div class="meiri padding10">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: 'MeadowIndex' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ name: 'MeadowLiquidIndex' }"
            >液态料</el-breadcrumb-item
          >
          <el-breadcrumb-item>每日流程</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <el-form :inline="true" :model="form" class="demo-form-inline global-form global-form-inline">
        <el-form-item label="喂食开始">
          <el-col :span="11">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="form.date1"
            ></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>
    </el-card>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs>
          <el-tab-pane label="基本">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-table class="table1" :data="tableData" border>
                  <el-table-column prop="t1" label="号"> </el-table-column>
                  <el-table-column prop="t2" label="活"> </el-table-column>
                  <el-table-column prop="t3" label="开始"> </el-table-column>
                  <el-table-column prop="t4" label="项目"> </el-table-column>
                  <el-table-column prop="t5" label="多任务项">
                  </el-table-column>
                  <el-table-column prop="t6" label="群"> </el-table-column>
                  <el-table-column prop="t7" label="日量%"> </el-table-column>
                  <el-table-column prop="t8" label="最大"> </el-table-column>
                  <el-table-column prop="t9" label="态"> </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="清洁" disabled>xx</el-tab-pane>
          <el-tab-pane label="宏" disabled>xx</el-tab-pane>
          <el-tab-pane label="扩展" disabled>xx</el-tab-pane>
          <el-tab-pane label="总揽" disabled>xx</el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  methods: {},
  data() {
    return {
      tableData: [
        {
          t1: "1",
          t2: "✔",
          t3: "8:00",
          t4: "备料",
          t5: "------",
          t6: "10",
          t7: "50",
          t8: "0",
          t9: "完",
        },
        {
          t1: "2",
          t2: "✔",
          t3: "8:05",
          t4: "分料",
          t5: "------",
          t6: "10",
          t7: "50",
          t8: "0",
          t9: "完",
        },
      ],
      form: {
        resource: "",
        type: [],
      },
    };
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
