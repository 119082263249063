<template>
  <div ref="login" class="login">
    <div
      :style="`transform:translate(-50%, 0) scale(${bgScale}) `"
      ref="bg"
      class="login-bg"
    ></div>
    <div ref="con" class="con">
      <div class="form-box">
        <div class="tab-box">
          <el-tabs v-model="active">
            <el-tab-pane label="密码登录" name="1">
              <el-form
                v-if="active == '1'"
                ref="form1"
                :model="form1"
                :rules="rules1"
              >
                <el-form-item label="用户名" prop="username">
                  <el-input
                    v-model="form1.username"
                    placeholder="请输入用户名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                  <el-input
                    v-model="form1.password"
                    type="password"
                    placeholder="请输入密码"
                  ></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="image_code">
                  <el-input
                    @focus="imageCodeFocus1"
                    v-model="form1.image_code"
                    placeholder="请输入验证码"
                    class="code-input"
                  ></el-input>
                  <imgCode setClass="global-img-code-login"></imgCode>
                </el-form-item>
              </el-form>
              <div class="form-forget">
                <router-link :to="{ name: 'find' }">忘记密码？</router-link>
              </div>
              <div class="btn" @click="submit">登 录</div>
            </el-tab-pane>
            <el-tab-pane label="验证码登录" name="2">
              <el-form
                v-if="active == '2'"
                ref="form2"
                :model="form2"
                :rules="rules2"
              >
                <el-form-item label="手机号" prop="phone">
                  <el-input
                    v-model="form2.phone"
                    placeholder="请输入手机号"
                  ></el-input>
                </el-form-item>

                <el-form-item label="验证码" prop="image_code">
                  <el-input
                    @focus="imageCodeFocus2"
                    v-model="form2.image_code"
                    placeholder="请输入验证码"
                    class="code-input"
                  ></el-input>
                  <imgCode setClass="global-img-code-login"></imgCode>
                </el-form-item>
                <el-form-item label="短信验证码" prop="phone_code">
                  <el-input
                    @focus="phoneCodeFocus"
                    v-model="form2.phone_code"
                    placeholder="请输入短信验证码"
                    class="code-input"
                  ></el-input>
                  <div class="send-btn" type="primary" @click="sendCode">
                    发送
                  </div>
                </el-form-item>
              </el-form>
              <div class="form-forget">
                <router-link :to="{ name: 'find' }">忘记密码？</router-link>
              </div>
              <div class="btn" @click="submit2">登 录</div>
            </el-tab-pane>
          </el-tabs>
          <div class="footer">京ICP备2020047937号</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cookie from "@/util/cookie";
import basil from "@/util/basil";
import imgCode from "../../components/img-code";
export default {
  name: "Page",
  components: { imgCode },
  methods: {
    scaleCon() {
      let clientHeight = this.$refs.login.clientHeight;
      let clientWidth = this.$refs.login.clientWidth;
      let bgClientWidth = this.$refs.bg.clientWidth;
      let bgClientHeight = this.$refs.bg.clientHeight;
      console.log(clientWidth, clientHeight, bgClientWidth, bgClientHeight);
      let conHeight = this.$refs.con.clientHeight;
      if (clientHeight < conHeight) {
        this.conScaleHeight = clientHeight / conHeight;
      } else {
        this.conScaleHeight = 1;
      }

      if (clientWidth / clientHeight > bgClientWidth / bgClientHeight) {
        this.bgScale = clientWidth / bgClientWidth;
      } else {
        this.bgScale = clientHeight / bgClientHeight;
      }
    },
    imageCodeFocus2() {
      this.image_code_err2 = false;
    },
    imageCodeFocus1() {
      this.image_code_err1 = false;
    },
    phoneCodeFocus() {
      this.phone_code_err = false;
    },
    submit() {
      this.$refs.form1.validate((valid) => {
        if (valid) {
          this.$myRequest({
            method: "post",
            url: "/api/tenant/user/login_by_username",
            data: this.form1,
            login: false,
          })
            .then((res) => {
              // console.log("login res");
              // console.log(res);
              
              // cookie.set("token", res.headers.authorization);
              // cookie.set("user", res.data.body);
              // cookie.set("projectid", res.data.body.projects[0].value);


              basil.set("token", res.headers.authorization);
              basil.set("user", res.data.body);
              basil.set("projectid", res.data.body.projects[0].value);

              if (res.data.body.roles[0]?.modules[0]?.functions[0]?.page) {
                this.$router.push({
                  name: res.data.body.roles[0]?.modules[0]?.functions[0]?.page,
                  params: { id: res.data.body.projects[0].value || 1 },
                });
              } else {
                this.$router.push({
                  name: res.data.body.roles[0]?.modules[0]?.page,
                  params: { id: res.data.body.projects[0].value || 1 },
                });
              }
              // if (res.data.body.projects.some((p) => p.value == 25)) {
              //   this.$router.push({
              //     name: "projectEnv",
              //     params: { id: res.data.body.projects[0].value || 1 },
              //   });
              // } else {
              //   this.$router.push({
              //     name: "projectIndex",
              //     params: { id: res.data.body.projects[0].value || 1 },
              //   });
              // }
            })
            .catch((res) => {
              console.log("login err");
              console.log(res);
              if (res.data.status == -113) {
                this.image_code_err1 = true;
                this.$refs.form1.validateField("image_code");
              } else {
                this.$message(res.data.status_detail);
              }
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    submit2() {
      this.$refs.form2.validate((valid) => {
        if (valid) {
          this.$myRequest({
            method: "post",
            url: "/api/tenant/user/login_by_phone",
            data: this.form2,
            login: false,
          })
            .then((res) => {
              // console.log("login res");
              // console.log(res);

              // cookie.set("token", res.headers.authorization);
              // cookie.set("user", res.data.body);
              // cookie.set("projectid", res.data.body.projects[0].value);


              basil.set("token", res.headers.authorization);
              basil.set("user", res.data.body);
              basil.set("projectid", res.data.body.projects[0].value);

              if (res.data.body.roles[0]?.modules[0]?.functions[0]?.page) {
                this.$router.push({
                  name: res.data.body.roles[0]?.modules[0]?.functions[0]?.page,
                  params: { id: res.data.body.projects[0].value || 1 },
                });
              } else {
                this.$router.push({
                  name: res.data.body.roles[0]?.modules[0]?.page,
                  params: { id: res.data.body.projects[0].value || 1 },
                });
              }
              // if (
              //   // 中育
              //   res.data.body.projects.some((p) => p.value == 25)
              // ) {
              //   this.$router.push({
              //     name: "projectEnv",
              //     params: { id: res.data.body.projects[0].value || 1 },
              //   });
              // } else {
              //   // 长荣
              //   this.$router.push({
              //     name: "projectIndex",
              //     params: { id: res.data.body.projects[0].value || 1 },
              //   });
              // }
            })
            .catch((res) => {
              console.log("login err");
              console.log(res);
              if (res.data.status == -113 || res.data.status == -112) {
                this.phone_code_err = true;
                this.$refs.form2.validateField("phone_code");
              } else {
                this.$message(res.data.status_detail);
              }
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    sendCode() {
      console.log("sendCode");
      // this.image_code_err = true;
      this.$refs.form2.validateField("phone", (phoneErrMsg) => {
        this.$refs.form2.validateField("image_code", (imageErrMsg) => {
          console.log("validateField");
          console.log(phoneErrMsg);
          console.log(imageErrMsg);
          if (phoneErrMsg == "" && imageErrMsg == "") {
            this.$myRequest({
              url: "/api/tenant/global/code/phone/" + this.form2.phone,
              data: {
                image_code: this.form2.image_code,
                phone: this.form2.phone,
              },
            })
              .then((res) => {
                console.log("phone res");
                console.log(res);
                this.$message("发送成功");
                //
              })
              .catch((res) => {
                console.log("phone err");
                console.log(res);
                if (res.data.status == -113) {
                  this.image_code_err2 = true;
                  this.$refs.form2.validateField("image_code");
                } else {
                  this.$message(res.data.status_detail);
                }
              });
          }
        });
      });
    },
  },
  data() {
    const checkPhone = (rule, value, callback) => {
      var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (myreg.test(value)) {
        callback();
      } else {
        callback(new Error("请输入正确的手机号"));
      }
    };
    const phoneCodeErr = (rule, value, callback) => {
      if (this.phone_code_err) {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };
    const imgErr1 = (rule, value, callback) => {
      if (this.image_code_err1) {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };
    const imgErr2 = (rule, value, callback) => {
      if (this.image_code_err2) {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };
    return {
      bgScale: 1,
      conScaleHeight: 1,
      image_code_err1: false,
      image_code_err2: false,
      phone_code_err: false,
      active: "1",
      form1: {
        type: 0,
        username: "",
        password: "",
        image_code: "",
      },
      form2: {
        type: 1,
        phone: "",
        phone_code: "",
        image_code: "",
      },
      rules1: {
        username: [{ required: true, message: "必填" }],
        password: [{ required: true, message: "必填" }],
        image_code: [
          {
            required: true,
            message: "必填",
          },
          { validator: imgErr1 },
        ],
      },
      rules2: {
        phone_code: [
          {
            required: true,
            message: "必填",
          },
          { validator: phoneCodeErr },
        ],
        image_code: [
          {
            required: true,
            message: "必填",
          },
          { validator: imgErr2 },
        ],
        phone: [
          {
            required: true,
            message: "必填",
          },
          { validator: checkPhone },
        ],
      },
    };
  },
  mounted() {
    this.scaleCon();
    window.addEventListener("resize", this.scaleCon);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.scaleCon);
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
