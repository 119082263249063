<template>
  <my-home>
    <template #con>
      <el-card class="find">
        <template #header>
          <div class="card-header">
            <span>找回密码</span>
          </div>
        </template>
        <el-form
          ref="form"
          :model="form"
          class="global-form"
          label-width="130rem"
          :rules="rules"
        >
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="image_code">
            <el-input
              class="input-short"
              v-model="form.image_code"
              @focus="imageCodeFocus"
            ></el-input>
            <imgCode></imgCode>
          </el-form-item>
          <el-form-item label="短信验证码" prop="phone_code">
            <el-input
              v-model="form.phone_code"
              @focus="phoneCodeFocus"
              class="input-short"
            ></el-input>
            <el-button class="global-sendCode" type="primary" @click="sendCode"
              >发送</el-button
            >
          </el-form-item>
          <el-form-item label="新密码" prop="new_password">
            <el-input type="password" v-model="form.new_password"></el-input>
          </el-form-item>
          <el-form-item label="重复新密码" prop="r_password">
            <el-input type="password" v-model="form.r_password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="back">取消</el-button>
            <el-button type="primary" @click="submit">确定</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </template>
  </my-home>
</template>

<script>
import imgCode from "../../components/img-code";
import { checkPhone } from "@/util/rules";
import Home from "@/components/home-no/index.vue";
export default {
  name: "Home",
  components: {
    "my-home": Home,
    imgCode,
  },
  methods: {
    back() {
      this.$router.push({ name: "Login" });
    },
    phoneCodeFocus() {
      this.phone_code_err = false;
    },
    imageCodeFocus() {
      this.image_code_err = false;
    },
    sendCode() {
      console.log("sendCode");
      // console.log(this.$refs.form.validateField);
      // this.image_code_err = true;
      this.$refs.form.validateField("phone", (phoneErrMsg) => {
        this.$refs.form.validateField("image_code", (imageErrMsg) => {
          console.log("validateField");
          console.log(phoneErrMsg);
          console.log(imageErrMsg);
          if (phoneErrMsg == "" && imageErrMsg == "") {
            this.$myRequest({
              url: "/api/tenant/global/code/phone/" + this.form.phone,
              data: {
                image_code: this.form.image_code,
                phone: this.form.phone,
              },
            })
              .then((res) => {
                console.log("phone res");
                console.log(res);
                this.$message("发送成功");
                //
              })
              .catch((res) => {
                console.log("phone err");
                console.log(res);
                if (res.data.status == -113) {
                  this.image_code_err = true;
                  this.$refs.form.validateField("image_code");
                } else {
                  this.$message(res.data.status_detail);
                }
              });
          }
        });
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$myRequest({
            url: "/api/tenant/user/reset_password",
            method: "post",
            data: this.form,
          })
            .then((res) => {
              console.log("res");
              console.log(res);
              this.$message("密码找回成功");

              this.$refs.form.resetFields();
              setTimeout(() => {
                this.$refs.form.resetFields();
                this.$router.push({ name: "Login" });
              }, 0);
            })
            .catch((res) => {
              console.log(" err");
              console.log(res);
              if (res.data.status == -113 || res.data.status == -112) {
                this.phone_code_err = true;
                this.$refs.form.validateField("phone_code");
              } else {
                this.$message(res.data.status_detail);
              }
            });
        }
      });
    },
  },
  data() {
    const imgErr = (rule, value, callback) => {
      if (this.image_code_err) {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };
    const phoneCodeErr = (rule, value, callback) => {
      if (this.phone_code_err) {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("必填"));
      } else {
        if (this.form.r_password !== "") {
          this.$refs.form.validateField("r_password");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("必填"));
      } else if (value !== this.form.new_password) {
        callback(new Error("输入不一致"));
      } else {
        callback();
      }
    };
    return {
      phone_code_err: false,
      image_code_err: false,
      form: {},
      rules: {
        new_password: [
          {
            required: true,
            message: "必填",
          },
          { validator: validatePass },
          { min: 6, message: "至少输入6个字符" },
        ],
        r_password: [
          {
            required: true,
            message: "必填",
          },
          { validator: validatePass2 },
          { min: 6, message: "至少输入6个字符" },
        ],
        image_code: [
          {
            required: true,
            message: "必填",
          },
          { validator: imgErr },
        ],
        phone: [
          {
            required: true,
            message: "必填",
          },
          { validator: checkPhone },
        ],
        phone_code: [
          {
            required: true,
            message: "必填",
          },
          { validator: phoneCodeErr },
        ],
      },
    };
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
