<template>
  <div class="weight-index">
    <div class="weight-towers">
      <div class="weight-btns">
        <div class="my-btn">物料重量</div>
        <div class="my-btn">本次加料量</div>
        <div class="my-btn hide">存栏数量</div>
      </div>
      <div v-for="(item, index) in arr" :key="index" class="weight-tower-item">
        <div class="weight-tower">
          <div class="weight-tower-rongliang-label">{{ item.val }}%</div>

          <div class="weight-tower-rongliang">
            <div
              class="weight-tower-rongliang-val"
              :style="{ height: item.val + '%' }"
            ></div>
          </div>
        </div>
        <table class="my-table">
          <thead>
            <tr>
              <td>{{ index + 1 }} #塔</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ ((item.t * item.val) / 100).toFixed(2) }}</td>
            </tr>
            <tr>
              <td>{{ (item.t / 5).toFixed(2) }}</td>
            </tr>
            <tr class="hide">
              <td>{{ item.v3 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="weight-towers">
      <div class="weight-btns">
        <div class="my-btn">物料重量</div>
        <div class="my-btn">本次加料量</div>
        <div class="my-btn hide">存栏数量</div>
      </div>
      <div v-for="(item, index) in arr2" :key="index" class="weight-tower-item">
        <div class="weight-tower">
          <div class="weight-tower-rongliang-label">{{ item.val }}%</div>
          <div class="weight-tower-rongliang">
            <div
              class="weight-tower-rongliang-val"
              :style="{ height: item.val + '%' }"
            ></div>
          </div>
        </div>
        <table class="my-table">
          <thead>
            <tr>
              <td>{{ index + 14 }} #塔</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ ((item.t * item.val) / 100).toFixed(2) }}</td>
            </tr>
            <tr>
              <td>{{ (item.t / 5).toFixed(2) }}</td>
            </tr>
            <tr class="hide">
              <td>{{ item.v3 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  data() {
    return {
      arr: [
        {
          t: 3.6,
          val: 23,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 7.3,
          val: 28,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 3.6,
          val: 98,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 7.3,
          val: 76,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 3.6,
          val: 88,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 7.3,
          val: 56,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        { t: 7.3, val: 43, v1: "xx", v2: "xx", v3: "xx" },
        { t: 7.3, val: 76, v1: "xx", v2: "xx", v3: "xx" },
        { t: 7.3, val: 54, v1: "xx", v2: "xx", v3: "xx" },
        {
          t: 7.3,
          val: 76,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 7.3,
          val: 72,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 7.3,
          val: 37,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 7.3,
          val: 94,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
      ],
      arr2: [
        {
          t: 7.3,
          val: 37,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 7.3,
          val: 46,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 1.7,
          val: 85,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 1.7,
          val: 76,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 5.4,
          val: 25,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 17.6,
          val: 26,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 17.6,
          val: 39,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        {
          t: 17.6,
          val: 94,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        { t: 17.6, val: 83, v1: "xx", v2: "xx", v3: "xx" },
        {
          t: 17.6,
          val: 89,
          v1: "xx",
          v2: "xx",
          v3: "xx",
        },
        { t: 17.6, val: 25, v1: "xx", v2: "xx", v3: "xx" },
        { t: 17.6, val: 35, v1: "xx", v2: "xx", v3: "xx" },
        { t: 17.6, val: 76, v1: "xx", v2: "xx", v3: "xx" },
      ],
    };
  },
  mounted() {
    console.log("feed index mounted");
    console.log(this.$route);
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>


