<template>
  <div class="liquid-index">
    <div class="liquid-left">
      <el-form ref="form" :model="form" class="liquid-select global-form">
        <el-form-item>
          <el-select v-model="form.f2" placeholder="">
            <el-option label="y1" value="y1"></el-option>
            <el-option label="y2" value="y2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-tabs>
        <el-tab-pane label="运行状态">
          <div class="liquid-status">
            <div class="liquid-status-top">
              <div
                v-for="(item, index) in arr"
                :key="index"
                :class="`liquid-item liquid-item-${item.status}`"
              >
                {{ index + 1 }}
              </div>
            </div>
            <div class="liquid-status-bottom">
              <div class="liquid-status-mc">
                <div class="liquid-rongliang liquid-rongliang-1">
                  <div
                    class="liquid-rongliang-val"
                    :style="{ height: 90 + '%' }"
                  ></div>
                </div>
                <div class="liquid-weight">109kg</div>

                <div class="liquid-rongliang liquid-rongliang-2 hide">
                  <div
                    class="liquid-rongliang-val"
                    :style="{ height: 50 + '%' }"
                  ></div>
                </div>
                <div class="liquid-txt liquid-txt-1">阀门1</div>
                <div class="liquid-txt liquid-txt-2">阀门2</div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="信息" disabled>信息</el-tab-pane>
        <el-tab-pane label="服务" disabled>服务</el-tab-pane>
      </el-tabs>
    </div>
    <div class="liquid-right">
      <div class="my-btn my-btn-1">自动模式</div>
      <div class="my-btn my-btn-2">准备时间：8:00</div>
      <el-card class="liquid-card-1">
        <template #header>
          <div class="card-header">
            <span>喂食A：109kg</span>
          </div>
        </template>
        <div class="liquid-msg1">
          <div
            v-for="(item, index) in msg1"
            :key="index"
            class="liquid-msg-item"
          >
            {{ item }}
          </div>
        </div>
      </el-card>
      <div class="my-btn my-btn-3 hide">准备时间：8:00</div>

      <el-card class="liquid-card-2 hide">
        <template #header>
          <div class="card-header">
            <span>Ring</span>
          </div>
        </template>
        <div class="liquid-msg1">
          <div
            v-for="(item, index) in msg1"
            :key="index"
            class="liquid-msg-item"
          >
            {{ item }}
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  data() {
    return {
      form: {
        f1: "",
        f2: "",
        f3: "",
        f4: "",
      },
      arr: [
        { status: 0 },
        { status: 0 },
        { status: 0 },
        { status: 0 },
        { status: 0 },
      ],
      msg1: ["事件栏：没有", "时间：", "DP：", "群：", "R1：", "R2:"],
      msg2: ["x", "x", "x"],
    };
  },
  mounted() {
    console.log("feed index mounted");
    console.log(this.$route);
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
<style lang="less">
</style>



