<template>
  <div class="index">
    <div class="box1">
      <div class="zhu">
        <div class="zhu-item">
          <div class="zhu-icon1"></div>
          <div class="zhu-info">
            <div class="zhu-info-title">本周出栏量</div>
            <div class="zhu-info-num">1090</div>
          </div>
        </div>
        <div class="zhu-item">
          <div class="zhu-icon1"></div>
          <div class="zhu-info">
            <div class="zhu-info-title">本周病死数</div>
            <div class="zhu-info-num">85</div>
          </div>
        </div>
      </div>
      <div class="cunlan">
        <div class="cunlan-title"></div>
        <div class="cunlan-item">
          <div class="cunlan-item-title">保育猪</div>
          <div class="cunlan-item-num">4312</div>
        </div>
        <div class="cunlan-item">
          <div class="cunlan-item-title">育肥猪</div>
          <div class="cunlan-item-num">5184</div>
        </div>
        <div class="cunlan-item">
          <div class="cunlan-item-title">育成猪</div>
          <div class="cunlan-item-num">3888</div>
        </div>
        <div class="cunlan-item">
          <div class="cunlan-item-title">公猪</div>
          <div class="cunlan-item-num">100</div>
        </div>
        <div class="cunlan-item">
          <div class="cunlan-item-title">母猪</div>
          <div class="cunlan-item-num">2000</div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="map">
        <div class="global-title">养殖场俯视图</div>
        <div class="map-box">
          <router-link
            :to="{ name: 'projectEnv', query: { pigstyid: item.value } }"
            v-for="(item, index) in pigstyList"
            :key="index"
            class="she"
            :style="{ left: `${item.l}rem`, top: `${item.t}rem` }"
          >
            {{ item.text }}
          </router-link>
        </div>
      </div>
      <div class="index-right">
        <div class="chanliang">
          <div class="global-title">一二期产量</div>
          <div id="chanliang" class="chanliang-box"></div>
        </div>
        <div class="shebei">
          <div class="global-title">设备统计</div>
          <div class="shebei-box">
            <div class="shebei-list">
              <div class="shebei-item">
                <div class="shebei-icon1"></div>
                <div class="shebei-info">
                  <div class="shebei-info-title">设备数量</div>
                  <div class="shebei-info-num">788</div>
                </div>
              </div>
              <div class="shebei-item">
                <div class="shebei-icon1"></div>
                <div class="shebei-info">
                  <div class="shebei-info-title">运行数量</div>
                  <div class="shebei-info-num">771</div>
                </div>
              </div>
              <div class="shebei-item">
                <div class="shebei-icon1"></div>
                <div class="shebei-info">
                  <div class="shebei-info-title">停机数量</div>
                  <div class="shebei-info-num">17</div>
                </div>
              </div>
              <div class="shebei-item">
                <div class="shebei-icon1"></div>
                <div class="shebei-info">
                  <div class="shebei-info-title">报警数量</div>
                  <div class="shebei-info-num">5</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nengyuan">
          <div class="global-title">能源消耗</div>
          <div class="nengyuan-box">
            <div class="nengyuan-table-title">
              <div class="nengyuan-table-title-item">今日</div>
              <div class="nengyuan-table-title-item">每只动物</div>
              <div class="nengyuan-table-title-item">总量</div>
            </div>
            <div class="nengyuan-bg">
              <table>
                <tr>
                  <td class="td1">
                    <div class="nengyuan-icon1"></div>
                    <div class="nengyuan-txt1">电表</div>
                  </td>
                  <td class="td2">18840kwh</td>
                  <td class="td2">1.35kwh</td>
                  <td class="td2">6782400kwh</td>
                </tr>
                <tr>
                  <td class="td1">
                    <div class="nengyuan-icon2"></div>
                    <div class="nengyuan-txt1">水表</div>
                  </td>
                  <td class="td2">200t</td>
                  <td class="td2">0.12t</td>
                  <td class="td2">76322t</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "Page",
  components: {},
  data() {
    return {
      pigstyList: this.$store.state.she.map((item) => {
        return { text: item.name, value: item.id, l: item.l, t: item.t };
      }),
    };
  },
  methods: {
    setChanliang(data) {
      let dom = document.getElementById("chanliang");
      let chart = echarts.init(dom);
      let option;
      option = {
        grid: {
          left: 20,
          bottom: 30,
          right: 10,
          top: 50,
          // width: 470,
          // height: 90,
          containLabel: true,
        },
        tooltip: {
          confine: true,
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#31c4f6",
            },
          },
        },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            // magicType: { show: true, type: ["line", "bar"] },
            // restore: { show: true },
            // saveAsImage: { show: true },
          },
        },
        legend: {
          data: data.map((item) => item.name),
          itemWidth: 8,
          itemHeight: 2,
          bottom: 15,
          textStyle: {
            fontSize: 7,
            color: "#FFFFFF",
          },
        },
        xAxis: [
          {
            type: "category",
            data: this.$store.state.total.chanliangTime,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              color: "#4D9EED",
              fontSize: 9,
            },
          },
        ],
        yAxis: [
          {
            scale: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#31c4f6",
              },
            },
            type: "value",
            name: "产量",
            nameTextStyle: {
              color: "#C5DBF4",
              verticalAlign: "top",
              fontSize: 9,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#28478c",
                type: "dashed",
              },
            },
            axisLabel: {
              formatter: "{value}",
              color: "#C5DBF4",
              fontSize: 8,
            },
          },
          {
            scale: true,
            type: "value",
            name: "产值",
            nameTextStyle: {
              color: "#C5DBF4",
              verticalAlign: "top",
              fontSize: 9,
            },
            axisLabel: {
              formatter: "{value}",
              color: "#C5DBF4",
              fontSize: 8,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: data,
      };
      chart.setOption(option);
      this.chanliangChart = chart;
    },
    getPigstyList() {
      this.$myRequest({
        url: "/api/asset/option/pigsty",
      })
        .then((res) => {
          console.log("res");
          console.log(res);
          this.pigstyList = res.data.body;
          //
        })
        .catch((res) => {
          console.log("err");
          console.log(res);
          this.$message(res.data.status_detail);
        });
    },
  },
  mounted() {
    this.setChanliang(this.$store.state.total.chanliang);
  },
  beforeUnmount() {
    this.chanliangChart.dispose();
  },
};
</script>
<style scoped lang="less" src="./index2.less"></style>
