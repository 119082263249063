<template>
  <div class="tip-content">
    <p><span>设备名称：</span><span>流量计>></span></p>
    <p><span>状态：</span><span>在线</span></p>
    <p><span>当前流量：</span><span>{{value}}m³/s</span></p>
    <p><span>累计流量：</span><span>{{total}}m³</span></p>
    <p><span>运行电流：</span><span>0.3A</span></p>
    <p><span>运行时长：</span><span>1时12分</span></p>
    <p><span>上次检测时间：</span><span>2023年2月12日</span></p>
    <p><span>预计下次检测时间：</span><span>2023年8月12日</span></p>
  </div>
</template>

<script>
export default {
  props: ['value', 'total'],
  name: "LiuLiangJi",
};
</script>

<style scoped lang="less" src="./style.less"></style>
