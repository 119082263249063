<template>
  <div class="guzhong">
    <el-form :inline="true" :model="form" class="demo-form-inline global-form global-form-inline">
      <el-form-item label="栋舍名称：">
        <el-select v-model="sheId" placeholder=" ">
          <el-option
            v-for="(item, index) in $store.state.she"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单元号：">
        <el-select v-model="danId" placeholder=" " v-if="sheId">
          <el-option
            v-for="(item, index) in $store.getters.getSheBySheId(sheId).arr"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="global-title title1">今日数据</div>
    <div class="box1">
      <div class="item1" v-for="(item, index) in data1" :key="index">
        <div class="icon-box">
          <div :class="'icon' + (index + 1)"></div>
        </div>
        <div class="item-txt">
          <div class="item-txt1">{{ item.name }}</div>
          <div class="item-txt2">{{ item.value }}</div>
        </div>
      </div>
      <div class="title2">存栏量</div>
      <div class="item2" v-for="(item, index) in data2" :key="index">
        <div class="item-txt1">{{ item.name }}</div>
        <div class="item-txt2">{{ item.value }}</div>
      </div>
    </div>
    <div class="box2">
      <div class="box2-left">
        <div class="item1" v-for="(item, index) in data3" :key="index">
          <div class="item-txt1">{{ item.name }}</div>
          <div class="item-txt2">{{ item.value }}</div>
        </div>
      </div>
      <div class="title2">估量</div>
      <div class="box2-right">
        <div class="item-box">
          <div class="item1">
            <div class="item-txt1">平均长度</div>
            <div class="item-txt2">39,000</div>
          </div>
          <div class="item1">
            <div class="item-txt1">平均宽度</div>
            <div class="item-txt2">39,000</div>
          </div>
        </div>
        <div class="line"></div>
        <div class="item-box">
          <div class="item1">
            <div class="item-txt1">最大长度</div>
            <div class="item-txt2">39,000</div>
          </div>
          <div class="item1">
            <div class="item-txt1">最小长度</div>
            <div class="item-txt2">39,000</div>
          </div>
        </div>
        <div class="line"></div>
        <div class="item-box">
          <div class="item1">
            <div class="item-txt1">最大宽度</div>
            <div class="item-txt2">39,000</div>
          </div>
          <div class="item1">
            <div class="item-txt1">最小宽度</div>
            <div class="item-txt2">39,000</div>
          </div>
        </div>
        <div class="line"></div>
        <div class="item1">
          <div class="item-txt1">平均重量</div>
          <div class="item-txt2">39,000</div>
        </div>
        <div class="line"></div>

        <div class="item-box">
          <div class="item1">
            <div class="item-txt1">最大重量</div>
            <div class="item-txt2">39,000</div>
          </div>
          <div class="item1">
            <div class="item-txt1">最小重量</div>
            <div class="item-txt2">39,000</div>
          </div>
        </div>
      </div>
    </div>
    <el-form :inline="true" :model="form2" class="chartTime">
      <el-form-item label="时间">
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="box3">
      <div class="chanliang">
        <div class="global-title">集团产量</div>
        <div id="chanliang" class="chanliang-box"></div>
      </div>
      <el-carousel
        :loop="false"
        indicator-position="none"
        trigger="click"
        width="637rem"
        height="330rem"
        class="lunbo"
      >
        <el-carousel-item>
          <div class="video-box">
            <div class="video-item">
              <div class="video-top">
                <div class="video-position"></div>
                <div class="video-name">
                  <div class="video-name1">厦门猪场</div>
                  <div class="video-name2">保育舍</div>
                </div>
                <div class="video-status"></div>
                <div class="video-status-txt">在线</div>
              </div>
              <video :src="pan1" loop muted controls autoplay></video>
              <div class="video-title">测试中介总览水水水水 拷贝</div>
            </div>
            <div class="video-item">
              <div class="video-top">
                <div class="video-position"></div>
                <div class="video-name">
                  <div class="video-name1">厦门猪场</div>
                  <div class="video-name2">保育舍</div>
                </div>
                <div class="video-status"></div>
                <div class="video-status-txt">在线</div>
              </div>
              <video :src="pan2" loop muted controls autoplay></video>
              <div class="video-title">测试中介总览水水水水 拷贝</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import pan1 from "../../assets/a.mp4";
import pan2 from "../../assets/a.mp4";
export default {
  name: "Page",
  components: {},
  data() {
    return {
      pan1,
      pan2,
      date: "",
      sheId: "",
      danId: "",
      data1: [
        {
          name: "本周出栏量",
          value: 33333,
        },
        {
          name: "本周配种数",
          value: 33333,
        },
        {
          name: "本周产仔数",
          value: 33333,
        },
        {
          name: "本周病死数",
          value: 33333,
        },
      ],
      data2: [
        {
          name: "仔猪",
          value: 33333,
        },
        {
          name: "保育猪",
          value: 33333,
        },
        {
          name: "育肥猪",
          value: 33333,
        },
        {
          name: "母猪",
          value: 33333,
        },
        {
          name: "公猪",
          value: 33333,
        },
      ],
      data3: [
        {
          name: "保育猪科肉比",
          value: 33333,
        },
        {
          name: "母猪PSY",
          value: 33333,
        },
        {
          name: "育肥猪存活率",
          value: 33333,
        },
        {
          name: "育肥猪料肉比",
          value: 33333,
        },
        {
          name: "保育猪存活率",
          value: 33333,
        },
        {
          name: "仔猪存活率",
          value: 33333,
        },
      ],
    };
  },
  watch: {
    sheId() {
      console.log("she change");
      console.log(this.sheId);
      this.danId = this.$store.getters.getSheBySheId(this.sheId).arr[0].id;
    },
  },
  methods: {
    setChanliang(data) {
      let dom = document.getElementById("chanliang");
      let chart = echarts.init(dom);
      let option;
      option = {
        grid: {
          left: 20,
          bottom: 30,
          right: 10,
          top: 50,
          // width: 470,
          // height: 90,
          containLabel: true,
        },
        tooltip: {
          confine: true,
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#31c4f6",
            },
          },
        },
        toolbox: {
          feature: {
            // dataView: { show: true, readOnly: false },
            // magicType: { show: true, type: ["line", "bar"] },
            // restore: { show: true },
            // saveAsImage: { show: true },
          },
        },
        legend: {
          data: data.map((item) => item.name),
          itemWidth: 8,
          itemHeight: 2,
          bottom: 15,
          textStyle: {
            fontSize: 7,
            color: "#FFFFFF",
          },
        },
        xAxis: [
          {
            type: "category",
            data: this.$store.state.total.chanliangTime,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              color: "#4D9EED",
              fontSize: 9,
            },
          },
        ],
        yAxis: [
          {
            scale: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#31c4f6",
              },
            },
            type: "value",
            name: "产量",
            nameTextStyle: {
              color: "#C5DBF4",
              verticalAlign: "top",
              fontSize: 9,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#28478c",
                type: "dashed",
              },
            },
            axisLabel: {
              formatter: "{value}",
              color: "#C5DBF4",
              fontSize: 8,
            },
          },
          {
            scale: true,
            type: "value",
            name: "产值",
            nameTextStyle: {
              color: "#C5DBF4",
              verticalAlign: "top",
              fontSize: 9,
            },
            axisLabel: {
              formatter: "{value}",
              color: "#C5DBF4",
              fontSize: 8,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: data,
      };
      chart.setOption(option);
      this.chanliangChart = chart;
    },
  },
  mounted() {
    console.log("she");
    console.log(this.$store.state.she);
    this.sheId = this.$store.state.she[0].id;
    this.danId = this.$store.getters.getSheBySheId(this.sheId).arr[0].id;
    this.setChanliang(this.$store.state.total.chanliang);
  },
  beforeUnmount() {
    this.chanliangChart.dispose();
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
