<template>
  <div class="wrapper">
    <div class="shu shu1"></div>
    <div class="shu shu2"></div>
    <div class="shu shu3"></div>
    <div class="shu shu4"></div>
    <div class="shu shu5"></div>
    <div class="guan guan1"></div>
    <div class="guan guan1_1"></div>
    <div class="guan guan2"></div>
    <div class="guan guan3"></div>
    <div class="guan guan4"></div>
    <div class="guan guan5"></div>
    <div class="guan guan6"></div>
    <div class="guan guan7"></div>

    <div class="text-block block1-4">奶厅地面冲洗</div>
    <div class="text-block block1-5">粪沟回冲</div>

    <!-- <el-tooltip popper-class="m-device-tip-blue-top" placement="top">
      <template #content> <liu-liang-ji :value="63" :total="102" /></template> -->
    <div class="block block1-2">
      <div class="b-left">
        <div class="img-box">
          <img class="zi_dong_fa" :src="require('../imgs/tdf.png')" />
        </div>
        <div class="name">自动阀</div>
      </div>
      <div class="b-right">
        <div class="value gray">关闭</div>
        <div class="status">在线</div>
      </div>
    </div>
    <!-- </el-tooltip> -->
    <div class="block block1-3">
      <div class="b-left">
        <div class="img-box">
          <img class="zi_dong_fa" :src="require('../imgs/tdf.png')" />
        </div>
        <div class="name">自动阀</div>
      </div>
      <div class="b-right">
        <div class="value gray">关闭</div>
        <div class="status">在线</div>
      </div>
    </div>

    <el-tooltip popper-class="m-device-tip-blue-top" placement="top">
      <template #content> <liu-liang-ji :value="63" :total="102" /></template>
      <div class="block block1-1">
        <div class="b-left">
          <div class="img-box">
            <img
              class="liu_liang_ji"
              :src="require('../imgs/liu_liang_ji.png')"
            />
          </div>
          <div class="name">流量计</div>
        </div>
        <div class="b-right">
          <div class="value">63m³/h</div>
          <div class="status">在线</div>
        </div>
      </div>
    </el-tooltip>

    <div class="box box1">
      <div class="title">中转池</div>
      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content>
          <ye-wei-yi />
        </template>
        <div class="block block1">
          <div class="b-left">
            <div class="img-box">
              <img class="ye_wei_yi" :src="require('../imgs/ye_wei_yi.png')" />
            </div>
            <div class="name">液位仪</div>
          </div>
          <div class="b-right">
            <div class="value">2.3米</div>
            <div class="status">在线</div>
          </div>
        </div>
      </el-tooltip>

      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content>
          <liu-liang-ji :value="'0.0'" :total="102"
        /></template>
        <div class="block block3">
          <div class="b-left">
            <div class="img-box">
              <img
                class="liu_liang_ji"
                :src="require('../imgs/liu_liang_ji.png')"
              />
            </div>
            <div class="name">流量计</div>
          </div>
          <div class="b-right">
            <div class="value">0m³/h</div>
            <div class="status">在线</div>
          </div>
        </div>
      </el-tooltip>
      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content>
          <qian-shui-jiao-ban-ji :value="'0.0'" :status="'关闭'"
        /></template>
        <div class="block block4">
          <div class="b-left">
            <div class="img-box">
              <img
                class="qian_shui_jiao_ban_ji"
                :src="require('../imgs/qian_shui_jiao_ban_ji.png')"
              />
            </div>
            <div class="name">潜水搅拌机</div>
          </div>
          <div class="b-right">
            <div class="value gray">0.0A</div>
            <div class="status gray">关闭</div>
          </div>
        </div>
      </el-tooltip>
      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content>
          <shu-song-beng name="回冲泵" :value="'0.0'" status="关闭"
        /></template>
        <div class="block block5">
          <div class="b-left">
            <div class="img-box">
              <img
                class="shu_song_beng"
                :src="require('../imgs/shu_song_beng.png')"
              />
            </div>
            <div class="name">回冲泵</div>
          </div>
          <div class="b-right">
            <div class="value gray">0.0A</div>
            <div class="status gray">关闭</div>
          </div>
        </div>
      </el-tooltip>
    </div>

    <div class="box box2">
      <div class="title">集污池</div>
      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content> <ge-shan-ji /></template>
        <div class="block block5">
          <div class="b-left">
            <div class="img-box">
              <img
                class="ge_shan_ji"
                :src="require('../imgs/ge_shan_ji.png')"
              />
            </div>
            <div class="name">格栅机</div>
          </div>
          <div class="b-right">
            <div class="value gray">0.0A</div>
            <div class="status gray">关闭</div>
          </div>
        </div>
      </el-tooltip>

      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content> <ye-wei-yi :value="5" /></template>
        <div class="block block1">
          <div class="b-left">
            <div class="img-box">
              <img class="ye_wei_yi" :src="require('../imgs/ye_wei_yi.png')" />
            </div>
            <div class="name">液位仪</div>
          </div>
          <div class="b-right">
            <div class="value">5米</div>
            <div class="status">在线</div>
          </div>
        </div>
      </el-tooltip>
      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content> <liu-liang-ji :value="70" :total="265" /></template>
        <div class="block block2">
          <div class="b-left">
            <div class="img-box">
              <img
                class="liu_liang_ji"
                :src="require('../imgs/liu_liang_ji.png')"
              />
            </div>
            <div class="name">流量计</div>
          </div>
          <div class="b-right">
            <div class="value">70m³/h</div>
            <div class="status">在线</div>
          </div>
        </div>
      </el-tooltip>
      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content>
          <qian-shui-jiao-ban-ji :value="15" :status="'开启'"
        /></template>
        <div class="block block3">
          <div class="b-left">
            <div class="img-box">
              <img
                class="qian_shui_jiao_ban_ji"
                :src="require('../imgs/qian_shui_jiao_ban_ji.png')"
              />
            </div>
            <div class="name">潜水搅拌机</div>
          </div>
          <div class="b-right">
            <div class="value">15A</div>
            <div class="status">开启</div>
          </div>
        </div>
      </el-tooltip>
      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content> <jin-liao-beng :value="22" /></template>
        <div class="block block4">
          <div class="b-left">
            <div class="img-box">
              <img
                class="shu_song_beng"
                :src="require('../imgs/shu_song_beng.png')"
              />
            </div>
            <div class="name">进料泵</div>
          </div>
          <div class="b-right">
            <div class="value">22A</div>
            <div class="status">开启</div>
          </div>
        </div>
      </el-tooltip>
    </div>

    <div class="box box3">
      <div class="title">固态分离车间</div>
      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content> <gu-ye-fen-li-ji :value="15" /></template>
        <div class="block block1">
          <div class="b-left">
            <div class="img-box">
              <img
                class="gu_ye_fen_li_ji"
                :src="require('../imgs/gu_ye_fen_li_ji.png')"
              />
            </div>
            <div class="name">固液分离机</div>
          </div>
          <div class="b-right">
            <div class="value">15A</div>
            <div class="status">开启</div>
          </div>
        </div>
      </el-tooltip>
      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content>
          <shu-song-zhuang-zhi :value="6.1" :status="'开启'"
        /></template>
        <div class="block block2">
          <div class="b-left">
            <div class="img-box">
              <img
                class="shu_song_zhuang_zhi"
                :src="require('../imgs/shu_song_zhuang_zhi.png')"
              />
            </div>
            <div class="name">输送装置</div>
          </div>
          <div class="b-right">
            <div class="value">6.1A</div>
            <div class="status">开启</div>
          </div>
        </div>
      </el-tooltip>
    </div>

    <div class="box box4">
      <div class="title">固肥晾晒场+分子膜发酵系统</div>
      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content> <wen-du :value="68.2" /></template>
        <div class="block block1">
          <div class="b-left">
            <div class="img-box">
              <img class="wen_du" :src="require('../imgs/wen_du.png')" />
            </div>
            <div class="name">温度</div>
          </div>
          <div class="b-right">
            <div class="value">68.2℃</div>
            <div class="status">平均温度</div>
          </div>
        </div>
      </el-tooltip>
      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content> <bao-qi-feng-ji :value="11" /></template>
        <div class="block block2">
          <div class="b-left">
            <div class="img-box">
              <img
                class="bao_qi_feng_ji"
                :src="require('../imgs/bao_qi_feng_ji.png')"
              />
            </div>
            <div class="name">曝气风机</div>
          </div>
          <div class="b-right">
            <div class="value">11A</div>
            <div class="status">开启</div>
          </div>
        </div>
      </el-tooltip>
      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content> <shi-du :value="51" /></template>
        <div class="block block3">
          <div class="b-left">
            <div class="img-box">
              <img class="shi_du" :src="require('../imgs/shi_du.png')" />
            </div>
            <div class="name">湿度</div>
          </div>
          <div class="b-right">
            <div class="value">51%RH</div>
            <div class="status">在线</div>
          </div>
        </div>
      </el-tooltip>
      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content> <ya-li :value="2" /></template>
        <div class="block block4">
          <div class="b-left">
            <div class="img-box">
              <img class="ya_li" :src="require('../imgs/ya_li.png')" />
            </div>
            <div class="name">压力</div>
          </div>
          <div class="b-right">
            <div class="value">2kpa</div>
            <div class="status">在线</div>
          </div>
        </div>
      </el-tooltip>
      <div class="block block5 no-pointer">
        <div class="b-left">
          <div class="img-box">
            <img class="house" :src="require('../imgs/house.png')" />
          </div>
          <div class="name"></div>
        </div>
        <div class="b-right">
          <div class="value" style="margin-left: -40px">14天</div>
          <div class="status" style="margin-left: -80px">累计发酵天数</div>
        </div>
      </div>
    </div>

    <div class="box box5">
      <div class="title">液体池</div>

      <el-tooltip popper-class="m-device-tip-blue-top" placement="top">
        <template #content>
          <shu-song-beng name="回冲泵" value="22" status="开启"
        /></template>
        <div class="block block6 block-red">
          <div class="b-left">
            <div class="img-box">
              <img
                class="huan_tian_beng"
                :src="require('../imgs/huan_tian_beng.png')"
              />
            </div>
            <div class="name">回冲泵</div>
          </div>
          <div class="b-right">
            <div class="value red">22A</div>
            <div class="status red">开启</div>
          </div>
        </div>
      </el-tooltip>

      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content>
          <liu-liang-ji :value="'0.0'" total="1236"
        /></template>
        <div class="block block5">
          <div class="b-left">
            <div class="img-box">
              <img
                class="liu_liang_ji"
                :src="require('../imgs/liu_liang_ji.png')"
              />
            </div>
            <div class="name">流量计</div>
          </div>
          <div class="b-right">
            <div class="value">0m³/h</div>
            <div class="status">在线</div>
          </div>
        </div>
      </el-tooltip>

      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content> <ye-wei-yi :value="5" /></template>
        <div class="block block1">
          <div class="b-left">
            <div class="img-box">
              <img class="ye_wei_yi" :src="require('../imgs/ye_wei_yi.png')" />
            </div>
            <div class="name">液位仪</div>
          </div>
          <div class="b-right">
            <div class="value">5米</div>
            <div class="status">在线</div>
          </div>
        </div>
      </el-tooltip>

      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content>
          <liu-liang-ji :value="'0.0'" total="1236"
        /></template>
        <div class="block block2">
          <div class="b-left">
            <div class="img-box">
              <img
                class="liu_liang_ji"
                :src="require('../imgs/liu_liang_ji.png')"
              />
            </div>
            <div class="name">流量计</div>
          </div>
          <div class="b-right">
            <div class="value">0m³/h</div>
            <div class="status">在线</div>
          </div>
        </div>
      </el-tooltip>

      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content>
          <qian-shui-jiao-ban-ji :value="15" :status="'开启'"
        /></template>
        <div class="block block3">
          <div class="b-left">
            <div class="img-box">
              <img
                class="qian_shui_jiao_ban_ji"
                :src="require('../imgs/qian_shui_jiao_ban_ji.png')"
              />
            </div>
            <div class="name">潜水搅拌机</div>
          </div>
          <div class="b-right">
            <div class="value">15A</div>
            <div class="status">开启</div>
          </div>
        </div>
      </el-tooltip>

      <el-tooltip popper-class="m-device-tip-blue-bottom" placement="bottom">
        <template #content> <pai-ye-beng :value="0" /></template>
        <div class="block block4">
          <div class="b-left">
            <div class="img-box">
              <img
                class="shu_song_beng"
                :src="require('../imgs/shu_song_beng.png')"
              />
            </div>
            <div class="name">排液泵</div>
          </div>
          <div class="b-right">
            <div class="value gray">0A</div>
            <div class="status gray">关闭</div>
          </div>
        </div>
      </el-tooltip>
    </div>

    <div class="box box6">
      <div class="title">沼气净化燃烧系统</div>
      <el-tooltip popper-class="m-device-tip-blue-top" placement="top">
        <template #content> <zhao-qi-huo-ju :value="15" /></template>
        <div class="block block1">
          <div class="b-left">
            <div class="img-box">
              <img
                class="zhao_qi_huo_ju"
                :src="require('../imgs/zhao_qi_huo_ju.png')"
              />
            </div>
            <div class="name">沼气火炬</div>
          </div>
          <div class="b-right">
            <div class="value">15kpa</div>
            <div class="status">开启</div>
          </div>
        </div>
      </el-tooltip>
      <el-tooltip popper-class="m-device-tip-blue-top" placement="top">
        <template #content> <tuo-liu-guan value="30分" /></template>
        <div class="block block2">
          <div class="b-left">
            <div class="img-box">
              <img
                class="tuo_liu_guan"
                :src="require('../imgs/tuo_liu_guan.png')"
              />
            </div>
            <div class="name">脱硫罐</div>
          </div>
          <div class="b-right">
            <div class="value">30分</div>
            <div class="status">运行时长</div>
          </div>
        </div>
      </el-tooltip>

      <div class="block block3 no-pointer">
        <div class="b-left">
          <div class="img-box">
            <img
              class="qi_shui_fen_li_qi"
              :src="require('../imgs/qi_shui_fen_li_qi.png')"
            />
          </div>
          <div class="name">汽水分离器</div>
        </div>
      </div>
    </div>

    <div class="box box7">
      <div class="title">氧化池</div>
      <el-tooltip popper-class="m-device-tip-blue-top" placement="top">
        <template #content> <huan-tian-beng :name="'还田泵'" /></template>
        <div class="block block1">
          <div class="b-left">
            <div class="img-box">
              <img
                class="huan_tian_beng"
                :src="require('../imgs/huan_tian_beng.png')"
              />
            </div>
            <div class="name">还田泵</div>
          </div>
          <div class="b-right">
            <div class="value">--</div>
            <div class="status">开启</div>
          </div>
        </div>
      </el-tooltip>

      <el-tooltip popper-class="m-device-tip-blue-top" placement="top">
        <template #content>
          <liu-liang-ji :value="150" :total="1236"
        /></template>
        <div class="block block2">
          <div class="b-left">
            <div class="img-box">
              <img
                class="liu_liang_ji"
                :src="require('../imgs/liu_liang_ji.png')"
              />
            </div>
            <div class="name">流量计</div>
          </div>
          <div class="b-right">
            <div class="value">150m³/s</div>
            <div class="status">在线</div>
          </div>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import YeWeiYi from "./yeWeiYi.vue";
import GeShanJi from "./geShanJi.vue";
import LiuLiangJi from "./liuLiangJi.vue";
import QianShuiJiaoBanJi from "./qianShuiJiaoBanJi.vue";
import ShuSongBeng from "./shuSongBeng.vue";
import JinLiaoBeng from "./jinLiaoBeng.vue";
import GuYeFenLiJi from "./guYeFenLiJi.vue";
import ShuSongZhuangZhi from "./shuSongZhuangZhi.vue";
import wenDu from "./wenDu.vue";
import BaoQiFengJi from "./baoQiFengJi.vue";
import ShiDu from "./shiDu.vue";
import YaLi from "./yaLi.vue";
import PaiYeBeng from "./paiYeBeng.vue";
import ZhaoQiHuoJu from "./zhaoQiHuoJu.vue";
import TuoLiuGuan from "./tuoLiuGuan.vue";
import HuanTianBeng from "./huanTianBeng.vue";
export default {
  name: "HuanTian",
  components: {
    YeWeiYi,
    GeShanJi,
    LiuLiangJi,
    QianShuiJiaoBanJi,
    ShuSongBeng,
    JinLiaoBeng,
    GuYeFenLiJi,
    ShuSongZhuangZhi,
    wenDu,
    BaoQiFengJi,
    ShiDu,
    YaLi,
    PaiYeBeng,
    ZhaoQiHuoJu,
    TuoLiuGuan,
    HuanTianBeng,
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
<style lang="less">
.el-popper.is-customized {
  background: #13266a;
  border: 1px solid #2d92f5;
}

.el-popper.is-customized .el-popper__arrow::before {
  // background: #13266a;
  // border: 1px solid #2d92f5;
  display: none;
}

.m-device-tip-blue-top {
  border: 1px solid #35a2e8 !important;
  background: #193b6f !important;
  color: #11d4f7 !important;
  border-radius: 0 !important;

  .el-popper__arrow::before {
    border-right: 1px solid #35a2e8 !important;
    border-bottom: 1px solid #35a2e8 !important;
    background: #193b6f !important;
  }
}

.m-device-tip-blue-bottom {
  border: 1px solid #35a2e8 !important;
  background: #193b6f !important;
  color: #11d4f7 !important;
  border-radius: 0 !important;

  .el-popper__arrow::before {
    border-left: 1px solid #35a2e8 !important;
    border-top: 1px solid #35a2e8 !important;
    background: #193b6f !important;
  }
}
</style>
