<template>
  <div class="global-box">
    <div class="pigsty-unit-inline">
      <!-- <pigstyUnit
          v-model="assetidInfo"
          :hasAll="true"
          :initAssetidInfo="initAssetidInfo"
        ></pigstyUnit> -->

      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline global-form global-form-inline"
      >
        <el-form-item label="栋舍名称：">
          <el-select
            v-model="form.pigstyActive"
            placeholder=" "
            no-data-text="暂无数据"
          >
            <el-option
              v-for="(item, index) in pigstyList"
              :key="index"
              :label="item.text"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :style="
            unitList.length && unitList.length > 1 ? '' : 'visibility: hidden'
          "
          label="单元号："
        >
          <el-select
            v-model="form.unitActive"
            placeholder=" "
            @change="unitChange"
            no-data-text="暂无数据"
          >
            <el-option
              v-for="(item, index) in unitList"
              :key="index"
              :label="item.text"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="恢复状态：">
          <el-select v-model="form.recoveried" placeholder="">
            <el-option label="全部" :value="undefined"></el-option>

            <el-option label="已恢复" :value="true"></el-option>
            <el-option label="未恢复" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报警类型：">
          <el-select v-model="form.type" placeholder="">
            <el-option label="全部" :value="undefined"></el-option>
            <el-option label="数据异常" :value="0"></el-option>
            <el-option label="数据延迟" :value="1"></el-option>
            <el-option label="网关离线" :value="2"></el-option>
            <el-option label="供电中断" :value="3"></el-option>
            <el-option label="环控死机" :value="4"></el-option>
            <el-option label="环境异常" :value="5"></el-option>
            <el-option label="设备异常" :value="6"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      :data="tableData"
      class="global-box-table"
      stripe
      border
      height="100"
    >
      <el-table-column
        prop="device.name"
        label="位置"
        width="260px"
        :formatter="formatterNull"
      >
      </el-table-column>
      <el-table-column prop="message" label="信息" :formatter="formatterNull">
      </el-table-column>
      <el-table-column
        prop="data_item"
        label="数据类型"
        :formatter="formatterNull"
      ></el-table-column>
      <el-table-column
        prop="data_value"
        label="数据"
        :formatter="formatterNull"
      ></el-table-column>
      <el-table-column
        prop="recovered"
        label="是否恢复"
        :formatter="formatterRecovered"
      ></el-table-column>
      <el-table-column
        prop="a6"
        label="产生时间"
        :formatter="formatterSt"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="a7"
        label="最后更新时间"
        width="180px"
        :formatter="formatterEt"
      ></el-table-column>
    </el-table>

    <div v-if="totalSize > pageSize" class="padding20">
      <el-pagination
        v-model:currentPage="page"
        background
        layout="prev, pager, next"
        v-if="tableData.length"
        :total="totalSize"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import basil from "@/util/basil";
import moment from "moment";

export default {
  name: "Page",
  components: { },
  data() {
    return {
      projectid: basil.get("projectid"),
      // initAssetidInfo: {
      //   pigstyActive: this.$route.query.pigstyActive,
      //   unitActive: this.$route.query.unitActive,
      // },

      unitList: [],
      pigstyList: [],

      form: {
        pigstyActive: undefined,
        unitActive: undefined,
        recoveried: undefined,
        type: undefined,
      },
      tableData: [],
      assetidInfo: "",
      page: 1,
      pageSize: 20,
      totalSize: 0,
    };
  },
  watch: {
    assetidInfo: function (newVal, old) {
      // console.log("out watch assetidInfo");
      // console.log(this.assetidInfo);
      // // if (newVal && newVal.unitActive && newVal.unitActive != old.unitActive) {
      // this.form.recoveried = undefined;
      // this.form.type = undefined;
      // this.getList();
      // }
    },

    "form.pigstyActive": function (newVal, old) {
      if (newVal) {
        this.getUnitList(this.form.pigstyActive);
      } else if (!newVal) {
        this.unitList = [];
        this.form.unitActive = "";
      }
    },
  },

  methods: {
    search() {
      this.page = 1;
      this.getList();
    },
    handleCurrentChange() {
      this.getList();
    },
    formatterRecovered(row, column) {
      if (row.recovered) {
        return (
          moment(row.recovery_time).format("YYYY-MM-DD HH:mm:ss") + "已恢复"
        );
      } else {
        return "未恢复";
      }
    },
    formatterNull(row, column, cellValue) {
      if (cellValue) {
        return cellValue;
      } else {
        return "--";
      }
    },
    formatterSt(row, column) {
      return moment(row.create_time).format("YYYY-MM-DD HH:mm:ss");
    },
    formatterEt(row, column) {
      return moment(row.latest_time).format("YYYY-MM-DD HH:mm:ss");
    },
    getList() {
      this.$myRequest({
        url: `/api/tenant/alarm/${this.projectid}/list`,
        data: {
          page: this.page,
          pageSize: this.pageSize,
          // assetid: init
          //   ? this.initAssetidInfo.unitActive
          //   : this.assetidInfo.unitActive,

          assetid: this.form.unitActive,
          recoveried: this.form.recoveried,
          type: this.form.type,
        },
      })
        .then((res) => {
          console.log(" res");
          console.log(res);
          this.tableData = res.data.body.datas;
          this.page = res.data.body.page;
          this.pageSize = res.data.body.pageSize;
          this.totalSize = res.data.body.totalSize;
          //
        })
        .catch((res) => {
          console.log(" err");
          console.log(res);
        });
    },

    getUnitList(pigstyid) {
      this.$myRequest({
        url: `/api/tenant/asset/${this.projectid}/option/${pigstyid}/unit`,
      })
        .then((res) => {
          res.data.body.sort((a, b) => Number(a.value) - Number(b.value));
          this.unitList = res.data.body;
          this.form.unitActive = this.unitList[0].value;
        })
        .catch((res) => {});
    },
    getPigstyList() {
      this.$myRequest({
        url: `/api/tenant/asset/${this.projectid}/option/house`,
        data: {
          env: "环控环境",
        },
      })
        .then((res) => {
          this.pigstyList = res.data.body;
          this.pigstyList.unshift({ value: "", text: "全部" });
        })
        .catch((res) => {
          console.log("getPigstyList err");
          console.log(res);
        });
    },
  },
  mounted() {
    this.form.pigstyActive = this.$route.query?.pigstyActive || "";
    this.form.unitActive = this.$route.query.unitActive;

    this.getList();
    this.getPigstyList();
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
