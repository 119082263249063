<template>
  <div class="baojing padding10">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: 'MeadowIndex' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ name: 'MeadowLiquidIndex' }"
            >液态料</el-breadcrumb-item
          >
          <el-breadcrumb-item>报警记录</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs>
          <el-tab-pane label="记录">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-table class="table1" :data="tableData" border>
                  <el-table-column prop="count" label="条"> </el-table-column>
                  <el-table-column prop="level" label="度"> </el-table-column>
                  <el-table-column prop="active" label="活动">
                  </el-table-column>
                  <el-table-column prop="info" label="信息"> </el-table-column>
                  <el-table-column prop="reason" label="原因">
                  </el-table-column>
                  <el-table-column prop="code" label="号码"> </el-table-column>
                  <el-table-column prop="scale1" label="scale">
                  </el-table-column>
                  <el-table-column prop="scale2" label="scale">
                  </el-table-column>
                  <el-table-column prop="time" label="时间点">
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="设置" disabled>xx</el-tab-pane>
          <el-tab-pane label="服务记录" disabled>xx</el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  methods: {},
  data() {
    return {
      tableData: [
        {
          count: 55,
          level: "!",
          active: "系统检查",
          info: "0",
          reason: "数据备份到外部存储器发生错误",
          num: 3,
          scale1: "1",
          scale2: "101",
          time: "2021/5/8 16:46",
        },
        {
          count: 54,
          level: "!",
          active: "系统检查",
          info: "0",
          reason: "数据备份到外部存储器发生错误",
          num: 3,
          scale1: "1",
          scale2: "102",
          time: "2021/5/8 12:20",
        },
      ],
      form: {
        resource: "",
        type: [],
      },
    };
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
