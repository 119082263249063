export const xAxis = [
  "8.10",
  "8.9",
  "8.8",
  "8.7",
  "8.6",
  "8.5",
  "8.4",
  "8.3",
  "8.2",
  "8.1",
  "7.31",
  "7.30",
  "7.29",
  "7.28",
  "7.27",
  "7.26",
  "7.25",
  "7.24",
  "7.23",
  "7.22",
  "7.21",
  "7.20",
].reverse();
// 中转池进料量/m³
export const data1 = [
  420, 435, 389, 406, 352, 367, 493, 414, 412, 391, 390, 402, 501, 415, 382,
  385, 446, 384, 415, 403, 389, 403,
].reverse();
// 固液分离固体量/m³
export const data2 = [
  42, 55, 39, 42, 33, 42, 47, 43, 42, 41, 41, 42, 50, 39, 39, 38, 61, 43, 39,
  42, 40, 49,
].reverse();
// 固液分离液体量/m³
export const data3 = [
  378, 380, 350, 364, 319, 325, 446, 371, 371, 351, 350, 361, 450, 376, 343,
  347, 385, 341, 376, 361, 349, 354,
].reverse();
// 还田液体量/m³
export const data4 = [
  1134, 1135, 1145, 702, 1140, 1210, 1266, 1024, 1384, 1036, 1169, 1154, 1050,
  920, 1385, 600, 1385, 1504, 1166, 1211, 1133, 1104,
].reverse();
// 沼气燃烧时间/min
export const data5 = [
  31, 29, 30, 34, 35, 33, 30, 35, 32, 30, 36, 29, 27, 36, 32, 30, 39, 29, 32,
  34, 33, 37,
].reverse();
// 总用电量/KW·h
export const data6 = [
  260, 262, 256, 244, 261, 266, 275, 259, 289, 251, 263, 260, 255, 253, 295,
  232, 289, 304, 261, 266, 262, 257,
].reverse();
