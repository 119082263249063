<template>
  <div ref="envBox" class="index-wrapper">
    <div ref="env" class="env" :style="`transform:scale(${scale})`">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-row :gutter="10">
            <el-col :span="12">
              <div class="block block1">
                <div class="block-title">昨日饲喂数据统计</div>
                <div class="block_detail">
                  <router-link class="detail_text" :to="{ name: 'SiWei' }">详情>></router-link>
                </div>
                <div class="block-content">
                  <v-chart ref="chart" class="chart" autoresize :option="createOption1()" />
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="block block2">
                <div class="block-title">​昨日测定站数据统计</div>
                <div class="block_detail">
                  <router-link class="detail_text" :to="{ name: 'CeDing' }">详情>></router-link>
                </div>
                <div class="block-content">
                  <ul>
                    <li>
                      <div class="left">
                        <img class="img_2_1" :src="require('./imgs/2-1.png')" />
                      </div>
                      <div class="right">
                        <p class="label">采食量</p>
                        <p class="value">{{ measureData?.amount || 0 }}kg</p>
                      </div>
                    </li>
                    <li>
                      <div class="left">
                        <img class="img_2_2" :src="require('./imgs/2-2.png')" />
                      </div>
                      <div class="right">
                        <p class="label">体重增长量</p>
                        <p class="value">{{ measureData?.weight || 0 }}kg</p>
                      </div>
                    </li>
                    <li>
                      <div class="left">
                        <img class="img_2_3" :src="require('./imgs/2-3.png')" />
                      </div>
                      <div class="right">
                        <p class="label">肉料比</p>
                        <p class="value">
                          {{ rouLiaoBi }}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="10">
            <el-col :span="12">
              <div class="block block3">
                <div class="block-title">7日人员洗消统计</div>
                <div class="block_detail">
                  <router-link class="detail_text" :to="{ name: 'WashList' }">详情>></router-link>
                </div>
                <div class="block-content">
                  <v-chart ref="chart" class="chart" autoresize :option="createOption3()" />
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="block block4">
                <div class="block-title">洗消间状态统计</div>
                <div class="block_detail">
                  <router-link class="detail_text" :to="{ name: 'Wash' }">详情>></router-link>
                </div>
                <div class="block-content">
                  <v-chart ref="chart" class="chart" autoresize :option="createOption4()" />
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="mgt10" :gutter="10">
        <el-col :span="12">
          <div class="block block5">
            <div class="block-title">设备统计</div>
            <!-- <div class="block_detail">
            <router-link class="detail_text" :to="{ name: 'Wash' }"
              >详情>></router-link
            >
          </div> -->
            <div class="block-content">
              <ul>
                <li>
                  <div class="left">
                    <img class="img_5_1" :src="require('./imgs/5-1.png')" />
                  </div>
                  <div class="right">
                    <p class="label">总数量</p>
                    <p class="value">{{ deviceData.total_amount || '--' }}</p>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <img class="img_5_2" :src="require('./imgs/5-2.png')" />
                  </div>
                  <div class="right">
                    <p class="label">正常数量</p>
                    <p class="value">{{ deviceData.running_amount || '--' }}</p>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <img class="img_5_3" :src="require('./imgs/5-3.png')" />
                  </div>
                  <div class="right">
                    <p class="label">离线数量</p>
                    <p class="value">
                      {{
                        (deviceData.total_amount -
                          deviceData.running_amount -
                          deviceData.stoped_amount)
                        || '--' }}
                    </p>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <img class="img_5_4" :src="require('./imgs/5-4.png')" />
                  </div>
                  <div class="right">
                    <p class="label">异常数量</p>
                    <p class="value">{{ deviceData.alarm_amount || '--' }}</p>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <img class="img_5_5" :src="require('./imgs/5-5.png')" />
                  </div>
                  <div class="right">
                    <p class="label">停用数量</p>
                    <p class="value">{{ deviceData.stoped_amount || '--' }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="block block6">
            <div class="block-title">猪舍统计</div>
            <div class="block_detail">
              <router-link class="detail_text" :to="{ name: 'SheIndex' }">详情>></router-link>
            </div>
            <div class="block-content">
              <ul>
                <li>
                  <div class="left">
                    <img class="img_6_1" :src="require('./imgs/6-1.png')" />
                  </div>
                  <div class="right">
                    <p class="label">总数量</p>
                    <p class="value">{{ ecData.total_amount || '--' }}</p>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <img class="img_5_2" :src="require('./imgs/5-2.png')" />
                  </div>
                  <div class="right">
                    <p class="label">正常数量</p>
                    <p class="value">{{ ecData.running_amount || '--' }}</p>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <img class="img_5_3" :src="require('./imgs/5-3.png')" />
                  </div>
                  <div class="right">
                    <p class="label">离线数量</p>
                    <p class="value">
                      {{
                        (ecData.total_amount -
                          ecData.running_amount -
                          ecData.stoped_amount) || '--'
                      }}
                    </p>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <img class="img_5_4" :src="require('./imgs/5-4.png')" />
                  </div>
                  <div class="right">
                    <p class="label">异常数量</p>
                    <p class="value">{{ ecData.alarm_amount || '--' }}</p>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <img class="img_5_5" :src="require('./imgs/5-5.png')" />
                  </div>
                  <div class="right">
                    <p class="label">停用数量</p>
                    <p class="value">{{ ecData.stoped_amount || '--' }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="mgt10">
        <div class="block block7">
          <div class="block-title">能源消耗</div>
          <div class="block_detail">
            <router-link class="detail_text" :to="{ name: 'energyWater' }">详情>></router-link>
          </div>
          <div class="block-content">
            <v-chart ref="chart2" class="chart" autoresize :option="createOption7()" />
          </div>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import basil from "../../util/basil";
import dayjs from "dayjs";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { LineChart } from "echarts/charts";
use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
]);

export default {
  components: { VChart },
  data() {
    return {
      projectid: basil.get("projectid"),
      scale: 1,
      energyType: "1",
      //设备统计
      deviceData: {},
      // 洗消门禁数据
      doorData: {},
      // 环控数据
      ecData: {},

      energyData: {},
      energyXAxis: [],
      energyWater: [],
      energyElectric: [],
      // 饲喂数据(weight属性或略)
      feedingData: {},
      // 测定数据(targetAmount属性忽略)
      measureData: {},
      // 洗消监控器数据
      showerRoomData: {},
    };
  },
  computed: {
    rouLiaoBi() {
      return this.measureData?.amount
        ? (
          (this.measureData?.weight || 0) / (this.measureData?.amount || 0)
        ).toFixed(2) + ":1"
        : "--";
    },
  },

  methods: {
    scaleEvn() {
      let scale;
      if (
        this.$refs.envBox.clientWidth / this.$refs.envBox.clientHeight >
        this.$refs.env.clientWidth / this.$refs.env.clientHeight
      ) {
        scale = this.$refs.envBox.clientHeight / this.$refs.env.clientHeight;
      } else {
        scale = this.$refs.envBox.clientWidth / this.$refs.env.clientWidth;
      }

      this.scale = scale > 0.5 ? scale : 0.5;
    },
    createOption1() {
      const color = ["#78c03e", "#4d81e8", "#4a5178"];
      const _this = this;
      const percent = this.feedingData?.targetAmount
        ? (
          (this.feedingData?.amount || 0) /
          (this.feedingData?.targetAmount || 0)
        ).toFixed(2) *
        100 +
        "%"
        : "0%";
      return {
        color,
        textStyle: {
          fontSize: 16,
          rich: {
            a: {
              color: "#CEDFF5",
            },
            b: {
              color: "#54F244",
            },
          },
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          icon: "circle",
          bottom: 10,
          formatter: function (name) {
            let value = null;
            if (name === "采食量") {
              value = _this.feedingData?.amount || 0;
            } else if (name === "分配量") {
              value = _this.feedingData?.targetAmount || 0;
            }
            return `{a|${name}} {b|${value}}`;
          },
          textStyle: {
            color: "#B4C0D3",
          },
          itemWidth: 10,
          itemHeight: 10,
        },

        series: [
          {
            // name: "Access From",
            top: -30,
            type: "pie",
            radius: ["44%", "65%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              rich: {
                totalText: {
                  fontSize: 38,
                  color: "#fff",
                },
                active: {
                  fontSize: 18,
                  color: "#fff",
                  lineHeight: 40,
                  padding: [5, 6, 5, 6],
                  width: 70,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  boxShadow: "0px 1px 0px 0px rgba(0,0,0,0.39)",
                  opacity: 0.95,
                  borderRadius: 5,
                },
              },

              formatter:
                "{totalText|" + percent + "}" + "\n\r" + "{active|完食率}",
              // formatter: function (params) {
              //   let str = "";
              //   str += `<div style="color: red">111</div>`;
              //   return str;
              // },

              color: "#fff",
              position: "center",
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: "40",
            //     fontWeight: "bold",
            //   },
            // },
            labelLine: {
              show: false,
            },
            data: [
              { value: this.feedingData?.amount || 0, name: "采食量" },
              { value: this.feedingData?.targetAmount || 0, name: "分配量" },
            ],
          },
        ],
      };
    },

    createOption3() {
      const color = ["#78c03e", "#4d81e8", "#4a5178"];
      const _this = this;
      const percent = _this.doorData?.card_times
        ? (
          (_this.doorData?.success_times || 0) /
          (_this.doorData?.card_times || 0)
        ).toFixed(2) *
        100 +
        "%"
        : "0%";

      return {
        color,
        textStyle: {
          fontSize: 16,
          rich: {
            a: {
              color: "#CEDFF5",
            },
            b: {
              color: "#54F244",
            },
          },
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          icon: "circle",
          bottom: 10,
          formatter: function (name) {
            let value = null;
            if (name === "总数") {
              value = _this.doorData?.card_times || 0;
            } else if (name === "合格人数") {
              value = _this.doorData?.success_times || 0;
            }
            return `{a|${name}} {b|${value}}`;
          },
          textStyle: {
            color: "#B4C0D3",
          },
          itemWidth: 10,
          itemHeight: 10,
        },

        series: [
          {
            // name: "Access From",
            top: -30,
            type: "pie",
            radius: ["44%", "65%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              rich: {
                totalText: {
                  fontSize: 38,
                  color: "#fff",
                },
                active: {
                  fontSize: 18,
                  color: "#fff",
                  lineHeight: 40,
                  padding: [5, 6, 5, 6],
                  width: 70,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  boxShadow: "0px 1px 0px 0px rgba(0,0,0,0.39)",
                  opacity: 0.95,
                  borderRadius: 5,
                },
              },

              formatter:
                "{totalText|" + percent + "}" + "\n\r" + "{active|合格率}",
              // formatter: function (params) {
              //   let str = "";
              //   str += `<div style="color: red">111</div>`;
              //   return str;
              // },

              color: "#fff",
              position: "center",
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: "40",
            //     fontWeight: "bold",
            //   },
            // },
            labelLine: {
              show: false,
            },
            data: [
              { value: this.doorData?.card_times || 0, name: "总数" },
              { value: this.doorData?.success_times || 0, name: "合格人数" },
            ],
          },
        ],
      };
    },
    createOption4() {
      const _this = this;
      const total =
        _this.showerRoomData?.work_amount +
        _this.showerRoomData?.free_amount +
        _this.showerRoomData?.offline_amount;
      const percent = total
        ? (
          (_this.showerRoomData?.work_amount +
            _this.showerRoomData?.free_amount) /
          total
        ).toFixed(2) *
        100 +
        "%"
        : "--";
      const color = ["#78c03e", "#d7d7d7", "#f15455"];
      return {
        color,
        textStyle: {
          fontSize: 16,
          rich: {
            a: {
              color: "#CEDFF5",
            },
            b: {
              color: "#54F244",
            },
          },
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          icon: "circle",
          bottom: 10,
          formatter: function (name) {
            let value = null;
            if (name === "在用") {
              value = _this.showerRoomData?.work_amount || 0;
            } else if (name === "空闲") {
              value = _this.showerRoomData?.free_amount || 0;
            } else {
              value = _this.showerRoomData?.offline_amount || 0;
            }
            return `{a|${name}} {b|${value}}`;
          },
          textStyle: {
            color: "#B4C0D3",
          },
          itemWidth: 10,
          itemHeight: 10,
        },

        series: [
          {
            // name: "Access From",
            top: -30,
            type: "pie",
            radius: ["44%", "65%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              rich: {
                totalText: {
                  fontSize: 38,
                  color: "#fff",
                },
                active: {
                  fontSize: 18,
                  color: "#fff",
                  lineHeight: 40,
                  padding: [5, 6, 5, 6],
                  width: 70,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  boxShadow: "0px 1px 0px 0px rgba(0,0,0,0.39)",
                  opacity: 0.95,
                  borderRadius: 5,
                },
              },

              formatter:
                "{totalText|" + percent + "}" + "\n\r" + "{active|正常率}",
              // formatter: function (params) {
              //   let str = "";
              //   str += `<div style="color: red">111</div>`;
              //   return str;
              // },

              color: "#fff",
              position: "center",
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: "40",
            //     fontWeight: "bold",
            //   },
            // },
            labelLine: {
              show: false,
            },
            data: [
              { value: this.showerRoomData.work_amount || 0, name: "在用" },
              { value: this.showerRoomData.free_amount || 0, name: "空闲" },
              { value: this.showerRoomData.offline_amount || 0, name: "离线" },
            ],
          },
        ],
      };
    },
    async getEnergyHistoryForChart(type) {
      let start_date = dayjs(this.form.date[0]).format("YYYY-MM-DD"),
        end_date = null;

      if (type) {
      }

      console.log(start_date, end_date);
      // try {
      //   let res = await this.$myRequest({
      //     url: `/api/tenant/energy/${this.projectid}/history`,
      //     data: {
      //       assetid: this.form.danId || this.form.sheId,
      //       projectid: this.projectid,
      //       start_date,
      //       end_date,
      //       device_profile: "electric",
      //     },
      //   });
      //   this.xAxis = res.data.body.map((i) => dayjs(i.time).format("MM-DD"));
      //   this.electricData = res.data.body.map((i) => i?.electric?.total);
      //   res.data.body.reverse();
      //   res.data.body.forEach((i, index) => (i.order = index + 1));
      //   this.electricData = res.data.body;
      // } catch (e) {
      //   console.log(e);
      // }
    },
    createOption7() {
      const color = ["#78c03e", "#e2838f"];
      return {
        color,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#31c4f6",
            },
          },
          // backgroundColor: "rgba(3, 117, 139, 0.7)",
          // borderColor: "rgba(0, 213, 255, 1)",
          // textStyle: {
          //   color: "#fff",
          // },
        },
        legend: {
          icon: "roundRect",
          // right: 20,
          textStyle: {
            fontSize: 16,
            color: "#B4C0D3",
          },
          itemGap: 40,
          itemWidth: 34,
          itemHeight: 14,
        },
        yAxis: [
          {
            type: "value",
            name: "度",
            axisLine: {
              // symbolOffset: [0, 20],

              // onZero: false,
              show: true,
              lineStyle: {
                //利用阴影进行延长
                shadowOffsetY: 20,
                color: "#B1B2B5",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#AAAAAA",
                opacity: 0.36,
              },
            },
            nameTextStyle: {
              fontSize: 16,
              color: "#E8F6F8",
            },
          },
          {
            type: "value",
            name: "吨",
            axisLine: {
              onZero: false,
              show: true,
              lineStyle: {
                color: "#B1B2B5",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#AAAAAA",
                opacity: 0.36,
              },
            },
            nameTextStyle: {
              fontSize: 16,
              color: "#E8F6F8",
            },
          },
        ],
        grid: {
          left: 20,
          bottom: 20,
          right: 20,
          top: 60,
          containLabel: true,
          show: true,
          backgroundColor: "#0E2454",
          borderColor: "transparent",
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#B1B2B5",
              },
            },
            nameTextStyle: {
              // fontSize: 18,
              color: "#E8F6F8",
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return params.value;
                },
              },
            },
            data: this.energyXAxis,
          },
        ],
        series: [
          {
            name: "用电",
            data: this.energyElectric,
            type: "line",
            smooth: true,
            yAxisIndex: 0,
          },
          {
            name: "用水",
            data: this.energyWater,
            type: "line",
            smooth: true,
            yAxisIndex: 1,
          },
        ],
      };
    },
    formatEnergy() {
      this.energyData.forEach((i) => {
        this.energyXAxis.push(dayjs(i.time).format("HH:mm"));
        this.energyWater.push(i.water?.total || 0);
        this.energyElectric.push(i.electric?.total || 0);
      });
    },
    async getData() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/home/${this.projectid}/datas`,
        });

        res = res.data.body;
        this.deviceData = res.device_data;
        this.doorData = res.door_data;
        this.ecData = res.ec_data;
        this.energyData = res.energy_data;
        this.feedingData = res.feeding_data;
        this.measureData = res.measure_data;
        this.showerRoomData = res.shower_room_data;
        this.formatEnergy();
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.getData();
    // window.xxx = this;
    // this.scaleEvn();
    // window.addEventListener("resize", this.scaleEvn);
  },
};
</script>

<style scoped lang="less" src="./index.less"></style>
