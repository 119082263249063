<template>
  <div class="global-box alarm-video">
    <div class="video">
      <el-form :inline="true" :model="form2" class="chartTime global-form global-form-inline">
        <el-form-item label="时间">
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="&nbsp;">
          <el-radio-group v-model="type">
            <el-radio :label="1">高度</el-radio>
            <el-radio :label="2">轻微</el-radio>
            <el-radio :label="3">所有</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="video-list">
        <div class="video-item">
          <div class="video-img-box">
            <img src="./img/video.png" />
          </div>
          <div class="txt-box">
            <div class="txt">位置：外围监控-08</div>
            <div class="txt">事件：区域入侵</div>
            <div class="txt">时间：2021-9-8</div>
          </div>
        </div>
        <div class="video-item">
          <div class="video-img-box">
            <img src="./img/video.png" />
          </div>
          <div class="txt-box">
            <div class="txt">位置：外围监控-08</div>
            <div class="txt">事件：区域入侵</div>
            <div class="txt">时间：2021-9-8</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Page",
  components: {},
  data() {
    return {
      type: 1,
      tableData: [],
      page: 1,
      pageSize: 10,
    };
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped lang="less" src="./index.less">
</style>
