<template>
  <div class="home-global-right">
    <div class="con-global-right"><router-view /></div>

    <div class="nav-global-right">
      <el-menu
        router
        :default-active="$route.name"
        class="nav-menu-global-right"
        mode="horizontal"
        @select="handleSelect"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowWeightIndex"
          :route="{
            name: 'MeadowWeightIndex',
            params: { id: $route.params.id },
          }"
          >实时监控</el-menu-item
        >
        <el-menu-item
          disabled
          class="nav-item-global-right"
          index="MeadowWeightAdd"
          :route="{
            name: 'MeadowWeightAdd',
            params: { id: $route.params.id },
          }"
          >料仓加料统计</el-menu-item
        >
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
};
</script>
<style scoped lang="less" src="../../feed/home/index.less">
</style>
