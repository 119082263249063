<template>
  <div class="baojing padding10">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: 'MeadowIndex' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ name: 'MeadowSmartFeedIndex' }"
            >智能饲喂站</el-breadcrumb-item
          >
          <el-breadcrumb-item>报警记录</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs>
          <el-tab-pane label="报警记录">
            <el-card>
              <el-table class="table1" :data="tableData" border stripe>
                <el-table-column prop="t1" label="记录"> </el-table-column>
                <el-table-column prop="t2" label="名称"> </el-table-column>
                <el-table-column prop="t3" label="电子耳牌"> </el-table-column>
                <el-table-column prop="t4" label="群"> </el-table-column>
                <el-table-column prop="t5" label="站"> </el-table-column>
                <el-table-column prop="t6" label="信息"> </el-table-column>
                <el-table-column prop="t7" label="编"> </el-table-column>
                <el-table-column prop="t8" label="原因"> </el-table-column>
                <el-table-column prop="t9" label="时间"> </el-table-column>
                <el-table-column prop="t10" label="是"> </el-table-column>
              </el-table>
            </el-card>
          </el-tab-pane>
          <el-tab-pane label="设置" disabled>xx</el-tab-pane>
          <el-tab-pane label="服务日志" disabled>xx</el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  methods: {},
  data() {
    return {
      tableData: [
        {
          t1: "253",
          t2: "3897",
          t3: "100409309",
          t4: "6",
          t5: "6",
          t6: "0",
          t7: "16",
          t8: "无通讯连接",
          t9: "2021/4/16 18:56:56",
          t10: "",
        },
        {
          t1: "254",
          t2: "----",
          t3: "0",
          t4: "14",
          t5: "14",
          t6: "14",
          t7: "26",
          t8: "未能识别动物",
          t9: "2021/4/16 18:56:56",
          t10: "",
        },
      ],
      form: {
        resource: "",
        type: [],
      },
    };
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
