<template>
  <div class="global-box">
    <el-form
      :inline="true"
      :model="form"
      class="global-form global-form-inline"
    >
      <el-form-item>
        <el-select v-model="form.xiaodu" placeholder="消毒间">
          <el-option label="全部消毒间" value="1" />
          <el-option label="消毒间1" value="2" />
          <el-option label="消毒间2" value="3" />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-select v-model="form.status" placeholder="消毒模式">
          <el-option label="全部消毒模式" value="1"></el-option>
          <el-option label="熏蒸" value="2"></el-option>
          <el-option label="浸泡" value="3"></el-option>
          <el-option label="烘干" value="4"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-select v-model="form.region" placeholder="开门模式">
          <el-option label="全部开门模式" value="1" />
          <el-option label="正常开门" value="2" />
          <el-option label="强制开门" value="3" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.region" placeholder="消毒结果">
          <el-option label="全部消毒结果" value="1" />
          <el-option label="合格" value="2" />
          <el-option label="不合格" value="3" />
          <el-option label="无结果" value="4" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="form.value1"
          type="daterange"
          range-separator="至"
          start-placeholder="消毒时间"
          end-placeholder="消毒时间"
        />
      </el-form-item>

      <el-form-item>
        <span class="btn-reset" @click="onReset" style="margin-right: 10px"
          >重置</span
        >
        <span class="btn-search" @click="onSubmit">查询</span>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      class="global-box-table"
      stripe
      border
      height="100"
    >
      <el-table-column prop="index" label="序号"> </el-table-column>
      <el-table-column prop="room" label="所选消毒间" min-width="100px">
      </el-table-column>
      <el-table-column
        prop="type"
        label="消毒模式"
        min-width="80px"
      ></el-table-column>
      <el-table-column
        prop="startTime"
        label="开始消毒时间"
        min-width="180px"
        :formatter="formatterNull"
      ></el-table-column>
      <el-table-column
        prop="duration"
        label="消毒时长"
        min-width="100px"
      ></el-table-column>
      <el-table-column
        prop="xiaoPerson"
        label="消毒操作人"
        width="100px"
      ></el-table-column>
      <el-table-column label="开门模式">
        <template #default="scoped">
          <span class="green" :class="{ red: scoped.row.mode != '正常开门' }">{{
            scoped.row.mode
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="openPerson"
        label="开门操作人"
        width="100px"
      ></el-table-column>
      <el-table-column
        prop="openTime"
        min-width="180px"
        label="开门时间"
      ></el-table-column>
      <el-table-column prop="result" label="消毒结果" width="100px">
        <template #default="scoped">
          <span class="green" :class="{ red: scoped.row.result != '合格' }">{{
            scoped.row.result
          }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { formatterNull, formatterTimeSt } from "@/util/formatter.js";
import basil from "@/util/basil";
import moment from "moment";
import { data } from "./data";
export default {
  name: "WashList",
  components: {},
  data() {
    return {
      projectid: basil.get("projectid"),
      form: {},
      tableData: data,
    };
  },
  mounted() {},
  methods: {
    formatterNull,
    formatterTimeSt,
    formatterSt(row, column) {
      return moment(row.create_time).format("YYYY-MM-DD HH:mm:ss");
    },
    formatterEt(row, column) {
      return moment(row.latest_time).format("YYYY-MM-DD HH:mm:ss");
    },
    onReset() {
      this.form = {};
    },
    onSubmit() {},
    handleClose() {
      this.dialogVisible = false;
    },
    openDetai(row) {
      console.log(row);
      this.detailInfo = row;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped lang="less" src="./index.less"></style>
