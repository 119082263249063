<template>
  <div class="global-box">
    <div class="padding20" style="padding-left: 0px">
      <el-button type="primary" @click="add()">新增</el-button>
    </div>
    <el-table height="100" class="global-box-table" :data="tableData" stripe border>
      <el-table-column prop="name" label="姓名"> </el-table-column>
      <el-table-column prop="phone" label="手机号码"> </el-table-column>
      <el-table-column prop="responsibility" label="工作职责">
      </el-table-column>

      <el-table-column label="操作" width="180%">
        <template #default="scope">
          <el-button size="mini" type="danger" @click="remove(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="totalSize > pageSize" class="padding20">
      <el-pagination background layout="prev, pager, next" v-if="tableData.length" :total="totalSize"
        :page-size="pageSize">
      </el-pagination>
    </div>

    <el-dialog title="新增" v-model="dialogVisible" width="600rem" :close-on-click-modal="false">
      <el-form v-if="dialogVisible" ref="form" :model="form" :rules="rules" label-width="130rem" class="global-form">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="image_code">
          <el-row>
            <el-col :span="16"><el-input @focus="imageCodeFocus" v-model="form.image_code"></el-input></el-col>
            <el-col :span="2"></el-col>
            <el-col :span="6">
              <imgCode></imgCode>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="短信验证码" prop="phone_code">
          <el-row>
            <el-col :span="16"><el-input v-model="form.phone_code" @focus="phoneCodeFocus"></el-input></el-col>
            <el-col :span="2"></el-col>
            <el-col :span="6">
              <el-button class="global-sendCode" type="primary" @click="sendCode">发送</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="工作职责">
          <el-input v-model="form.responsibility"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessageBox, ElMessage } from "element-plus";
import basil from "@/util/basil";
import imgCode from "../../../components/img-code";
import { checkPhone } from "@/util/rules";

export default {
  name: "Page",
  components: { imgCode },
  data() {
    const imgErr = (rule, value, callback) => {
      if (this.image_code_err) {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };
    const phoneCodeErr = (rule, value, callback) => {
      if (this.phone_code_err) {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    };
    return {
      projectid: basil.get("projectid"),
      phone_code_err: false,
      image_code_err: false,
      dialogVisible: false,
      type: 0, //
      // tableHeight: document.documentElement.clientHeight * 0.75,
      tableData: [],
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "必填",
          },
        ],

        image_code: [
          {
            required: true,
            message: "必填",
          },
          { validator: imgErr },
        ],
        phone: [
          {
            required: true,
            message: "必填",
          },
          { validator: checkPhone },
        ],
        phone_code: [
          {
            required: true,
            message: "必填",
          },
          { validator: phoneCodeErr },
        ],
      },
      codeErr: false,
      page: 1,
      pageSize: 10,
      totalSize: 0,
    };
  },
  methods: {
    phoneCodeFocus() {
      this.phone_code_err = false;
    },
    imageCodeFocus() {
      this.image_code_err = false;
    },
    sendCode() {
      console.log("sendCode");
      // console.log(this.$refs.form.validateField);
      // this.image_code_err = true;
      this.$refs.form.validateField("phone", (phoneErrMsg) => {
        this.$refs.form.validateField("image_code", (imageErrMsg) => {
          console.log("validateField");
          console.log(phoneErrMsg);
          console.log(imageErrMsg);
          if (phoneErrMsg == "" && imageErrMsg == "") {
            this.$myRequest({
              url: "/api/tenant/global/code/phone/" + this.form.phone,
              data: {
                image_code: this.form.image_code,
                phone: this.form.phone,
                check_registry: false,
              },
            })
              .then((res) => {
                console.log("phone res");
                console.log(res);
                this.$message("发送成功");
                //
              })
              .catch((res) => {
                console.log("phone err");
                console.log(res);
                if (res.data.status == -113) {
                  this.image_code_err = true;
                  this.$refs.form.validateField("image_code");
                } else {
                  this.$message(res.data.status_detail);
                }
              });
          }
        });
      });
    },

    close() {
      this.dialogVisible = false;
      this.type = 0;
      this.form = {
        id: "",
        name: "",
        image_code: "",
        phone: "",
        phone_code: "",
        responsibility: "",
      };
    },
    remove(i, row) {
      ElMessageBox.confirm("确定删除?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$myRequest({
            method: "delete",
            url:
              `/api/tenant/project/contacts/${this.projectid}/delete/` + row.id,
          })
            .then((res) => {
              this.getList();
            })
            .catch((res) => {
              this.$message(res.data?.status_detail);
            });
        })
        .catch(() => { });
      // console.log(row);
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.type == 0) {
            this.$myRequest({
              url: `/api/tenant/project/contacts/${this.projectid}/add`,
              method: "post",
              data: this.form,
            })
              .then((res) => {
                this.getList();
                this.close();
              })
              .catch((res) => {
                // this.$message("添加失败");
                if (res.data.status == -113 || res.data.status == -112) {
                  this.phone_code_err = true;
                  this.$refs.form.validateField("phone_code");
                } else {
                  this.$message(res.data.status_detail);
                }
              });
          }
        }
      });
    },
    add() {
      this.form = {};
      this.type = 0;
      this.dialogVisible = true;
    },
    edit(index, row) {
      // console.log(row);
      this.type = 1;
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogVisible = true;
    },
    getList() {
      this.$myRequest({
        url: `/api/tenant/project/contacts/${this.projectid}/list`,
        data: {
          page: this.page,
          pageSize: this.pageSize,
        },
      })
        .then((res) => {
          this.tableData = res.data.body.datas;
          this.page = res.data.body.page;
          this.pageSize = res.data.body.pageSize;
          this.totalSize = res.data.body.totalSize;

          //
        })
        .catch((res) => {
          this.$message(res.data.status_detail);
        });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
