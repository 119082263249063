<template>
  <div class="liquid-allot padding10">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: 'MeadowIndex' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ name: 'MeadowLiquidIndex' }"
            >液态料</el-breadcrumb-item
          >
          <el-breadcrumb-item>饲料分配</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs>
          <el-tab-pane label="饲喂曲线">
            <el-row :gutter="20">
              <el-col :span="15">
                <el-card class="box-card">
                  <template #header>
                    <el-form
                      :inline="true"
                      :model="formInline"
                      class="demo-form-inline global-form global-form-inline"
                    >
                      <el-form-item label="">
                        <el-select v-model="formInline.s1" placeholder="">
                          <el-option label="料1" value="xxx1"></el-option>
                          <el-option label="料2" value="xxx2"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="">
                        <el-select v-model="formInline.s2" placeholder="">
                          <el-option label="料1" value="xxx1"></el-option>
                          <el-option label="料2" value="xxx2"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="">
                        <el-checkbox-group v-model="formInline.type">
                          <el-checkbox
                            label="喂食曲线用于繁育"
                            name="type"
                          ></el-checkbox>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-form>
                  </template>
                  <el-table
                    class="table1"
                    :data="tableData"
                    border
                    style="width: 100%"
                  >
                    <el-table-column prop="tag" label="日"> </el-table-column>
                    <el-table-column prop="gew" label="体重[kg]">
                    </el-table-column>
                    <el-table-column prop="e" label="料能[兆]">
                    </el-table-column>
                    <el-table-column prop="r1" label="配方1"> </el-table-column>
                    <el-table-column prop="r2" label="配方2"> </el-table-column>
                    <el-table-column prop="br1" label="%方1"> </el-table-column>
                    <el-table-column prop="br2" label="%方2"> </el-table-column>
                    <el-table-column prop="eiwei" label="蛋白">
                    </el-table-column>
                    <el-table-column prop="wasser" label="喂水量">
                    </el-table-column>
                  </el-table>
                </el-card>
              </el-col>
              <el-col :span="9">
                <el-card class="box-card">
                  <template #header> Futterkurvenwechsel </template>
                  <el-table :data="tableData2" border style="width: 100%">
                    <el-table-column prop="ab" label="起始动物重">
                    </el-table-column>
                    <el-table-column prop="gruppe" label="群组">
                    </el-table-column>
                    <el-table-column prop="von" label="老饲喂曲线">
                    </el-table-column>
                    <el-table-column prop="nene" label="新饲喂曲线">
                    </el-table-column>
                  </el-table>
                </el-card>
                <el-card class="box-card">
                  <template #header> 能量/动物体重图 </template>
                  <div id="tizhong" class="tizhongtu"></div>
                </el-card>
                <el-card class="box-card">
                  <template #header> 蛋白质/赖氨酸与动物关系图</template>
                  <el-tabs @tab-click="handleClick">
                    <el-tab-pane label="蛋白质">
                      <div id="danbaizhi" class="tizhongtu"></div>
                    </el-tab-pane>
                    <el-tab-pane label="赖氨酸">
                      <div id="laiansuan" class="tizhongtu"></div>
                    </el-tab-pane>
                  </el-tabs>
                </el-card>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="群栏" disabled>信息</el-tab-pane>
          <el-tab-pane label="存栏动物" disabled>服务</el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { BarChart, LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
]);
export default {
  name: "Page",
  components: {},
  data() {
    return {
      tableData2: [],
      tableData: [
        {
          tag: "1",
          gew: "40",
          e: "0.8",
          r1: "料1",
          r2: "----",
          br1: "100",
          br2: "0",
          eiwei: "0",
          wasser: "0",
        },
        {
          tag: "15",
          gew: "45",
          e: "0.8",
          r1: "料1",
          r2: "----",
          br1: "100",
          br2: "0",
          eiwei: "0",
          wasser: "0",
        },
        {
          tag: "21",
          gew: "55",
          e: "1",
          r1: "料1",
          r2: "----",
          br1: "100",
          br2: "0",
          eiwei: "0",
          wasser: "0",
        },
        {
          tag: "25",
          gew: "60",
          e: "1.2",
          r1: "料1",
          r2: "----",
          br1: "100",
          br2: "0",
          eiwei: "0",
          wasser: "0",
        },
        {
          tag: "32",
          gew: "65",
          e: "1.5",
          r1: "料1",
          r2: "----",
          br1: "100",
          br2: "0",
          eiwei: "0",
          wasser: "0",
        },
      ],
      formInline: {
        user: "",
        region: "",
        type: [],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.setTizhong();
      this.setDb();
      this.setLa();
    }, 0);
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab.index);
      if (tab.index == 0) {
        setTimeout(() => {
          this.dbChart.resize();
        }, 0);
      } else {
        setTimeout(() => {
          this.laChart.resize();
        }, 0);
      }
    },
    setTizhong() {
      let dom = document.getElementById("tizhong");
      let chart = echarts.init(dom);
      let option;
      option = {
        grid: {
          left: 10,
          bottom: 15,
          right: 10,
          top: 10,
          // width: 470,
          // height: 90,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "40",
            "45",
            "50",
            "55",
            "60",
            "65",
            "70",
            "75",
            "80",
            "85",
            "90",
          ],
        },
        yAxis: {
          scale: true,
          type: "value",
        },
        series: [
          {
            data: [0.8, 0.88, 1.2, 1.3, 1.5, 1.8, 1.9, 2, 2.2, 2.4, 2.5],
            type: "line",
            areaStyle: {},
          },
        ],
      };
      chart.setOption(option);
      this.tizhongChart = chart;
    },
    setDb() {
      let dom = document.getElementById("danbaizhi");
      let chart = echarts.init(dom);
      let option;
      option = {
        grid: {
          left: 10,
          bottom: 15,
          right: 10,
          top: 10,
          // width: 470,
          // height: 90,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["", "", "", "", "", "", ""],
        },
        yAxis: {
          scale: true,
          type: "value",
        },
        series: [
          {
            data: [0, 0, 0, 0, 0, 0, 0],
            type: "line",
            areaStyle: {},
          },
        ],
      };
      chart.setOption(option);
      this.dbChart = chart;
    },
    setLa() {
      let dom = document.getElementById("laiansuan");
      let chart = echarts.init(dom);
      let option;
      option = {
        grid: {
          left: 10,
          bottom: 15,
          right: 10,
          top: 10,
          // width: 470,
          // height: 90,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["", "", "", "", "", "", ""],
        },
        yAxis: {
          scale: true,
          type: "value",
        },
        series: [
          {
            data: [0, 0, 0, 0, 0, 0, 0],
            type: "line",
            areaStyle: {},
          },
        ],
      };
      chart.setOption(option);
      this.laChart = chart;
    },
  },
  beforeUnmount() {
    this.tizhongChart.dispose();
    this.dbChart.dispose();
    this.laChart.dispose();

    this.tizhongChart = null;
    this.dbChart = null;
    this.laChart = null;
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
<style lang="less">
.liquid-allot {
  .el-select {
    // width: 60px;
  }
}
</style>
