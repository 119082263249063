<template>
  <div class="jilu padding10">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: 'MeadowIndex' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ name: 'MeadowSmartFeedIndex' }"
            >智能饲喂站</el-breadcrumb-item
          >
          <el-breadcrumb-item>饲喂记录</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs>
          <el-tab-pane label="报告">
            <el-card class="box-card">
              <el-table class="table1" :data="tableData" border>
                <el-table-column prop="t1" label="条"></el-table-column>
                <el-table-column prop="t2" label="名称"> </el-table-column>
                <el-table-column prop="t3" label="群"> </el-table-column>
                <el-table-column prop="t4" label="站"> </el-table-column>
                <el-table-column prop="t5" label="饲料1"> </el-table-column>
                <el-table-column prop="t6" label="饲料2"> </el-table-column>
                <el-table-column prop="t7" label="分离栏"> </el-table-column>
                <el-table-column prop="t8" label="颜色标"> </el-table-column>
                <el-table-column prop="t9" label="添加"> </el-table-column>
                <el-table-column prop="t10" label="训练"> </el-table-column>
                <el-table-column prop="t11" label="先后顺序"> </el-table-column>
                <el-table-column prop="t12" label="进入时间"> </el-table-column>
                <el-table-column prop="t13" label="离开"> </el-table-column>
              </el-table>
            </el-card>
          </el-tab-pane>
          <el-tab-pane label="tagesverbrauch" disabled>xx</el-tab-pane>
          <el-tab-pane label="采食有剩的动物" disabled>xx</el-tab-pane>
          <el-tab-pane label="总采食量" disabled>xx</el-tab-pane>
          <el-tab-pane label="设置" disabled>xx</el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  methods: {},
  data() {
    return {
      tableData: [
        {
          t1: "1353",
          t2: "Y0915",
          t3: "14",
          t4: "14",
          t5: "1.2",
          t6: "0",
          t7: "0",
          t8: "0",
          t9: "",
          t10: "",
          t11: "0",
          t12: "2021/4/20 18:04:34",
          t13: "18:12:22",
        },
        {
          t1: "1352",
          t2: "D0043",
          t3: "2",
          t4: "2",
          t5: "1.5",
          t6: "0",
          t7: "0",
          t8: "0",
          t9: "",
          t10: "",
          t11: "0",
          t12: "2021/4/20 17:54:26",
          t13: "18:02:58",
        },
      ],
      form: {
        resource: "",
        type: [],
      },
    };
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
