<template>
  <div class="home-global-right">
    <div class="con-global-right"><router-view /></div>

    <div class="nav-global-right">
      <el-menu
        router
        :default-active="$route.name"
        class="nav-menu-global-right"
        mode="horizontal"
        @select="handleSelect"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowLiquidIndex"
          :route="{
            name: 'MeadowLiquidIndex',
            params: { id: $route.params.id },
          }"
          >F1 液态料</el-menu-item
        >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowLiquidAllot"
          :route="{
            name: 'MeadowLiquidAllot',
            params: { id: $route.params.id },
          }"
          >F2 饲料分配</el-menu-item
        >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowLiquidXiaohao"
          :route="{
            name: 'MeadowLiquidXiaohao',
            params: { id: $route.params.id },
          }"
          >F3 配料消耗</el-menu-item
        >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowLiquidBaojing"
          :route="{
            name: 'MeadowLiquidBaojing',
            params: { id: $route.params.id },
          }"
          >F4 报警记录</el-menu-item
        >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowLiquidLanwei"
          :route="{
            name: 'MeadowLiquidLanwei',
            params: { id: $route.params.id },
          }"
          >F5 栏位</el-menu-item
        >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowLiquidPeiliao"
          :route="{
            name: 'MeadowLiquidPeiliao',
            params: { id: $route.params.id },
          }"
          >F6 配料</el-menu-item
        >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowLiquidPeifang"
          :route="{
            name: 'MeadowLiquidPeifang',
            params: { id: $route.params.id },
          }"
          >F7 配方</el-menu-item
        >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowLiquidMeiri"
          :route="{
            name: 'MeadowLiquidMeiri',
            params: { id: $route.params.id },
          }"
          >F8 每日流程</el-menu-item
        >
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
};
</script>
<style scoped lang="less" src="../../feed/home/index.less">
</style>
