<template>
  <div class="global-home">
    <div class="global-header-bg"></div>
    <div class="global-header">
      <div class="global-logo1"></div>
      <Project></Project>
      <!-- <Time></Time> -->
      <Warning></Warning>
      <User></User>

      <!-- <div class="global-logo2"></div> -->
    </div>
    <div class="global-nav">
      <!-- <div class="global-nav-title">
        <div class="global-nav-title-txt">{{ projects.text }}</div>
      </div> -->

      <slot name="nav"></slot>
    </div>
    <div class="global-con">
      <slot name="con"></slot>
    </div>
  </div>
</template>

<script>
import Project from "../../components/project/index.vue";
import Time from "../../components/time/index.vue";
import Warning from "../../components/warning/index.vue";
import User from "../../components/user/index.vue";
export default {
  name: "Home",
  components: { Project, Time, Warning, User },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  props: {
    // projects: {
    //   type: String,
    //   value: "0",
    //   text: "",
    // },
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
