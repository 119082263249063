<template>
  <div class="xxx">feed param</div>
</template>

<script>

export default {
  name: "Page",
  components: {
    
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
