<template>
  <div class="global-box">
    <el-form
      :inline="true"
      :model="form"
      class="global-form global-form-inline"
    >
      <el-form-item>
        <el-input v-model="form.name" placeholder="姓名" />
      </el-form-item>

      <el-form-item>
        <el-input v-model="form.gonghao" placeholder="手机号" />
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.type" placeholder="类型">
          <el-option label="全部类型" value="1" />
          <el-option label="内部员工" value="2" />
          <el-option label="外部访客" value="3" />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-select v-model="form.status" placeholder="状态">
          <el-option label="全部状态" value="1"></el-option>
          <el-option label="通过" value="2"></el-option>
          <el-option label="拒绝" value="3"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-select v-model="form.result" placeholder="结果">
          <el-option label="全部结果" value="1" />
          <el-option label="阴性" value="2" />
          <el-option label="阳性" value="3" />
          <el-option label="未知" value="4" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="form.value1"
          type="daterange"
          range-separator="至"
          start-placeholder="申请时间"
          end-placeholder="申请时间"
        />
      </el-form-item>

      <el-form-item>
        <span class="btn-reset" @click="onReset" style="margin-right: 10px"
          >重置</span
        >
        <span class="btn-search" @click="onSubmit">查询</span>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      class="global-box-table"
      stripe
      border
      height="100"
    >
      <el-table-column prop="index" label="序号" width="60px">
      </el-table-column>
      <el-table-column prop="name" label="姓名" width="80px"> </el-table-column>
      <el-table-column
        prop="idCard"
        label="身份证号"
        min-width="150px"
        :formatter="formatterNull"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="手机号"
        min-width="120px"
        :formatter="formatterNull"
      ></el-table-column>
      <el-table-column
        prop="enterTime"
        min-width="120px"
        label="入场时间"
      ></el-table-column>
      <el-table-column
        prop="type"
        label="申请人类型"
        width="100px"
      ></el-table-column>
      <el-table-column prop="applyStatus" label="申请状态" min-width="80px">
        <template #default="scoped">
          <span
            class="green"
            :class="{ red: scoped.row.applyStatus != '通过' }"
            >{{ scoped.row.applyStatus }}</span
          >
        </template></el-table-column
      >
      <el-table-column
        prop="entryStatus"
        label="入场状态"
        min-width="80px"
      ></el-table-column>
      <el-table-column
        prop="applyTime"
        label="提交申请时间"
        min-width="150px"
      ></el-table-column>
      <el-table-column label="操作" width="60px">
        <template #default="scope">
          <span @click.prevent="openDetai(scope.row)" class="detail">详情</span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :append-to-body="true"
      top="10vh"
    >
      <div class="xi">
        <div class="title">基本信息</div>
        <el-row gutter="20">
          <el-col :span="8">
            <div class="label">员工姓名</div>
            <div class="content">{{ detailInfo.name }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">身份证号</div>
            <div class="content">{{ detailInfo.idCard }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">手机号</div>
            <div class="content">{{ detailInfo.phone }}</div>
          </el-col>
        </el-row>
        <el-row gutter="20">
          <el-col :span="8">
            <div class="label">入场时间</div>
            <div class="content">{{ detailInfo.enterTime }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">申请人类型</div>
            <div class="content">{{ detailInfo.type }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">申请状态</div>
            <div class="content">{{ detailInfo.applyStatus }}</div>
          </el-col>
        </el-row>
        <el-row gutter="20">
          <el-col :span="8">
            <div class="label">入场状态</div>
            <div class="content">{{ detailInfo.entryStatus }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">提交申请时间</div>
            <div class="content">{{ detailInfo.applyTime }}</div>
          </el-col>
        </el-row>
        <div class="title" style="margin-top: 40px">洗消流程</div>
        <div class="flow">
          <div class="left">
            <img :src="require('../imgs/zhong.png')" />
            <span class="line"></span>
            <img
              v-if="detailInfo.step >= 2"
              :src="require('../imgs/zhong.png')"
            />
            <img v-else class="gray" :src="require('../imgs/zhong.png')" />
            <span class="line" :class="{ gray: detailInfo.step < 3 }"></span>
            <img
              v-if="detailInfo.step >= 3"
              :src="require('../imgs/zhong.png')"
            />
            <img v-else class="gray" :src="require('../imgs/zhong.png')" />
            <span class="line" :class="{ gray: detailInfo.step < 4 }"></span>
            <img
              v-if="detailInfo.step >= 4"
              :src="require('../imgs/zhong.png')"
            />
            <img v-else class="gray" :src="require('../imgs/zhong.png')" />
          </div>
          <div class="right">
            <div>
              <p>入场申请审核通过</p>
              <p>入场时间：2022-02-26 10：12：22</p>
            </div>
            <span></span>
            <div v-if="detailInfo.step >= 2">
              <p>采样完成</p>
              <p>采样结果：<span class="green">阴性</span></p>
            </div>
            <div v-else>待采样</div>
            <p></p>
            <span></span>
            <div v-if="detailInfo.step >= 3">
              <p>外勤隔离完成</p>
              <p>隔离时间：48小时</p>
            </div>
            <div v-else>
              <p>待勤隔离</p>
              <p></p>
            </div>
            <span></span>
            <div v-if="detailInfo.step >= 4">
              出洗消间时间：2022-02-26 10：12：22
            </div>
            <div v-else>
              <p>待厂区内隔离</p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatterNull } from "@/util/formatter.js";
import basil from "@/util/basil";
import moment from "moment";
import { data } from "./data";
export default {
  name: "WashList",
  components: {},
  data() {
    return {
      projectid: basil.get("projectid"),
      form: {},
      tableData: data,
      dialogVisible: false,
      dialogTitle: "人员详情",
      detailInfo: {},
    };
  },
  mounted() {},
  methods: {
    formatterNull,
    formatterSt(row, column) {
      return moment(row.create_time).format("YYYY-MM-DD HH:mm:ss");
    },
    formatterEt(row, column) {
      return moment(row.latest_time).format("YYYY-MM-DD HH:mm:ss");
    },
    onReset() {
      this.form = {};
    },
    onSubmit() {},
    handleClose() {
      this.dialogVisible = false;
    },
    openDetai(row) {
      console.log(row);
      this.detailInfo = row;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped lang="less" src="./index.less"></style>
