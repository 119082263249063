<template>
  <iframe class="frame" src="http://lf.muzhiyun.link/index.html"></iframe>
</template>

<script>
export default {
  data() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.frame {
  width: 100%;
  height: 100%;
}
</style>
