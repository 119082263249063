<template>
  <el-dialog
    v-model="visible"
    :title="title"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :width="'80%'"
    append-to-body
  >
    <div class="container">
      <div class="my-form">
        <el-form
          :inline="true"
          :model="form"
          class="demo-form-inline global-form global-form-inline"
        >
          <el-form-item>
            <el-date-picker
              v-model="form.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              :disabled-date="disabledDate"
            />
          </el-form-item>
          <el-form-item>
            <span class="btn-reset" @click="onReset" style="margin-right: 10px"
              >重置</span
            >
            <span class="btn-search" @click="onSubmit">查询</span>
          </el-form-item>
        </el-form>
      </div>
      <div class="tabs">
        <div
          @click="() => tabClick(0)"
          class="tab-item"
          :class="{ active: tabIndex == 0 }"
        >
          <span>温度曲线</span>
        </div>
        <div
          @click="() => tabClick(1)"
          class="tab-item"
          :class="{ active: tabIndex == 1 }"
        >
          <span>温度数据</span>
        </div>
      </div>

      <div class="tab-content">
        <v-chart
          v-show="tabIndex == 0"
          class="chart"
          autoresize
          :option="createOption()"
        />
        <el-table
          v-show="tabIndex == 1"
          :data="tableData"
          class="m-table"
          height="100%"
          stripe
          border
        >
          <el-table-column prop="order" label="序号" width="120px">
          </el-table-column>
          <el-table-column
            prop="time"
            label="时间"
            :formatter="formatterTimeSt2"
          >
          </el-table-column>
          <el-table-column
            prop="value"
            label="实际温度(℃)"
            :formatter="formatterNull"
          >
            <template #default="scope">
              {{ scope.row.values.temperature }}
            </template>
          </el-table-column>
          <el-table-column
            prop="data_value"
            label="目标温度(℃)"
            :formatter="formatterNull"
          >
            <template #default="scope">
              {{ scope.row.values.targetTemperature }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { formatterNull, formatterTimeSt2 } from "@/util/formatter.js";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
// import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { LineChart } from "echarts/charts";
import dayjs from "dayjs";
use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
]);

const colors = [
  // "#FF4C50",
  "#67C23A",
  "#FF9B78",
];
import basil from "@/util/basil";
export default {
  name: "Page",
  props: ["title", "visible", "uuid"],
  components: { VChart },
  data() {
    return {
      tabIndex: 0,
      form: {
        date: [dayjs(), dayjs()],
      },

      projectid: basil.get("projectid"),
      xData: [],
      yData: [],
      yData2: [],
      tableData: [],
      loading: false,
    };
  },
  watch: {
    uuid: function (value) {
      if (!value) return;
      this.getData();
    },
  },
  methods: {
    disabledDate(time) {
      if (time.getTime() > Date.now()) return true;
      if (time.getTime() < dayjs().subtract(2, "month").valueOf()) return true;
      return false;
    },
    tabClick(index) {
      this.tabIndex = index;
    },
    onReset() {
      this.form = {
        date: [dayjs(), dayjs()],
      };
      this.getData();
    },
    onSubmit() {
      this.getData();
    },
    createOption() {
      return {
        color: colors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#31c4f6",
            },
          },
          // formatter: '{b0}: {c0}<br />{b1}: {c1}'
          // valueFormatter: (value) => '$' + value.toFixed(2)


          // backgroundColor: "rgba(3, 117, 139, 0.7)",
          // borderColor: "rgba(0, 213, 255, 1)",
          // textStyle: {
          //   color: "#fff",
          // },
        },
        legend: {
          icon: "rect",
          right: 30,
          // tooltip: {
          //   show: true,
          // },
          textStyle: {
            fontSize: 16,
            color: "#B4C0D3",
          },
          itemWidth: 34,
          itemHeight: 14,
        },
        grid: {
          left: 30,
          bottom: 60,
          right: 50,
          top: 60,
          containLabel: true,
          show: true,
          backgroundColor: "#0E2454",
          borderColor: "transparent",
        },
        dataZoom: {
          // start: 0,
          // startValue: 0,
          // endValue: 20,
          // type: "inside",

          height: 20,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisPointer: {
            type: "shadow",
          },
          axisTick: {
            alignWithLabel: false,
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "#B1B2B5",
            },
          },
          nameTextStyle: {
            fontSize: 18,
            color: "#E8F6F8",
          },
          axisPointer: {
            label: {
              formatter: function (params) {
                return params.value;
              },
            },
          },
          axisLabel: {
            // interval: 5,
            // minInterval: 1,
            // maxInterval: 6,
            // rotate: 60,
            margin: 15,
          },
          data: this.xData,
        },
        yAxis: [
          {
            type: "value",
            name: "℃",
            min: 10,
            max: 45,
            axisLine: {
              onZero: false,
              show: true,
              lineStyle: {
                color: "#B1B2B5",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#AAAAAA",
                opacity: 0.36,
              },
            },
            nameTextStyle: {
              fontSize: 18,
              color: "#E8F6F8",
            },
          },
        ],
        series: [
          {
            name: "实际温度",
            type: "line",
            smooth: true,
            data: this.yData,
          },
          {
            name: "目标温度",
            type: "line",
            smooth: true,
            data: this.yData2,
          },
        ],
      };
    },
    async getData() {
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        background: "rgba(0, 0, 0, 0.7)",
        body: true,
      });
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/device/${this.projectid}/temperature/history`,
          data: {
            uuid: this.uuid,
            projectid: this.projectid,
            start_time: this.form?.date
              ? dayjs(dayjs(this.form.date[0]).startOf("date")).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "",
            end_time: this.form?.date
              ? dayjs(dayjs(this.form.date[1]).endOf("date")).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "",
          },
        });

        res = res.data;
        this.xData = [];
        this.yData = [];
        this.yData2 = [];
        res.body.forEach((i, index) => {
          i.order = index + 1;
          this.xData[index] = dayjs(i.time).format("MM/DD HH:mm");
          this.yData[index] = i.values.temperature;
          this.yData2[index] = i.values.targetTemperature;
        });
        this.tableData = res.body;
      } catch (err) {
        console.log(err);
      } finally {
        loading.close();
      }
    },
    formatterNull,
    formatterTimeSt2,
    handleClose() {
      this.$emit("update:visible", false);
      this.$emit("update:uuid", "");
    },
  },
  mounted() {
    // this.getData();
  },
  beforeUnmount() {},
};
</script>
<style scoped lang="less" src="./index.less"></style>
