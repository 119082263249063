export const data = [
  {
    index: 1, 
    name: '王辉',
    idCard: '140100********1486',
    phone: '158****6521',
    enterTime: '2022-8-9 9:00',
    type: '内部员工',
    applyStatus: '通过', 
    entryStatus: '已入场',
    applyTime: '2022-8-6 10:12:22',
    step: 1, 
    code: '5027'
  },
  {
    index: 2, 
    name: '刘武',
    idCard: '140100********3214',
    phone: '152****6618',
    enterTime: '2022-8-9 9:00',
    type: '内部员工',
    applyStatus: '通过', 
    entryStatus: '已入场',
    applyTime: '2022-8-6 18:10:30',
    step: 2, 
    code: '5027'
  },
  {
    index: 3, 
    name: '赵明',
    idCard: '140100********2541',
    phone: '156****1235',
    enterTime: '2022-8-8 14:00',
    type: '外部访客',
    applyStatus: '通过', 
    entryStatus: '已入场',
    applyTime: '2022-8-3 14:20:19',
    step: 3, 
    code: '5027'
  },
  {
    index: 4, 
    name: '郭一飞',
    idCard: '342426********5287',
    phone: '150****5287',
    enterTime: '2022-8-8 14:00',
    type: '外部访客',
    applyStatus: '通过', 
    entryStatus: '已入场',
    applyTime: '2022-8-3 14:14:32',
    step: 4, 
    code: '5027'
  },
  {
    index: 5, 
    name: '王武',
    idCard: '342426********6555',
    phone: '138****6521',
    enterTime: '2022-8-9 9:00',
    type: '外部访客',
    applyStatus: '通过', 
    entryStatus: '未入场',
    applyTime: '2022-8-3 14:14:25',
    step: 1, 
    code: '5027'
  },
  {
    index: 6, 
    name: '孙钊',
    idCard: '342426********X115',
    phone: '132****0234',
    enterTime: '2022-8-9 9:00',
    type: '外部访客',
    applyStatus: '通过', 
    entryStatus: '已入场',
    applyTime: '2022-8-3 14:13:32',
    step: 2, 
    code: '5027'
  },
  {
    index: 7, 
    name: '江一帆',
    idCard: '342426********5219',
    phone: '180****9525',
    enterTime: '2022-8-8 14:00',
    type: '外部访客',
    applyStatus: '通过', 
    entryStatus: '已入场',
    applyTime: '2022-8-3 14:13:30',
    step: 2, 
    code: '5027'
  },
  {
    index: 8, 
    name: '吴四海',
    idCard: '140110********5287',
    phone: '153****1574',
    enterTime: '2022-8-8 14:00',
    type: '外部访客',
    applyStatus: '通过', 
    entryStatus: '已入场',
    applyTime: '2022-8-2 11:11:30',
    step: 3, 
    code: '5027'
  },
  {
    index: 9, 
    name: '张强',
    idCard: '140110********6329',
    phone: '180****9634',
    enterTime: '2022-8-7 10:00',
    type: '内部员工',
    applyStatus: '通过', 
    entryStatus: '已入场',
    applyTime: '2022-8-2 11:11:30',
    step: 3, 
    code: '5027'
  },
  {
    index: 10, 
    name: '周正',
    idCard: '140110********5218',
    phone: '155****3215',
    enterTime: '2022-8-7 9:00',
    type: '内部员工',
    applyStatus: '通过', 
    entryStatus: '已入场',
    applyTime: '2022-8-2 19:18:29',
    step: 1, 
    code: '5027'
  }
]