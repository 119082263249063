<template>
  <div class="peiliao padding10">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'MeadowIndex' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item :to="{ name: 'MeadowLiquidIndex' }"
        >液态料</el-breadcrumb-item
      >
      <el-breadcrumb-item>配料</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs>
      <el-tab-pane label="数据1">
        <el-card>
          <el-table class="table1" :data="tableData" border>
            <el-table-column prop="t1" label="*"></el-table-column>
            <el-table-column prop="t2" label="号"> </el-table-column>
            <el-table-column prop="t3" label="名称"> </el-table-column>
            <el-table-column prop="t4" label="料能"> </el-table-column>
            <el-table-column prop="t5" label="价格"> </el-table-column>
            <el-table-column prop="t6" label="干物质%"> </el-table-column>
            <el-table-column prop="t7" label="蛋白"> </el-table-column>
            <el-table-column prop="t8" label="赖氨酸"> </el-table-column>
            <el-table-column prop="t9" label="蛋氨酸 胱氨"></el-table-column>
            <el-table-column prop="t10" label="主显">
              <template #default="scope">
                <i class="el-icon-check"></i>
              </template>
            </el-table-column>
            <el-table-column prop="color" label="色">
              <template #default="scope">
                <div
                  :style="{
                    width: '20px',
                    height: '20px',
                    backgroundColor: scope.row.color,
                  }"
                ></div>
              </template>
            </el-table-column>
          </el-table>
        </el-card>

        <el-card class="box-card">
          <el-button type="primary" icon="el-icon-delete"></el-button>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="数据2" disabled>xx</el-tab-pane>
      <el-tab-pane label="控制1" disabled>xx</el-tab-pane>
      <el-tab-pane label="控制2" disabled>xx</el-tab-pane>
      <el-tab-pane label="存量" disabled>xx</el-tab-pane>
      <el-tab-pane label="消耗量" disabled>xx</el-tab-pane>
      <el-tab-pane label="替代配料" disabled>xx</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  methods: {},
  data() {
    return {
      tableData: [
        {
          color: "#ff0000",
          t1: "*",
          t2: "1",
          t3: "used water",
          t4: "0",
          t5: "0",
          t6: "0",
          t7: "0",
          t8: "0",
          t9: "0",
          t10: "",
        },
        {
          color: "#0000ff",
          t1: "*",
          t2: "2",
          t3: "fresh water",
          t4: "0",
          t5: "0",
          t6: "0",
          t7: "0",
          t8: "0",
          t9: "0",
          t10: "",
        },
      ],
      form: {
        resource: "",
        type: [],
      },
    };
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
