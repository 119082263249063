<template>
  <div class="home-global-right">
    <div class="con-global-right"><router-view /></div>

    <div class="nav-global-right">
      <el-menu
        router
        :default-active="$route.name"
        class="nav-menu-global-right"
        mode="horizontal"
        @select="handleSelect"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowSmartFeedIndex"
          :route="{
            name: 'MeadowSmartFeedIndex',
            params: { id: $route.params.id },
          }"
        >
          <!-- F1 功能控制 -->
          
          F1 数据统计
        </el-menu-item>
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowSmartFeedQuxianfenpei"
          :route="{
            name: 'MeadowSmartFeedQuxianfenpei',
            params: { id: $route.params.id },
          }"
          >F2 曲线分配</el-menu-item
        >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowSmartFeedJilu"
          :route="{
            name: 'MeadowSmartFeedJilu',
            params: { id: $route.params.id },
          }"
          >F3 饲喂记录</el-menu-item
        >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowSmartFeedBaojing"
          :route="{
            name: 'MeadowSmartFeedBaojing',
            params: { id: $route.params.id },
          }"
          >F4 报警记录</el-menu-item
        >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowSmartFeedDongwu"
          :route="{
            name: 'MeadowSmartFeedDongwu',
            params: { id: $route.params.id },
          }"
          >F5 动物数据</el-menu-item
        >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowSmartFeedFenli"
          :route="{
            name: 'MeadowSmartFeedFenli',
            params: { id: $route.params.id },
          }"
          >F6 分离</el-menu-item
        >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowSmartFeedYanse"
          :route="{
            name: 'MeadowSmartFeedYanse',
            params: { id: $route.params.id },
          }"
          >F7 颜色标记</el-menu-item
        >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowSmartFeedWeijiliang"
          :route="{
            name: 'MeadowSmartFeedWeijiliang',
            params: { id: $route.params.id },
          }"
          >F8 小料</el-menu-item
        >
        <el-menu-item
          class="nav-item-global-right"
          index="MeadowSmartFeedRichang"
          :route="{
            name: 'MeadowSmartFeedRichang',
            params: { id: $route.params.id },
          }"
          >F9 每日流程</el-menu-item
        >
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
};
</script>
<style scoped lang="less" src="../../feed/home/index.less"></style>
