<template>
  <div class="pigsty-unit">
    <!-- <div class="zhu"></div> -->
    <el-form :inline="true" :model="form" class="demo-form-inline global-form global-form-inline">
      <el-form-item label="栋舍名称：">
        <el-select v-model="pigstyActive" placeholder=" " no-data-text="暂无数据">
          <el-option v-for="(item, index) in pigstyList" :key="index" :label="item.text"
            :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :style="
        unitList.length && unitList.length > 1 ? '' : 'visibility: hidden'
      " label="单元号：">
        <el-select v-model="unitActive" placeholder=" " @change="unitChange" no-data-text="暂无数据">
          <el-option v-for="(item, index) in unitList" :key="index" :label="item.text" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import basil from "../../util/basil";

export default {
  name: "Page",
  components: {},
  props: ["modelValue", "hasAll", "initAssetidInfo"],
  emits: ["update:modelValue"],
  data() {
    return {
      projectid: basil.get("projectid"),
      unitList: [],
      unitActive: "",
      pigstyList: [],
      pigstyActive: "",
    };
  },
  watch: {
    unitActive: function (newVal, old) {
      this.$emit("update:modelValue", {
        pigstyActive: this.pigstyActive,
        unitActive: newVal,
      });
    },
    pigstyActive(newVal, old) {
      if (newVal) {
        this.getUnitList(this.pigstyActive);
      } else if (!newVal) {
        this.unitList = [];
        this.unitActive = "";
      }
    },
  },
  methods: {
    getUnitList(pigstyid) {
      this.$myRequest({
        // url: `/api/tenant/asset/${this.$route.params.id}/option/${pigstyid}/unit`,
        url: `/api/tenant/asset/${this.projectid}/option/${pigstyid}/unit`,
      })
        .then((res) => {
          // console.log("getUnitList res");
          // console.log(res);
          res.data.body.sort((a, b) => Number(a.value) - Number(b.value));
          this.unitList = res.data.body;

          if (this.unitList.length) {
            if (this.initAssetidInfo && this.initAssetidInfo.unitActive) {
              let find = this.unitList.find((v) => {
                return v.value == this.initAssetidInfo.unitActive;
              });
              if (find) {
                this.unitActive = this.initAssetidInfo.unitActive;
              } else {
                this.unitActive = this.unitList[0].value;
              }
            } else {
              this.unitActive = this.unitList[0].value;
            }
          } else {
            // console.log("zhikong");
            this.unitActive = "";
          }
          // this.getEnvList();
          // this.getDevice();
          // this.getEnergy();
          //
        })
        .catch((res) => {
          // console.log("getUnitList err");
          // console.log(res);
          this.$message(res.data.status_detail);
        });
    },
    getPigstyList() {
      this.$myRequest({
        url: `/api/tenant/asset/${this.projectid}/option/house`,
        data: {
          env: "环控环境",
        },
      })
        .then((res) => {
          console.log("getPigstyList res");
          console.log(res);
          this.pigstyList = res.data.body;
          if (this.hasAll) {
            this.pigstyList.unshift({ value: "", text: "全部" });
          }
          if (this.initAssetidInfo && this.initAssetidInfo.pigstyActive) {
            let find = this.pigstyList.find((v) => {
              return v.value == this.initAssetidInfo.pigstyActive;
            });
            if (find) {
              this.pigstyActive = this.initAssetidInfo.pigstyActive;
            } else {
              this.pigstyActive = this.pigstyList[0].value;
            }
          } else {
            this.pigstyActive = this.pigstyList[0].value;
          }
          // this.getUnitList(this.pigstyActive);
          //
        })
        .catch((res) => {
          console.log("getPigstyList err");
          console.log(res);
          this.$message(res.data.status_detail);
        });
    },
  },
  mounted() {
    this.getPigstyList();
  },
};
</script>
<style scoped lang="less" src="./index.less">

</style>
