<template>
  <div class="time">{{ time }}</div>
</template>

<script>
import moment from "moment";
export default {
  name: "Page",
  components: {},
  data() {
    return {
      time: "",
      timeT: undefined,
    };
  },
  methods: {
    init() {
      this.time = moment().format("YYYY-MM-DD HH:mm:ss");
      this.timeT = setInterval(() => {
        this.time = moment().format("YYYY-MM-DD HH:mm:ss");
      }, 1000);
    },
    visibilitychange() {
      console.log("visibilitychange");
      console.log(document.hidden);
      if (document.hidden) {
        clearInterval(this.timeT);
      } else {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
    document.addEventListener("visibilitychange", this.visibilitychange);
  },
  beforeUnmount() {
    clearInterval(this.timeT);
    document.removeEventListener("visibilitychange", this.visibilitychange);
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
