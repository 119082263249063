<template>
  <div class="global-box">
    <el-table :data="tableData" stripe border>
      <el-table-column prop="a1" label="位置"> </el-table-column>
      <el-table-column prop="a2" label="设备类型"> </el-table-column>
      <el-table-column prop="a3" label="设备号"></el-table-column>
      <el-table-column prop="a4" label="报警类型"></el-table-column>
      <el-table-column prop="a5" label="数据"></el-table-column>
      <el-table-column
        prop="a6"
        label="产生时间"
        :formatter="formatterTime"
      ></el-table-column>
      <el-table-column
        prop="a7"
        label="结束时间"
        :formatter="formatterTime"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Page",
  components: {},
  data() {
    return {
      tableData: [
        {
          a1: "配怀舍",
          a2: "电表",
          a3: "D1",
          a4: "电量异常",
          a5: "高于正常值",
          a6: "2021/7/15 2:14",
          a7: "2021/7/15 9:14",
        },
        {
          a1: "育肥舍4单元",
          a2: "室外温度",
          a3: "W2",
          a4: "温度异常",
          a5: "温度过高，50°C",
          a6: "2021/7/30 12:01",
          a7: "2021/7/30 14:17",
        },
        {
          a1: "保育舍1单元",
          a2: "水表",
          a3: "S1",
          a4: "水量异常",
          a5: "低于正常值",
          a6: "2021/8/3 9:34",
          a7: "2021/8/4 8:14",
        },
        {
          a1: "公猪舍",
          a2: "室内温度",
          a3: "W1",
          a4: "温度异常",
          a5: "温度过低，15°C",
          a6: "2021/10/8 2:14",
          a7: "2021/10/8 4:10",
        },
        {
          a1: "母猪舍",
          a2: "湿度",
          a3: "Y1",
          a4: "湿度异常",
          a5: "湿度过高",
          a6: "2021/10/9 18:14",
          a7: "2021/10/9 19:08",
        },
        {
          a1: "育成舍1单元",
          a2: "氨气",
          a3: "Q1",
          a4: "氨气异常",
          a5: "氨气浓度过高",
          a6: "2021/11/15 16:14",
          a7: "2021/11/15 18:14",
        },
      ],
      page: 1,
      pageSize: 10,
    };
  },
  methods: {
    formatterTime(row, column) {
      // console.log("formatterType");
      // console.log(row);
      // console.log(column);
      return moment(row.time).format("YYYY-MM-DD HH:mm:ss");
    },
    getList() {
      this.$myRequest({
        url: "/api/alarm/list",
        data: {
          page: this.page,
          pageSize: this.pageSize,
        },
      })
        .then((res) => {
          // console.log(" res");
          // console.log(res);
          this.tableData = res.data.body.datas;
          //
        })
        .catch((res) => {
          console.log(" err");
          console.log(res);
          this.$message(res.data.status_detail);
        });
    },
  },
  mounted() {},
};
</script>
<style scoped lang="less" src="./index.less">
</style>
