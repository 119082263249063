<template>
  <div class="weight-index2">
    <div class="tabs">
      <div
        @click="() => handleClick('1')"
        class="tab-item"
        :class="{ active: activeName == '1' }"
      >
        <span>实时数据</span>
      </div>
      <div
        @click="() => handleClick('2')"
        class="tab-item"
        :class="{ active: activeName == '2' }"
      >
        <span>历史记录</span>
      </div>
      <div
        @click="() => handleClick('3')"
        class="tab-item"
        :class="{ active: activeName == '3' }"
      >
        <span>加料记录</span>
      </div>
    </div>
    <div class="block" v-show="activeName == '1'" style="width: 100%">
      <div class="weight-towers" v-for="it in rows" :key="it">
        <!-- <div class="weight-btns">
          <div class="my-btn">物料重量</div> -->
        <!-- <div class="my-btn">本次加料量</div>
        <div class="my-btn">存栏数量</div> -->
        <!-- </div> -->

        <template v-for="(item, index1) in rowsData[it]" :key="index1">
          <div
            class="group-wrapper"
            :style="{
              marginRight:
                index1 == rowsData[it].length - 1 ? 0 : item.length * 20 + 'px',
            }"
          >
            <div
              class="weight-tower-item"
              v-for="(tower, index2) in item"
              :key="index2"
            >
              <div class="weight-tower">
                <div class="weight-tower-rongliang-label">
                  {{ tower.percent }}%
                </div>

                <div class="weight-tower-rongliang">
                  <div
                    class="weight-tower-rongliang-val"
                    :style="{ height: tower.percent + '%' }"
                  ></div>
                </div>
              </div>
              <table class="my-table">
                <thead>
                  <tr>
                    <td>{{ tower?.showName }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ tower?.values?.curWeight / 10 }} kg</td>
                  </tr>
                  <!-- <tr>
              <td>{{ (item.t / 5).toFixed(2) }}</td>
            </tr>
            <tr>
              <td>{{ item.v3 }}</td>
            </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </template>

        <div class="weight-tower-item"></div>
      </div>
    </div>

    <div class="block block2" v-show="activeName == '2'" style="width: 100%">
      <div style="width: 100%">
        <el-form
          ref="form"
          :inline="true"
          :model="form"
          class="demo-form-inline global-form global-form-inline"
          width="100%"
        >
          <el-col :span="5">
            <el-form-item>
              <el-select v-model="form.sheId" placeholder=" ">
                <el-option
                  v-for="(item, index) in pigstyList"
                  :key="index"
                  :label="item.text"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="5">
            <el-form-item>
              <el-select v-model="form.towerId" placeholder=" ">
                <el-option
                  v-for="(item, index) in towerList"
                  :key="index"
                  :label="item.text"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="日期：">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                range-separator="至"
                :disabled-date="disabledDate"
              />
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item>
              <span
                class="btn-reset"
                @click="onReset"
                style="margin-right: 10px"
                >重置</span
              >
              <span class="btn-search" @click="onSubmit">查询</span>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <div class="table-box">
        <el-table :data="tableData" stripe border>
          <el-table-column
            label="序号"
            prop="order"
            width="120"
            :formatter="formatterNull"
          ></el-table-column>
          <el-table-column
            prop="time"
            label="时间"
            :formatter="formatterTimeSt2"
          >
          </el-table-column>

          <el-table-column label="余量(kg)" :formatter="formatterNull">
            <template #default="scope">
              <span>{{ scope.row.values.curWeight ?? "--" }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="block block2" v-show="activeName == '3'" style="width: 100%">
      <div style="width: 100%">
        <el-form
          ref="form2"
          :inline="true"
          :model="form2"
          class="demo-form-inline global-form global-form-inline"
          width="100%"
        >
          <el-col :span="5">
            <el-form-item>
              <el-select v-model="form2.sheId" placeholder=" ">
                <el-option
                  v-for="(item, index) in pigstyList2"
                  :key="index"
                  :label="item.text"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="5">
            <el-form-item>
              <el-select v-model="form2.towerId" placeholder=" ">
                <el-option
                  v-for="(item, index) in towerList2"
                  :key="index"
                  :label="item.text"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="日期：">
              <el-date-picker
                v-model="form2.date"
                type="daterange"
                range-separator="至"
                :disabled-date="disabledDate"
              />
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item>
              <span
                class="btn-reset"
                @click="onReset2"
                style="margin-right: 10px"
                >重置</span
              >
              <span class="btn-search" @click="onSubmit2">查询</span>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <div class="table-box">
        <el-table :data="tableData2" stripe border>
          <el-table-column
            label="序号"
            prop="order"
            width="120"
            :formatter="formatterNull"
          ></el-table-column>
          <el-table-column
            prop="start_time"
            label="时间"
            :formatter="formatterTimeSt2"
          >
          </el-table-column>
          <el-table-column label="加料量(kg)" :formatter="formatterNull">
            <template #default="scope">
              <span>{{ scope.row.add_value ?? "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="余量(kg)" :formatter="formatterNull">
            <template #default="scope">
              <span>{{ scope.row.end_value ?? "--" }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  formatterNull,
  formatterDateSt,
  formatterTimeHour,
  formatterTimeSt2,
} from "@/util/formatter.js";
import basil from "@/util/basil";
import dayjs from "dayjs";
import { maxBy, cloneDeep } from "lodash-es";
export default {
  name: "Page",
  components: {},
  data() {
    return {
      projectid: basil.get("projectid"),
      rowLength: 13,
      rowsData: {},
      rows: [],

      activeName: "1",
      pigstyList: [],
      towerList: [],
      pigstyList2: [],
      towerList2: [],
      form: {
        date: [dayjs(), dayjs()],
        // date: [dayjs().subtract(1, "week"), dayjs()],
      },
      form2: {
        date: [dayjs(), dayjs()],
        // date: [dayjs().subtract(1, "week"), dayjs()],
      },
      tableData: [],
      tableData2: [],

      page: 1,
      pageSize: 10,

      page2: 1,
      pageSize2: 10,
    };
  },
  methods: {
    formatterNull,
    formatterDateSt,
    formatterTimeHour,
    formatterTimeSt2,
    // 最近两个月时间
    disabledDate(time) {
      if (time.getTime() > Date.now()) return true;
      if (time.getTime() < dayjs().subtract(2, "month").valueOf()) return true;
      return false;
    },
    async getData() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/foodtower/${this.projectid}/datas/realtime`,
          data: {
            projectid: this.projectid,
          },
        });
        res = res.data;

        res.body.forEach((i) => {
          const arr = i.name.split("-");
          let percent = (
            (i.values.curWeight / i.values.maxWeight) *
            100
          ).toFixed(2);

          if (percent < 0) {
            percent = 0;
          } else if (percent > 100) {
            percent = 100;
          }
          i.firstName = arr[0];
          i.showName = arr[0] + "-" + arr[arr.length - 1];
          i.percent = percent;
        });

        const rowsLength = Math.ceil(res.body.length / this.rowLength);
        let obj = {};

        res.body.forEach((i) => {
          if (obj[i.firstName]) {
            obj[i.firstName].push(i);
          } else {
            obj[i.firstName] = [i];
          }
        });

        const fillArr = (list, total) => {
          if (Object.keys(obj).length <= 0) return list;
          const restLength = this.rowLength - total;

          if (restLength <= 0) return list;

          const a = Object.keys(obj).find((i) => obj[i].length == restLength);

          if (a) {
            list.push(obj[a]);
            delete obj[a];
            fillArr(list, total + a.length);
          } else {
            let newObj = cloneDeep(obj);
            Object.keys(newObj).forEach((i) => {
              if (i.length > restLength) {
                delete newObj[i];
              }
            });

            const it = maxBy(Object.keys(newObj), (i) => {
              return obj[i].length;
            });

            if (it && obj[it].length) {
              list.push(obj[it]);
              const thisLen = obj[it].length || 0;
              delete obj[it];
              fillArr(list, total + thisLen);
            } else {
              return list;
            }
          }

          return list;
        };

        let rowsData = {};
        for (let i = 0; i < rowsLength; i++) {
          rowsData[i] = fillArr([], 0);
        }

        this.rowsData = rowsData;
        this.rows = Object.keys(rowsData);
      } catch (e) {
        console.log(e);
      }
    },
    async getPigstyList() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/asset/${this.projectid}/option/house`,
          data: {
            env: "能源环境",
          },
        });
        this.pigstyList = res.data.body;
        if (this.pigstyList.length) {
          this.form.sheId = this.pigstyList[0].value;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getTowerOptions(assetid) {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/device/${this.projectid}/list`,
          data: { assetid, profile: "foodTower" },
        });
        const list = res.data.body.filter((i) => i.profile === "foodTower");
        const makeName = (name) => {
          const arr = name.split("栋舍料塔");
          return `栋舍料塔${arr[1]}`;
        };
        this.towerList = list.map((i) => ({
          text: makeName(i.name),
          value: i.uuid,
        }));
        if (this.towerList.length) {
          this.form.towerId = this.towerList[0].value;
        } else {
          this.form.towerId = undefined;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getPigstyList2() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/asset/${this.projectid}/option/house`,
          data: {
            env: "能源环境",
          },
        });
        this.pigstyList2 = res.data.body;
        if (this.pigstyList2.length) {
          this.form2.sheId = this.pigstyList2[0].value;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getTowerOptions2(assetid) {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/device/${this.projectid}/list`,
          data: { assetid, profile: "foodTower" },
        });
        const list = res.data.body.filter((i) => i.profile === "foodTower");
        const makeName = (name) => {
          const arr = name.split("栋舍料塔");
          return `栋舍料塔${arr[1]}`;
        };
        this.towerList2 = list.map((i) => ({
          text: makeName(i.name),
          value: i.uuid,
        }));
        if (this.towerList2.length) {
          this.form2.towerId = this.towerList2[0].value;
        } else {
          this.form2.towerId = undefined;
        }
      } catch (e) {
        console.log(e);
      }
    },
    // 历史记录
    async getFodderHisttory() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/device/${this.projectid}/datas/history`,
          data: {
            projectid: this.projectid,
            uuid: this.form.towerId,
            timelevel: 2, //时间级别(0:分 1:时 2:天)
            start_time: this.form?.date
              ? dayjs(dayjs(this.form.date[0]).startOf("date")).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "",
            end_time: this.form?.date
              ? dayjs(dayjs(this.form.date[1]).endOf("date")).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "",
            // page: this.page,
            // pageSize: this.pageSize,
          },
        });
        res = res.data;
        res.body.forEach((i, index) => {
          i.order = index + 1;
        });
        this.tableData = res.body;
      } catch (e) {
        console.log(e);
      }
    },
    // 加料记录
    async getFodderRecord() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/foodtower/${this.projectid}/add_fodder_record`,
          data: {
            uuid: this.form2.towerId,
            // start_time: dayjs(this.form2.date[0]).format("YYYY-MM-DD"),
            // end_time: dayjs(this.form2.date[1]).format("YYYY-MM-DD"),
            start_time: this.form2?.date
              ? dayjs(dayjs(this.form2.date[0]).startOf("date")).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "",
            end_time: this.form2?.date
              ? dayjs(dayjs(this.form2.date[1]).endOf("date")).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "",
            page: this.page2,
            pageSize: this.pageSize2,
          },
        });
        res = res.data;
        res.body.forEach((i, index) => {
          i.order = index + 1;
        });
        this.tableData2 = res.body;
      } catch (e) {
        console.log(e);
      }
    },
    handleClick(name) {
      this.activeName = name;
      if (name === "1") {
        this.getData();
      } else if (name === "2") {
        this.getFodderHisttory();
      } else {
        this.getFodderRecord();
      }
    },
    onReset() {
      this.form = {
        sheId: this.pigstyList[0].value,
        danId: this.towerList[0].value,
        // date: [dayjs().subtract(15, "day"), dayjs()],
        date: [dayjs(), dayjs()],
      };
    },
    onSubmit() {
      this.getFodderHisttory();
    },
    onReset2() {
      this.form2 = {
        sheId: this.pigstyList2[0].value,
        towerId: this.towerList2[0].value,
        // date: [dayjs().subtract(15, "day"), dayjs()],
        date: [dayjs(), dayjs()],
      };
    },
    onSubmit2() {
      this.getFodderRecord();
    },
  },
  watch: {
    "form.sheId"(v) {
      this.getTowerOptions(v);
    },
    // "form.towerId"(v) {
    //   this.getFodderRecord(v);
    // },
    "form2.sheId"(v) {
      this.getTowerOptions2(v);
    },
    // "form2.towerId"(v) {
    //   console.log(v, "lllll");
    //   this.getFodderRecord(v);
    // },
  },
  async mounted() {
    this.getData();
    this.timer = setInterval(() => {
      // this.getData();
    }, 5000);
    await this.getPigstyList();
    await this.getTowerOptions(this.form.sheId);
    // this.getFodderHisttory();

    await this.getPigstyList2();
    await this.getTowerOptions2(this.form2.sheId);
    // this.getFodderRecord();
  },
  unmounted() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped lang="less" src="./style.less"></style>
