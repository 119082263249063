<template>
  <div class="peifang padding10">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: 'MeadowIndex' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ name: 'MeadowLiquidIndex' }"
            >液态料</el-breadcrumb-item
          >
          <el-breadcrumb-item>配方</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="16">
        <el-card>
          <el-form :inline="true" :model="form" class="demo-form-inline global-form global-form-inline">
            <el-form-item label="">
              <el-select v-model="form.s1" placeholder="">
                <el-option label="1" value="xx"></el-option>
                <el-option label="2" value="xx"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-select v-model="form.s2" placeholder="">
                <el-option label="liao1" value="xx"></el-option>
                <el-option label="liao2" value="xx"></el-option>
                <el-option label="used water" value="xx"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-input></el-input>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card>
          <el-table class="table1" :data="tableData" border>
            <el-table-column prop="t1" label="顺序"> </el-table-column>
            <el-table-column prop="t2" label="配料号"> </el-table-column>
            <el-table-column prop="t3" label="配料名"> </el-table-column>
            <el-table-column prop="t4" label="份额"> </el-table-column>
            <el-table-column prop="t5" label="液料‰"> </el-table-column>
            <el-table-column prop="t6" label="干料‰"> </el-table-column>
            <el-table-column prop="t7" label="exp.ds"> </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card>
          <el-form :inline="true" :model="form" class="demo-form-inline global-form global-form-inline">
            <el-form-item label="">
              <el-checkbox-group v-model="form.type">
                <el-checkbox label="标准化干物质" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="">
              <el-input v-model="form.i1"></el-input>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card>
          <el-form :inline="true" :model="form" class="demo-form-inline global-form global-form-inline">
            <el-form-item label="wangmung bei ts(%)">
              <el-input v-model="form.i2"></el-input>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card>
          <el-form :inline="true" :model="form" class="demo-form-inline global-form global-form-inline">
            <el-form-item label="计算干物质(%)">
              <el-select v-model="form.s1" placeholder="">
                <el-option label="否" value="xx"></el-option>
                <el-option label="是" value="xx"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card>
          <el-form :model="form" class="peifangform-4 global-form">
            <el-form-item label="">
              <el-radio-group v-model="form.r1">
                <el-radio label="干物质"></el-radio>
              </el-radio-group>
              <el-input v-model="form.i3"></el-input>
              <span style="color: #ffffff">%</span>
            </el-form-item>
            <el-form-item label="">
              <el-radio-group v-model="form.resource">
                <el-radio label="wasser pro kg futter"></el-radio>
              </el-radio-group>
              <el-input v-model="form.i4"></el-input>
              <span style="color: #ffffff">L</span>
            </el-form-item>
            <el-form-item label="料能(Mj/kg)"
              ><span style="color: #ffffff">1.14</span></el-form-item
            >
            <el-form-item label="价格(/kg)">
              <span style="color: #ffffff">0</span>
            </el-form-item>
            <el-form-item label="价格(/Mj)">
              <span style="color: #ffffff">0</span>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card>
          <el-table :data="tableData2" border>
            <el-table-column prop="" label="">
              <template #default="scope">
                <div
                  :style="{
                    display:'inline-block',
                    width: '20px',
                    height: '20px',
                    backgroundColor: scope.row.color,
                  }"
                ></div>
                {{scope.row.t1}}
              </template>
            </el-table-column>
            <el-table-column prop="t2" label="g/kg"> </el-table-column>
            <el-table-column prop="t3" label="g/Mj"> </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Page",
  components: {},
  methods: {},
  data() {
    return {
      tableData2: [
        { t1: "Protein", color: "#ff0000", t2: "0", t3: "0" },
        { t1: "Lysin", color: "#ffff00", t2: "0", t3: "0" },
        { t1: "Methion Cystin", color: "#0000ff", t2: "0", t3: "0" },
      ],
      tableData: [
        {
          t1: "1",
          t2: "1",
          t3: "used water",
          t4: "730",
          t5: "730",
          t6: "0",
          t7: "0",
        },
        {
          t1: "2",
          t2: "2",
          t3: "fresh water",
          t4: "0",
          t5: "0",
          t6: "0",
          t7: "0",
        },
        {
          t1: "3",
          t2: "3",
          t3: "liao1",
          t4: "270",
          t5: "270",
          t6: "1000",
          t7: "23.8",
        },
      ],
      form: {
        resource: "",
        type: [],
        i1: "100",
        i2: "25",
        i3: "23.76",
        i4: "3.21",
      },
    };
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
<style lang="less">
.peifang {
  .peifangform-4 {
    .el-input {
      width: 60px;
      margin-left: 10px;
    }
  }
}
</style>
