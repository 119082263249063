<template>
  <div class="tip-content">
    <p>
      <span>设备名称：</span><span>{{ name }}>></span>
    </p>
    <p><span>状态：</span><span>开启</span></p>
    <p><span>运行时长：</span><span>1时12分</span></p>
    <p><span>上次检测时间：</span><span>2023年2月12日</span></p>
    <p><span>预计下次检测时间：</span><span>2023年8月12日</span></p>
  </div>
</template>

<script>
export default {
  name: "",
  props: ["name", "value"],
};
</script>

<style scoped lang="less" src="./style.less"></style>
