<template>
  <div class="wash-container">
    <div class="top-bar">
      <div class="bar-item">
        今日洗消合格总人数：<span class="number">{{
          total_qualified_peoples
        }}</span>
      </div>
      <div class="bar-item">
        洗消间总数量：<span class="number">{{ data.length }}</span>
      </div>
      <div class="bar-item">
        空闲数量：<span class="number">{{ freeNumber }}</span>
      </div>
      <div class="bar-item">
        在用数量：<span class="number">{{ inUseNumber }}</span>
      </div>
      <div class="bar-item">
        离线数量：<span class="number">{{ offNumber }}</span>
      </div>
    </div>
    <div class="card-box">
      <div class="card" v-for="(item, index) in data" :key="index">
        <div class="card-top">
          <span>{{ item.house_name }}</span>
          <span :class="`text${item.status}`">{{ item.statusText }}</span>
        </div>
        <div class="card-body">
          <div
            class="number"
            :class="`text${item.status}`"
            @click="() => toDetail(item)"
          >
            {{ item.qualified_peoples || 0 }}
          </div>
          <div class="tip">今日洗消合格/人</div>
        </div>
        <div class="card-bottom" v-if="item.status == 2">
          <span class="card-btn" @click="() => open(1)">开启脏区门</span>
          <span class="card-btn disabled" @click="() => open(2)"
            >开启净区门</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import basil from "@/util/basil";
export default {
  name: "Wash",
  components: {},
  data() {
    return {
      projectid: basil.get("projectid"),
      data: [],
      total_qualified_peoples: 0,
      offNumber: 0,
      freeNumber: 0,
      inUseNumber: 0,
    };
  },
  methods: {
    async getData() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/shower/${this.projectid}/room/list`,
        });
        res = res.data;
        res.body.forEach((i) => {
          if (!i.monitor_online) {
            i.status = 0;
            this.offNumber++;
            i.statusText = "离线";
          } else {
            if (i.monitor_work_state != null) {
              if (i.monitor_work_state == 1 || i.monitor_work_state == 0) {
                i.status = 1;
                i.statusText = "空闲";
                this.freeNumber++;
              } else {
                i.status = 2;
                i.statusText = "在用";
                this.inUseNumber++;
              }
            } else {
              //  i.status = 2;
              i.statusText = "--";
            }
          }
          this.total_qualified_peoples += i.qualified_peoples;
        });
        this.data = res.body;
      } catch (err) {
        console.log(err);
      }
    },
    open(type) {
      console.log(type);
    },
    toDetail(item) {
      // if (item.qualified_peoples) {
      this.$router.push({
        name: "WashList",
        query: {
          houseid: item.houseid,
        },
      });
      // }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped lang="less" src="./style.less"></style>
