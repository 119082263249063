<template>
  <div class="energy">
    <div class="energy-left">
      <el-form
        ref="form"
        :inline="true"
        :model="form"
        class="demo-form-inline global-form global-form-inline"
      >
        <el-col :span="5">
          <el-form-item label="栋舍名称：">
            <el-select v-model="form.sheId" placeholder=" ">
              <el-option
                v-for="(item, index) in pigstyList"
                :key="index"
                :label="item.text"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="5">
          <el-form-item label="单元号：">
            <el-select v-model="form.danId" placeholder=" ">
              <el-option
                v-for="(item, index) in unitList"
                :key="index"
                :label="item.text"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="10">
          <el-form-item label="日期：">
            <el-date-picker
              v-model="form.date"
              type="daterange"
              range-separator="至"
            />
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item>
            <span class="btn-reset" @click="onReset" style="margin-right: 10px"
              >重置</span
            >
            <span class="btn-search" @click="onSubmit">查询</span>
          </el-form-item>
        </el-col>
      </el-form>

      <div class="tabs">
        <div
          @click="() => handleClick('chart')"
          class="tab-item"
          :class="{ active: activeName == 'chart' }"
        >
          <span>图表</span>
        </div>
        <div
          @click="() => handleClick('table')"
          class="tab-item"
          :class="{ active: activeName == 'table' }"
        >
          <span>数据</span>
        </div>
      </div>

      <div v-show="activeName === 'chart'" class="chart-wrapper">
        <v-chart
          ref="chart"
          class="chart"
          autoresize
          :option="createOption()"
        />
      </div>
      <div v-show="activeName === 'table'" class="chart-wrapper">
        <el-table :data="tableData" stripe border :height="`calc(100% - 20px)`">
          <el-table-column
            label="序号"
            prop="order"
            :formatter="formatterNull"
          ></el-table-column>
          <el-table-column
            prop="time"
            label="日期"
            :formatter="formatterDateSt"
          >
          </el-table-column>
          <!-- <el-table-column label="当日平均用量(t)" :formatter="formatterNull">
            <template #default="scope">
              <span>{{ scope.row.water.average ?? "--" }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="实际用水量(t)" :formatter="formatterNull">
            <template #default="scope">
              <span>{{ this.fmNumber(scope.row?.water?.total) ?? "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="水表读数(t)" :formatter="formatterNull">
            <template #default="scope">
              <span>{{ this.fmNumber(scope.row?.water?.accumulate) ?? "--" }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="energy-right">
      <div class="energy-box1">
        <div class="global-title">用水量统计</div>
        <table class="energy-table">
          <tr>
            <td class="td1">
              <div class="txt1">今日</div>
            </td>
            <td class="td2">
              <div class="txt2">{{ this.fmNumber(energyInfoTody?.total) ?? "--" }} t</div>
            </td>
          </tr>
          <tr>
            <td class="td1">
              <div class="txt1">昨日</div>
            </td>
            <td class="td2">
              <div class="txt2">{{ this.fmNumber(energyInfoHisttory?.total) ?? "--" }} t</div>
            </td>
          </tr>
          <tr>
            <td class="td1">
              <div class="txt1">平均</div>
            </td>
            <td class="td2">
              <div class="txt2">
                {{ this.fmNumber(energyInfoHisttory?.average) ?? "--" }} t
              </div>
            </td>
          </tr>
          <tr>
            <td class="td1">
              <div class="txt1">总量</div>
            </td>
            <td class="td2">
              <div class="txt2">{{ this.fmNumber(energyInfoTody?.accumulate) ?? "--" }} t</div>
            </td>
          </tr>
        </table>
      </div>
      <div class="energy-box2" style="visibility: hidden">
        <div class="global-title">
          异常记录
          <span class="more" @click="toAlarm">更多>></span>
        </div>
        <div class="video-warning-list">
          <div
            class="video-warning-item video-warning-item-read"
            v-for="(item, index) in warningHistory"
            :key="index"
            :data-time="item"
            @click="warningClick"
          >
            <div class="content">{{ item.content }}</div>
            <div class="time">{{ item.time }}</div>
            <!-- <div class="video-warning-tag"></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatterNull, formatterDateSt } from "@/util/formatter.js";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { LineChart } from "echarts/charts";
import dayjs from "dayjs";
use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
]);

const colors = [
  // "#FF4C50",
  "#67C23A",
  "#FF9B78",
];
import basil from "@/util/basil";
export default {
  name: "Page",
  components: { VChart },
  data() {
    return {
      energyInfoTody: {},
      energyInfoHisttory: {},
      form: {
        sheId: undefined,
        danId: undefined,
        date: [dayjs().subtract(15, "day"), dayjs()],
      },

      projectid: basil.get("projectid"),
      pigstyList: [],
      unitList: [],
      warningHistory: [
        {
          content: "超过上限",
          time: "2022-09-23 20:26:21",
        },
        {
          content: "超过上限",
          time: "2022-07-21 18:25:29",
        },
        {
          content: "无数据更新",
          time: "2022-07-01 22:19:36",
        },
        {
          content: "不足下跟",
          time: "2022-06-22 20:55:24",
        },
      ],

      xAxis: [],
      realList: [],
      tableData: [],
      activeName: "chart",
    };
  },
  watch: {
    "form.sheId": async function (value) {
      if (!value) return;
      await this.getUnitList(value);
    },
  },
  methods: {
    formatterNull,
    formatterDateSt,
    fmNumber(value) {
      if(typeof value === 'number') {
        return value.toFixed(3)
      }
    },
    async getEnergyHistoryForChart() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/energy/${this.projectid}/history`,
          data: {
            assetid: this.form.danId || this.form.sheId,
            projectid: this.projectid,
            start_date: dayjs(this.form.date[0]).format("YYYY-MM-DD"),
            end_date: dayjs(this.form.date[1]).format("YYYY-MM-DD"),
            device_profile: "water",
          },
        });
        this.xAxis = res.data.body.map((i) => dayjs(i.time).format("MM-DD"));
        this.realList = res.data.body.map((i) => i?.water?.total);
        res.data.body.reverse();
        res.data.body.forEach((i, index) => (i.order = index + 1));
        this.tableData = res.data.body;
      } catch (e) {
        console.log(e);
      }
    },
    async getEnergyHistory() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/energy/${this.projectid}/history`,
          data: {
            assetid: this.form.danId || this.form.sheId,
            projectid: this.projectid,
            start_date: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
            end_date: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
            device_profile: "water",
          },
        });
        if (res.data.body.length) {
          this.energyInfoHisttory = res.data?.body[0]?.water;
        } else {
          this.energyInfoHisttory = {};
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getEnergy() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/energy/${this.projectid}/today`,
          data: {
            assetid: this.form.danId || this.form.sheId,
            projectid: this.projectid,
            device_profile: "water",
          },
        });
        if (res.data.body.water) {
          this.energyInfoTody = res.data.body.water;
        } else {
          this.energyInfoTody = {};
        }
        // console.log(this.energyInfoTody, "pppppp");
      } catch (e) {
        console.log(e);
      }
    },
    onReset() {
      this.form = {
        sheId: this.pigstyList[0].value,
        danId: this.unitList[0].value,
        date: [dayjs().subtract(15, "day"), dayjs()],
      };
    },
    onSubmit() {
      this.getEnergyHistoryForChart();
      this.getEnergy();
      this.getEnergyHistory();
    },
    createOption() {
      return {
        color: colors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#31c4f6",
            },
          },
          backgroundColor: "rgba(3, 117, 139, 0.7)",
          borderColor: "rgba(0, 213, 255, 1)",
          textStyle: {
            color: "#fff",
          },
        },
        legend: {
          icon: "rect",
          right: 20,
          // tooltip: {
          //   show: true,
          // },
          textStyle: {
            fontSize: 16,
            color: "#B4C0D3",
          },
          itemWidth: 34,
          itemHeight: 14,
        },
        grid: {
          left: 10,
          bottom: 40,
          right: 20,
          top: 70,
          containLabel: true,
          show: true,
          backgroundColor: "#0E2454",
          borderColor: "transparent",
        },
        // dataZoom: {
        //   start: 0,
        //   type: "inside",
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisPointer: {
              type: "shadow",
            },
            // axisTick: {
            //   alignWithLabel: true,
            // },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#B1B2B5",
              },
            },
            nameTextStyle: {
              fontSize: 18,
              color: "#E8F6F8",
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return params.value;
                },
              },
            },

            data: this.xAxis,
          },
        ],
        yAxis: [
          {
            type: "value",
            // max: 20,
            min: 0,
            // offset: 20,
            name: "T",
            axisLine: {
              onZero: false,
              show: true,
              lineStyle: {
                color: "#B1B2B5",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#AAAAAA",
                opacity: 0.36,
              },
            },
            nameTextStyle: {
              fontSize: 18,
              color: "#E8F6F8",
            },
          },
        ],
        series: [
          // {
          //   name: "上限",
          //   type: "line",
          //   smooth: true,
          //   // symbol: "rect",
          //   // icon: 'rect',
          //   // symbolSize: 8,
          //   // emphasis: {
          //   //   focus: "series",
          //   // },
          //   data: data1,
          // },
          {
            name: "实际",
            type: "line",
            smooth: true,
            // symbol: "rect",
            // symbolSize: 8,
            data: this.realList,
          },
          // {
          //   name: "下限",
          //   type: "line",
          //   smooth: true,
          //   // symbol: "rect",
          //   // symbolSize: 8,
          //   data: data3,
          // },
        ],
      };
    },
    warningClick(e) {
      console.log(e.currentTarget.dataset.time);
      this.$router.push({ name: "projectAlarm", params: { id: 1 } });
    },
    async getPigstyList() {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/asset/${this.projectid}/option/house`,
          data: {
            env: "能源环境",
          },
        });
        this.pigstyList = res.data.body;
        if (this.pigstyList.length) {
          this.form.sheId = this.pigstyList[0].value;
          await this.getUnitList(this.form.sheId);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getUnitList(pigstyid) {
      try {
        let res = await this.$myRequest({
          url: `/api/tenant/asset/${this.projectid}/option/${pigstyid}/unit`,
        });

        res.data.body.sort((a, b) => Number(a.value) - Number(b.value));
        this.unitList = res.data.body;
        this.unitList.unshift({ value: "", text: "全部单元" });
        if (this.unitList.length) {
          this.form.danId = this.unitList[0].value;
        } else {
          this.form.danId = undefined;
        }
      } catch (e) {
        console.log(e);
      }
    },
    toAlarm() {
      this.$router.push({ name: "projectAlarm", params: { id: 1 } });
    },
    handleClick(name) {
      this.activeName = name;
    },
  },
  async mounted() {
    await this.getPigstyList();
    this.getEnergy();
    this.getEnergyHistory();
    this.getEnergyHistoryForChart();

    const _this = this;
    window.addEventListener("resize", () => {
      _this?.$refs?.chart?.resize();
    });
  },
  beforeUnmount() {},
};
</script>
<style scoped lang="less" src="../index.less"></style>
