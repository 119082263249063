<template>
  <div class="tip-content">
    <p><span>设备名称：</span><span>潜水搅拌机>></span></p>
    <p>
      <span>状态：</span><span>{{ status}}</span>
    </p>
    <p><span>运行模式：</span><span>手动</span></p>
    <p>
      <span>运行电流：</span><span>{{ value }}A</span>
    </p>
    <p><span>运行时长：</span><span>1时12分</span></p>
    <p><span>上次检测时间：</span><span>2023年2月12日</span></p>
    <p><span>预计下次检测时间：</span><span>2023年8月12日</span></p>
  </div>
</template>

<script>
export default {
  props: ["value", "status"],
  name: "QianShuiJiaoBanJi",
};
</script>

<style scoped lang="less" src="./style.less"></style>
