const list = [
  {
    code: 1,
    pipeline: 1,
    fa: "1-1",
    animalCount: 20,
    yestody1: 30,
    yestody2: 120,
    id: 1,
    index: 0,
  },
  {
    code: 2,
    pipeline: 1,
    fa: "1-4",
    animalCount: 29,
    yestody1: 69,
    yestody2: 235,
    id: 1,
    index: 1,
  },
  {
    code: 3,
    pipeline: 2,
    fa: "2-1",
    animalCount: 30,
    yestody1: 45,
    yestody2: 190,
    id: 1,
    index: 2,
  },
  {
    code: 4,
    pipeline: 2,
    fa: "2-2",
    animalCount: 28,
    yestody1: 42,
    yestody2: 160,
    id: 1,
    index: 3,
  },
  {
    code: 5,
    pipeline: 5,
    fa: "5-1",
    animalCount: 33,
    yestody1: 50,
    yestody2: 195,
    id: 1,
    index: 4,
  },
];

const list_detail = [
  [
    {
      date: "5-12",
      amount: 28,
      weight: 71.1,
    },
    {
      date: "5-13",
      amount: 29,
      weight: 71.6,
    },
    {
      date: "5-14",
      amount: 28,
      weight: 72,
    },
    {
      date: "5-15",
      amount: 27.9,
      weight: 72.4,
    },
    {
      date: "5-16",
      amount: 30.2,
      weight: 73,
    },
    {
      date: "5-17",
      amount: 31,
      weight: 74,
    },
    // {
    //   date: "5-18",
    //   amount: 28.7,
    //   weight: 75.7,
    // },
    {
      date: "5-18",
      amount: 30,
      weight: 76.2,
    },
  ],

  [
    {
      date: "5-12",
      amount: 65,
      weight: 71.1,
    },
    {
      date: "5-13",
      amount: 66.6,
      weight: 71.6,
    },
    {
      date: "5-14",
      amount: 66,
      weight: 72,
    },
    {
      date: "5-15",
      amount: 67.3,
      weight: 72.4,
    },
    {
      date: "5-16",
      amount: 67,
      weight: 73,
    },
    {
      date: "5-17",
      amount: 68,
      weight: 74,
    },
    // {
    //   date: "5-16",
    //   amount: 68.2,
    //   weight: 75.7,
    // },
    {
      date: "5-18",
      amount: 69,
      weight: 76.2,
    },
  ],


  [
    {
      date: "5-12",
      amount: 44,
      weight: 71.1,
    },
    {
      date: "5-13",
      amount: 44.4,
      weight: 71.6,
    },
    {
      date: "5-14",
      amount: 45,
      weight: 72,
    },
    {
      date: "5-15",
      amount: 44.5,
      weight: 72.4,
    },
    {
      date: "5-16",
      amount: 45,
      weight: 73,
    },
    {
      date: "5-17",
      amount: 46,
      weight: 74,
    },
    // {
    //   date: "5-16",
    //   amount: 45.6,
    //   weight: 75.7,
    // },
    {
      date: "5-18",
      amount: 45,
      weight: 76.2,
    },
  ],


  [
    {
      date: "5-12",
      amount: 40,
      weight: 71.1,
    },
    {
      date: "5-13",
      amount: 41,
      weight: 71.6,
    },
    {
      date: "5-14",
      amount: 41.2,
      weight: 72,
    },
    {
      date: "5-15",
      amount: 41,
      weight: 72.4,
    },
    {
      date: "5-16",
      amount: 41.5,
      weight: 73,
    },
    {
      date: "5-17",
      amount: 42,
      weight: 74,
    },
    // {
    //   date: "5-16",
    //   amount: 41,
    //   weight: 75.7,
    // },
    {
      date: "5-18",
      amount: 42,
      weight: 76.2,
    },
  ],

  [
    {
      date: "5-12",
      amount: 49,
      weight: 71.1,
    },
    {
      date: "5-13",
      amount: 49.2,
      weight: 71.6,
    },
    {
      date: "5-14",
      amount: 48,
      weight: 72,
    },
    {
      date: "5-15",
      amount: 49.1,
      weight: 72.4,
    },
    {
      date: "5-16",
      amount: 48.7,
      weight: 73,
    },
    {
      date: "5-17",
      amount: 48,
      weight: 74,
    },
    // {
    //   date: "5-16",
    //   amount: 50.8,
    //   weight: 75.7,
    // },
    {
      date: "5-18",
      amount: 50,
      weight: 76.2,
    },
  ],
];

export { list, list_detail };
