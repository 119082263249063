<template>
  <div class="tip-content">
    <p><span>设备名称：</span><span>温度传感器>></span></p>
    <p><span>状态：</span><span>在线</span></p>
    <p><span>上层温度：</span><span>72.5℃</span></p>
    <p><span>中层温度：</span><span>71.1℃</span></p>
    <p><span>下层温度：</span><span>61℃</span></p>
    <p><span>平均温度：</span><span>68.2℃</span></p>
    <p><span>运行时长：</span><span>1时12分</span></p>
    <p><span>上次检测时间：</span><span>2023年2月12日</span></p>
    <p><span>预计下次检测时间：</span><span>2023年8月12日</span></p>
  </div>
</template>

<script>
export default {
  props: ["value"],
  name: "",
};
</script>

<style scoped lang="less" src="./style.less"></style>
