<template>
  <div class="video">
    <div class="video-box">
      <div :class="{ 'video-left': true, 'video-left-close': !open }">
        <div class="video-title">监控位置</div>
        <videoList v-model="cameraIndexCode"></videoList>

        <div class="time-box">
          <div class="video-title">时间段</div>
          <el-form ref="form" :model="form" class="global-form" :rules="rules">
            <el-form-item label="">
              <el-date-picker
                :clearable="false"
                v-model="date"
                type="datetimerange"
                range-separator="-"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              >
              </el-date-picker>
            </el-form-item>
            <div class="time-btns">
              <div class="btn1" @click="resetTime">重置</div>
              <div class="btn2" @click="timeSearch">查询</div>
            </div>
          </el-form>
        </div>
      </div>
      <div class="video-line" @click="open = !open">
        <div
          :class="{ 'video-line-open': open, 'video-line-close': !open }"
        ></div>
      </div>
      <div class="video-right">
        <div class="global-title">历史监控</div>

        <div class="video-obj-box">
          <div id="video" class="video-obj"></div>
          <div v-if="this.videoInfo.status != 2" class="video-msg">
            <div class="video-msg-icon"></div>
            <template v-if="this.videoInfo.status == 0"
              >选择一个监控位置，即可查看监控视频</template
            >
            <template v-if="this.videoInfo.status == 1"
              >监控视频加载中...</template
            >
            <template v-if="this.videoInfo.status == 3"
              >加载失败，请重试</template
            >
            <template v-if="this.videoInfo.status == 4"
              >所选时间段暂无视频，请重新选择</template
            >
          </div>
        </div>
        <div class="video-control">
          <i class="icon el-icon-refresh" @click="playRefresh"></i>
          <i class="icon el-icon-video-pause" @click="playPause"></i>
          <i class="icon el-icon-video-play" @click="playResume"></i>
          <el-slider
            :max="videoInfo.et"
            :min="videoInfo.st"
            v-model="videoInfo.cur"
            :format-tooltip="formatTooltip"
            @change="timeBarChange"
            @input="timeBarInput"
            :disabled="videoInfo.status == 2 ? false : true"
            :show-tooltip="videoInfo.status == 2 ? true : false"
          ></el-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videoList from "@/components/video-list/index.vue";

import moment from "moment";
export default {
  name: "Page",
  components: { videoList },
  data() {
    return {
      open: true,
      url: "",
      cameraIndexCode: "",
      date: [],
      videoInfo: {
        status: 0,
        cur: 0,
        st: 0,
        et: 0,
        marks: {},
        urlRes: null,
      },
    };
  },
  watch: {
    cameraIndexCode(newV, oldV) {
      if (newV && newV != oldV) {
        this.resetTime();
        this.getUrl();
      }
    },
    open() {
      setTimeout(() => {
        this.player.JS_Resize().then(
          () => {
            console.info("JS_Resize success");
            // do you want...
          },
          (err) => {
            console.info("JS_Resize failed");
            // do you want...
          }
        );
      }, 0);
    },
  },
  methods: {
    playRefresh() {
      if (this.videoInfo.status != 2) {
        return;
      }
      this.videoInfo.status = 1;
      this.clearTimeBarInterval();
      this.play();
    },
    playPause() {
      if (this.videoInfo.status != 2) {
        return;
      }
      this.player.JS_Pause(0).then(
        () => {
          console.info("JS_Pause success");
          // do you want...
          this.clearTimeBarInterval();
          this.setCurTimeBar();
        },
        (err) => {
          console.info("JS_Pause failed");
          // do you want...
        }
      );
    },
    playResume() {
      if (this.videoInfo.status != 2) {
        return;
      }
      this.player.JS_Resume(0).then(
        () => {
          console.info("JS_Resume success");
          // do you want...
          this.initTimeBarInterval();
        },
        (err) => {
          console.info("JS_Resume failed");
          // do you want...
        }
      );
    },
    timeBarChange(val) {
      console.log("timeBarChange");
      console.log(val);
      this.clearTimeBarInterval();
      this.player
        .JS_Seek(
          0,
          moment(val).format("YYYY-MM-DDTHH:mm:ss") + "Z",
          moment(this.videoInfo.et).format("YYYY-MM-DDTHH:mm:ss") + "Z"
        )
        .then(() => {
          console.log("seekTo success");
          this.initTimeBarInterval();
        })
        .catch(() => {
          console.log("seekTo err");
        });
    },
    timeBarInput() {
      console.log("timeBarInput");
      // this.clearTimeBarInterval();
    },
    formatTooltip(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    timeSearch() {
      console.log("timeSearch");
      console.log(this.date);
      if (!this.date || !this.date.length) {
        this.$message("请输入时间");
        return;
      }
      if (!this.cameraIndexCode) {
        this.$message("请选择监控位置");
        return;
      }
      if (moment(this.date[0]).format() == moment(this.date[1]).format()) {
        this.$message("开始结束时间不能相同");
        return;
      }
      this.getUrl();
    },
    getUrl() {
      this.videoInfo.status = 1;
      this.videoInfo.cur = 0;
      this.videoInfo.st = 0;
      this.videoInfo.et = 0;
      this.videoInfo.marks = {};
      this.videoInfo.urlRes = null;
      this.clearTimeBarInterval();
      this.player.JS_Stop();
      console.log("?????");
      console.log(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      // return;
      this.$myRequest({
        url: `/api/tenant/hik/camera/playback/url`,
        data: {
          cameraIndexCode: this.cameraIndexCode,
          beginTime: moment(this.date[0]).format(
            "YYYY-MM-DDTHH:mm:ss.SSS+08:00"
          ),
          endTime: moment(this.date[1]).format("YYYY-MM-DDTHH:mm:ss.SSS+08:00"),
          // beginTime: moment(this.date[0]).toISOString(),
          // endTime: moment(this.date[1]).toISOString(),
        },
      })
        .then((res) => {
          console.log("url res");
          console.log(res);
          // return;
          if (res.data.body && res.data.body.url) {
            this.url = res.data.body.url;
            this.videoInfo.urlRes = res;
            this.play();
          } else {
            this.$message("加载失败，请重试");
            this.player.JS_Stop();
            this.videoInfo.status = 4;
          }
          //
        })
        .catch((res) => {
          console.log("url err");
          console.log(res);
          this.$message("加载失败，请重试");

          // this.$message(res.data.status_detail);
          this.videoInfo.status = 3;
        });
    },
    play() {
      this.videoInfo.cur = 0;
      let urlRes = this.videoInfo.urlRes;
      console.log("play");
      console.log(this.url);
      console.log(moment(this.date[0]).format("YYYY-MM-DDTHH:mm:ss") + "Z");
      console.log(moment(this.date[1]).format("YYYY-MM-DDTHH:mm:ss") + "Z");
      this.player
        .JS_Play(
          this.url,
          { playURL: this.url, mode: 0 },
          0,
          moment(this.date[0]).format("YYYY-MM-DDTHH:mm:ss") + "Z",
          moment(this.date[1]).format("YYYY-MM-DDTHH:mm:ss") + "Z"
        )
        .then((res) => {
          console.log("play res");
          console.log(res);
          this.videoInfo.status = 2;
          this.videoInfo.cur = 0;
          this.videoInfo.st = moment(
            urlRes.data.body.list[0].beginTime
          ).valueOf();
          this.videoInfo.et = moment(
            urlRes.data.body.list[urlRes.data.body.list.length - 1].endTime
          ).valueOf();

          this.videoInfo.marks[this.videoInfo.st] = moment(
            this.videoInfo.st
          ).format("YYYY-MM-DD HH:mm:ss");
          this.videoInfo.marks[this.videoInfo.et] = moment(
            this.videoInfo.et
          ).format("YYYY-MM-DD HH:mm:ss");
          this.initTimeBarInterval();
        })
        .catch((res) => {
          console.log("play err");
          console.log(res);
          this.videoInfo.status = 3;
        });
    },
    initTimeBarInterval() {
      this.clearTimeBarInterval();
      this.timeT = setInterval(() => {
        this.setCurTimeBar();
      }, 1000);
    },
    setCurTimeBar() {
      this.player
        .JS_GetOSDTime(0)
        .then((time) => {
          // console.info("osdTime:", new Date(time));
          this.videoInfo.cur = moment(time).valueOf();
          // do you want...
        })
        .catch((err) => {
          console.info("JS_GetOSDTime failed");
          // do you want...
        });
    },
    clearTimeBarInterval() {
      if (this.timeT) {
        clearInterval(this.timeT);
      }
    },
    resetTime() {
      let now = moment();
      console.log(now.format("YYYY-MM-DD HH:mm:ss"));
      let st = new Date(
        parseInt(moment(now.format("YYYY-MM-DD") + " 00:00:00").format("x"))
      );
      let et = new Date(
        parseInt(moment(now.format("YYYY-MM-DD") + " 23:59:59").format("x"))
      );
      this.date = [st, et];
    },
    visibilitychange() {
      if (document.hidden) {
        this.clearTimeBarInterval();
      } else {
        if (this.videoInfo.status == 2) {
          this.initTimeBarInterval();
        }
      }
    },
  },
  mounted() {
    window.xxx = this.player = new window.JSPlugin({
      szId: "video",
      szBasePath: "/haikang",
      // szBasePath: "/tenant/haikang",

      // iMaxSplit: 4,
      // iCurrentSplit: 2,
      // openDebug: true,
      // oStyle: {
      //   borderSelect: "#FFCC00",
      // },
    });
    this.player.JS_SetWindowControlCallback({
      StreamEnd: () => {
        this.clearTimeBarInterval();
      },
      pluginErrorHandler: (index, iErrorCode, oError) => {
        //插件错误回调 // do you want...
        console.log("pluginErrorHandler");
        this.videoInfo.status = 3;
      },
    });
    this.resetTime();
    // console.log(parseInt(moment().format("x")));
    // console.log(new Date().getTime());
    document.addEventListener("visibilitychange", this.visibilitychange);
  },
  beforeUnmount() {
    this.clearTimeBarInterval();
    this.player.JS_Stop();
    this.player = null;
    document.removeEventListener("visibilitychange", this.visibilitychange);
  },
};
</script>
<style scoped lang="less" src="./index.less">
</style>
