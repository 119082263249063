<template>
  <div class="global-box">
    <el-form
      :inline="true"
      :model="form"
      class="global-form global-form-inline"
    >
      <el-form-item>
        <el-input v-model="form.name" placeholder="车牌号" />
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.type" placeholder="车辆类型">
          <el-option label="全部车辆" value="1" />
          <el-option label="内场转猪车" value="2" />
          <el-option label="外场转猪车" value="3" />
          <el-option label="饲料车" value="4" />
          <el-option label="物资车" value="5" />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-select v-model="form.status" placeholder="采样结果">
          <el-option label="全部采样结果" value="1"></el-option>
          <el-option label="阴性" value="2"></el-option>
          <el-option label="阳性" value="3"></el-option>
          <el-option label="未知" value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          type="daterange"
          v-model="form.value1"
          range-separator="至"
          start-placeholder="入场时间"
          end-placeholder="入场时间"
        />
      </el-form-item>

      <el-form-item>
        <span class="btn-reset" @click="onReset" style="margin-right: 10px"
          >重置</span
        >
        <span class="btn-search" @click="onSubmit">查询</span>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      class="global-box-table"
      stripe
      border
      height="100"
    >
      <el-table-column prop="index" label="序号" width="100px">
      </el-table-column>
      <el-table-column prop="plate" label="车牌号"> </el-table-column>
      <el-table-column
        prop="type"
        label="车辆类型"
        :formatter="formatterNull"
      ></el-table-column>
      <el-table-column
        prop="enterTime"
        width="180px"
        label="入场时间"
      ></el-table-column>
      <el-table-column prop="sampling" label="采样流程" width="100px">
        <template #default="scoped">
          <span class="green" :class="{ red: scoped.row.sampling != '合格' }">{{
            scoped.row.sampling
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="samplingResult" label="采样结果" width="100px">
        <template #default="scoped">
          <span
            class="green"
            :class="{ red: scoped.row.samplingResult != '阴性' }"
            >{{ scoped.row.samplingResult }}</span
          >
        </template></el-table-column
      >
      <el-table-column prop="xi1" :formatter="formatterNull" label="一级洗消">
        <template #default="scoped">
          <span
            class="green"
            :class="{
              red: scoped.row.xi1 != '合格',
              white: scoped.row.xi1 == '--',
            }"
            >{{ scoped.row.xi1 }}</span
          >
        </template></el-table-column
      >
      <el-table-column :formatter="formatterNull" label="二级洗消">
        <template #default="scoped">
          <span
            class="green"
            :class="{
              red: scoped.row.xi2 != '合格',
              white: scoped.row.xi2 == '--',
            }"
            >{{ scoped.row.xi2 }}</span
          >
        </template></el-table-column
      >
      <el-table-column :formatter="formatterNull" label="转猪业务">
        <template #default="scoped">
          <span
            class="green"
            :class="{
              red: scoped.row.zhuan != '合格',
              white: scoped.row.zhuan == '--',
            }"
            >{{ scoped.row.zhuan }}</span
          >
        </template></el-table-column
      >
      <el-table-column label="操作" width="100px">
        <template #default="scope">
          <span @click.prevent="openDetai(scope.row)" class="detail">详情</span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div class="xi">
        <div class="title">基本信息</div>
        <el-row gutter="20">
          <el-col :span="8">
            <div class="label">车牌号</div>
            <div class="content">{{ detailInfo.plate }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">车辆类型</div>
            <div class="content">{{ detailInfo.type }}</div>
          </el-col>
          <el-col :span="8">
            <div class="label">入场时间</div>
            <div class="content">{{ detailInfo.enterTime }}</div>
          </el-col>
        </el-row>
        <div class="title" style="margin-top: 40px">洗消流程</div>
        <div class="flow">
          <div class="left">
            <img
              v-if="detailInfo.sampling == '合格'"
              :src="require('../imgs/zhong.png')"
            />
            <img v-else :src="require('../imgs/zhong.png')" />
            <span class="line"></span>
            <img
              v-if="detailInfo.samplingResult == '阴性'"
              :src="require('../imgs/zhong.png')"
            />
            <img v-else :src="require('../imgs/zhong.png')" />
            <span class="line"></span>
            <img
              v-if="detailInfo.xi1 == '合格'"
              :src="require('../imgs/zhong.png')"
            />
            <img v-else :src="require('../imgs/zhong.png')" />
            <span class="line"></span>
            <img
              v-if="detailInfo.xi2 == '合格'"
              :src="require('../imgs/zhong.png')"
            />
            <img v-else :src="require('../imgs/zhong.png')" />
            <span class="line"></span>
            <img
              v-if="detailInfo.zhuan == '合格'"
              :src="require('../imgs/zhong.png')"
            />
            <img v-else :src="require('../imgs/zhong.png')" />
          </div>
          <div class="right">
            <div>
              <p>采样流程</p>
              <p>
                <span>{{ detailInfo.xiTime }}</span>
                <span
                  class="green"
                  :class="{ red: detailInfo.sampling != '合格' }"
                  >{{ detailInfo.sampling }}</span
                >
                <span>详情>></span>
              </p>
            </div>
            <span></span>
            <div>
              <p>采样结果</p>
              <p>
                <span>{{ detailInfo.xiTime }}</span>
                <span
                  class="green"
                  :class="{ red: detailInfo.samplingResult != '阴性' }"
                  >{{ detailInfo.samplingResult }}</span
                >
                <span>详情>></span>
              </p>
            </div>
            <span></span>
            <div>
              <p>一级洗消</p>
              <p>
                <span>{{ detailInfo.xiTime }}</span>
                <span
                  class="green"
                  :class="{ red: detailInfo.xi1 != '合格' }"
                  >{{ detailInfo.xi1 }}</span
                ><span>详情>></span>
              </p>
            </div>
            <span></span>
            <div>
              <p>二级洗消</p>
              <p>
                <span>{{ detailInfo.xiTime }}</span>
                <span
                  class="green"
                  :class="{ red: detailInfo.xi2 != '合格' }"
                  >{{ detailInfo.xi2 }}</span
                ><span>详情>></span>
              </p>
            </div>
            <span></span>
            <div>
              <p>转猪业务</p>
              <p>
                <span>{{ detailInfo.xiTime }}</span>
                <span
                  class="green"
                  :class="{ red: detailInfo.zhuan != '合格' }"
                  >{{ detailInfo.zhuan }}</span
                ><span>详情>></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import basil from "@/util/basil";
import moment from "moment";
import { data } from "./data";
export default {
  name: "WashList",
  components: {},
  data() {
    return {
      projectid: basil.get("projectid"),
      form: {},
      tableData: data,
      dialogVisible: false,
      dialogTitle: "车辆详情",
      detailInfo: {},
    };
  },
  mounted() {},
  methods: {
    formatterNull(row, column, cellValue) {
      if (cellValue) {
        return cellValue;
      } else {
        return "--";
      }
    },
    formatterSt(row, column) {
      return moment(row.create_time).format("YYYY-MM-DD HH:mm:ss");
    },
    formatterEt(row, column) {
      return moment(row.latest_time).format("YYYY-MM-DD HH:mm:ss");
    },
    onReset() {
      this.form = {};
    },
    onSubmit() {},
    handleClose() {
      this.dialogVisible = false;
    },
    openDetai(row) {
      console.log(row);
      this.detailInfo = row;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped lang="less" src="./index.less"></style>
<style lang="less">
:deep(.el-dialog__body) {
  padding-top: 0 !important;
}
</style>
