<template>
  <div class="global-box">
    <div class="padding20" style="padding-left: 0px">
      <el-button type="primary" @click="add()">新增</el-button>
    </div>
    <el-table
      class="global-box-table"
      height="100"
      :data="tableData"
      stripe
      border
    >
      <el-table-column prop="username" label="用户名"> </el-table-column>

      <el-table-column prop="nickname" label="姓名"> </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="department" label="部门"> </el-table-column>
      <el-table-column prop="roles" label="角色" :formatter="roleFormat">
      </el-table-column>

      <el-table-column label="操作" width="180%">
        <template #default="scope">
          <el-button
            :disabled="scope.row.is_supper"
            size="mini"
            type="danger"
            @click="remove(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="新增"
      v-model="dialogVisible"
      width="600rem"
      :close-on-click-modal="false"
    >
      <div class="dia-form">
        <el-form
          v-if="dialogVisible"
          ref="form"
          :model="form"
          :rules="rules"
          label-width="100rem "
          class="global-form"
        >
          <el-form-item label="用户名" prop="username">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="nickname">
            <el-input v-model="form.nickname"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="form.password" show-password></el-input>
          </el-form-item>

          <el-form-item label="项目" prop="projectidsArr">
            <el-checkbox-group v-model="form.projectidsArr">
              <el-checkbox
                :label="item.id"
                :key="index"
                v-for="(item, index) in projectArr"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="部门">
            <el-input v-model="form.department"></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="roleidsArr">
            <el-checkbox-group v-model="form.roleidsArr">
              <el-checkbox
                :label="item.id"
                :key="index"
                v-for="(item, index) in roleArr"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessageBox, ElMessage } from "element-plus";
import { checkPhone } from "@/util/rules";

export default {
  name: "Page",
  components: {},
  data() {
    return {
      dialogVisible: false,
      type: 0, //
      // tableHeight: document.documentElement.clientHeight * 0.75,
      tableData: [],
      form: {
        projectidsArr: [],
        roleidsArr: [],
      },
      roleArr: [],
      projectArr: [],
      rules: {
        username: [
          {
            required: true,
            message: "必填",
          },
        ],
        nickname: [
          {
            required: true,
            message: "必填",
          },
        ],
        password: [
          {
            required: true,
            message: "必填",
          },
        ],
        phone: [
          {
            required: true,
            message: "必填",
          },
          { validator: checkPhone },
        ],
        projectidsArr: [
          {
            required: true,
            message: "必填",
          },
        ],
        roleidsArr: [
          {
            required: true,
            message: "必填",
          },
        ],
      },
    };
  },
  methods: {
    roleFormat(row, column) {
      return row.roles.map((item) => item.name).join(",");
    },
    close() {
      this.dialogVisible = false;
      this.type = 0;
      this.form = {
        projectidsArr: [],
        roleidsArr: [],
      };
    },
    remove(i, row) {
      ElMessageBox.confirm("确定删除?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$myRequest({
            method: "delete",
            url: "/api/tenant/user/delete/" + row.id,
          })
            .then((res) => {
              console.log("deviceDeleteReq");
              console.log(res);
              this.getList();
            })
            .catch((res) => {
              console.log("deviceDeleteReq err");
              console.log(res);
            });
        })
        .catch(() => {});
      // console.log(row);
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        console.log(this.form);
        this.form.projectids = this.form.projectidsArr.join(",");
        this.form.roleids = this.form.roleidsArr.join(",");
        this.$myRequest({
          url: "/api/tenant/user/add",
          method: "post",
          data: this.form,
        })
          .then((res) => {
            console.log("addres");
            console.log(res);
            this.getList();
            this.close();
          })
          .catch((res) => {
            console.log("add err");
            console.log(res);
            // this.$message("添加失败");
            this.$message(res.data.status_detail);
          });
      });
    },
    add() {
      this.form = {
        projectidsArr: [this.projectArr[0].id],
        roleidsArr: [],
      };
      this.type = 0;
      this.dialogVisible = true;
    },
    edit(index, row) {
      // console.log(row);
      this.type = 1;
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogVisible = true;
    },
    getList() {
      this.$myRequest({
        url: "/api/tenant/user/list",
      })
        .then((res) => {
          console.log("user res");
          console.log(res);
          this.tableData = res.data.body;

          //
        })
        .catch((res) => {
          console.log("user err");
          console.log(res);
          this.$message("系统错误");
        });
    },
    getProjects() {
      this.$myRequest({
        url: "/api/tenant/project/query",
        data: { page: 1, pageSize: 100 },
      })
        .then((res) => {
          console.log("getProjects res");
          console.log(res);
          this.projectArr = res.data.body.datas;
          //
        })
        .catch((res) => {
          console.log("getProjects err");
          console.log(res);
          this.$message("系统错误");
        });
    },
    getRole() {
      this.$myRequest({
        url: "/api/tenant/role/query",
        data: { page: 1, pageSize: 100 },
      })
        .then((res) => {
          console.log("getRole res");
          console.log(res);
          this.roleArr = res.data.body.datas;
          //
        })
        .catch((res) => {
          console.log("getRole err");
          console.log(res);
          this.$message("系统错误");
        });
    },
  },
  mounted() {
    this.getList();
    this.getProjects();
    this.getRole();
  },
};
</script>
<style scoped lang="less" src="./index.less"></style>
<style scoped>
/* ::-webkit-scrollbar {
  display: none;
} */
</style>
