<template>
  <div class="container">
    <div class="top">
      <div class="title">
        <img :src="require('../imgs/title_tody.png')" />
      </div>
      <div class="card">
        <div>
          <div class="card-wrapper">
            <img class="img7" :src="require('../imgs/top_7.png')" />
            <div class="card-right">
              <div class="card-inner-top">
                <span>22</span>
                <span>m³</span>
              </div>
              <div class="card-inner-bottom">奶厅冲洗用水量</div>
            </div>
          </div>
        </div>

        <div>
          <div class="card-wrapper">
            <img class="img8" :src="require('../imgs/top_8.png')" />
            <div class="card-right">
              <div class="card-inner-top">
                <span>322</span>
                <span>m³</span>
              </div>
              <div class="card-inner-bottom">粪沟回冲用水量</div>
            </div>
          </div>
        </div>

        <div>
          <div class="card-wrapper">
            <img class="img2" :src="require('../imgs/top_2.png')" />

            <div class="card-right">
              <div class="card-inner-top">
                <span>224</span>
                <span>m³</span>
              </div>
              <div class="card-inner-bottom">固液分离固体量</div>
            </div>
          </div>
        </div>

        <div>
          <div class="card-wrapper">
            <img class="img3" :src="require('../imgs/top_3.png')" />
            <div class="card-right">
              <div class="card-inner-top">
                <span>898</span>
                <span>m³</span>
              </div>
              <div class="card-inner-bottom">固液分离液体量</div>
            </div>
          </div>
        </div>

        <div>
          <div class="card-wrapper">
            <img class="img4" :src="require('../imgs/top_4.png')" />

            <div class="card-right">
              <div class="card-inner-top">
                <span>2841</span>
                <span>m³</span>
              </div>
              <div class="card-inner-bottom">还田液体量</div>
            </div>
          </div>
        </div>

        <div>
          <div class="card-wrapper">
            <img class="img5" :src="require('../imgs/top_5.png')" />
            <div class="card-right">
              <div class="card-inner-top">
                <span>37</span>
                <span>min</span>
              </div>
              <div class="card-inner-bottom">沼气燃烧时间</div>
            </div>
          </div>
        </div>
        <div>
          <div class="card-wrapper">
            <img class="img6" :src="require('../imgs/top_6.png')" />
            <div class="card-right">
              <div class="card-inner-top">
                <span>385</span>
                <span>kw·h</span>
              </div>
              <div class="card-inner-bottom">总用电量</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chart-box">
      <div class="title">
        <img :src="require('../imgs/title_history.png')" />
      </div>
      <v-chart ref="chart" class="chart" :option="createOption()" autoresize />
    </div>
  </div>
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { xAxis, data1, data2, data3, data4, data5, data6, data7 } from "./data";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { LineChart } from "echarts/charts";
use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
]);
const colors = [
  "#C8AD4F",
  "#C440EA",
  "#02A7F0",
  "#6161FF",
  "#FF4C50",
  "#67C23A",
  "#ccc",
];
export default {
  components: { VChart },
  data() {
    return {};
  },
  methods: {
    createOption() {
      return {
        color: colors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: true,
          },

          backgroundColor: "#13266a",
          borderColor: "#2d92f5",
          textStyle: {
            color: "#fff",
          },
        },

        legend: {
          icon: "rect",
          right: 0,
          textStyle: {
            fontSize: 14,
            color: "#B4C0D3",
          },
          itemWidth: 34,
          itemHeight: 14,
          // tooltip: {
          //   show: true,
          // },
        },
        grid: {
          top: 60,
          left: 60,
          right: 0,
          bottom: 50,
          // containLabel: true,
          // show: true,
          // borderColor: "transparent",
          // backgroundColor: "#223257",
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#44B1DD",
              },
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return params.value;
                },
              },
            },

            data: xAxis,
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: "采食/kg",
            axisLine: {
              onZero: false,
              show: true,
              lineStyle: {
                color: "#44B1DD",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#3C5389",
              },
            },
            nameTextStyle: {
              color: "#44B1DD",
            },
          },
        ],
        series: [
          {
            name: "奶厅冲洗用水量",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 8,
            // emphasis: {
            //   focus: "series",
            // },
            data: data1,
            // yAxisIndex: 0,
          },
          {
            name: "粪沟回冲用水量",
            type: "line",
            smooth: true,
            symbol: "circle",
            symbolSize: 8,
            data: data2,
            // yAxisIndex: 0,
          },
          {
            name: "固液分离固体量",
            symbol: "circle",
            type: "line",
            smooth: true,
            symbolSize: 8,
            data: data3,
            // yAxisIndex: 1,
          },
          {
            name: "固液分离液体量",
            symbol: "circle",
            type: "line",
            smooth: true,
            symbolSize: 8,
            data: data4,
            // yAxisIndex: 1,
          },
          {
            name: "还田液体量",
            symbol: "circle",
            type: "line",
            smooth: true,
            symbolSize: 8,
            data: data5,
            // yAxisIndex: 1,
          },
          {
            name: "沼气燃烧时间/min",
            symbol: "circle",
            type: "line",
            smooth: true,
            symbolSize: 8,
            data: data6,
            // yAxisIndex: 1,
          },
          {
            name: "总用电量",
            symbol: "circle",
            type: "line",
            smooth: true,
            symbolSize: 8,
            data: data7,
            // yAxisIndex: 1,
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="less" src="./index.less"></style>
